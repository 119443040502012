import React, { useEffect, useState } from "react";
import { Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import { Calendar } from "primereact/calendar";
import { Panel } from "primereact/panel";
import getUnixFromDate from "@helpers/dates/getUnixFromDate/getUnixFromDate";
import getDateFromUnix from "@helpers/dates/getDateFromUnix/getDateFromUnix";
import { Notificacion } from "@components/general/Alertas/Alertas";
import { enviarSolicitudExamen } from "@helpers/httpRequests/httpRequestMOS/httpRequestMOS";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { Button as PrimeButton } from "primereact/button";
import { addBusinessDays, isWeekend, isSameDay, eachDayOfInterval } from "date-fns";
import { addLocale } from "primereact/api";
import ReactInputMask from "react-input-mask";
import getFechaSinHora from "@helpers/dates/getDateFinalizacionCurso/getFechaSinHora";
import { agendarExamenCertificacionMos, getCertificacionesMosByFolio } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import typesTiposUsuario from "@helpers/types/typesTiposUsuario";

const AgendarExamen = ({ rol, correo, folioContrato, idPerfil, idRegion }) => {
	
	addLocale("es", {
		firstDayOfWeek: 1,
		dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
		dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
		dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
		monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
		monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
		today: "Hoy",
		clear: "Limpiar",
		//...
	});

	//VALIDACION PAR EL FORMULARIO
	const [validated, setValidated] = useState(false);

	//Loader para el boton del formulario
	const [loading, setLoading] = useState(false);

	const [invalidDates, setInvalidDates] = useState([]);

	const [certificacionesMos, setCertificacionesMos] = useState([]);

	const [requisitosLeidos, setRequisitosLeidos] = useState(true);

	const [datosUsuarioElegido, setDatosUsuarioElegido ] = useState(null);

	const [formValues, setFormValues] = useState({
		nombre: "",
		correo,
		fecha: "",
		telefono: "",
		certiportUser: "",
		hora: "",
	});

	const handleOnChange = ({ target }) => {
		let { name, value } = target;

		setFormValues({
			...formValues,
			[name]: value,
		});
	};

	const agendarExamen = async (e) => {
		e.preventDefault();

		const form = e.currentTarget;

		if (form.checkValidity() === false) {
			e.stopPropagation();
		}

		setValidated(true);

		try {
			const { fecha, nombre, correo, telefono, certiportUser, hora } = formValues;

			const infoSolicitud = {
				nombre,
				correo,
				fecha: getFechaSinHora(fecha),
				telefono,
				certiportUser,
				hora,
			};

			if (nombre !== "" && correo !== "" && fecha !== "" && telefono !== "" && certiportUser !== "" && hora !== "") {
				setLoading(true);

				const { message } = await enviarSolicitudExamen(infoSolicitud);

				await agendarExamenCertificacionMos({
					folioContrato,
					idPerfil: datosUsuarioElegido.idPerfil,
					fechaAgenda: fecha,
					idRegion
				});

				Notificacion("success", message);
			}
		} catch (error) {
			console.log(error);

			Notificacion("error", "Ocurrió un error al agendar tu examen");

			setRequisitosLeidos(!requisitosLeidos);

			setLoading(!loading);
		}
	};

	const isDisabledDate = (date) => {
		// Deshabilita los fines de semana
		if (isWeekend(date)) {
			return true;
		}

		// Deshabilita los próximos 15 días hábiles a partir de hoy
		const currentDate = new Date();

		for (let i = 0; i < 15; i++) {
			if (isSameDay(date, addBusinessDays(currentDate, i))) {
				return true;
			}
		}

		// Si no se cumple ninguna de las condiciones anteriores, la fecha no está deshabilitada
		return false;
	};

	const handleCheckBox = () => {
		setRequisitosLeidos(!requisitosLeidos);
	};

	const handleSeleccionUsuario = ({ target }) => {

		if (target.value === '') return setDatosUsuarioElegido(null);

		const idCertificacionMos = parseInt(target.value);

		const datosUsuario = certificacionesMos.find(cert => cert.idCertificacionMos === idCertificacionMos);

		setDatosUsuarioElegido(datosUsuario);

		setFormValues({
			...formValues,
			nombre: `${ datosUsuario.nombreCompleto } ${ datosUsuario.apellidos }`,
			correo: datosUsuario.idPerfil !== null ? formValues.correo : ''
		});
	};

	const consultarCertificacionesUsuario = async () => {
		try {
			const { certificacionesAsignadas } = await getCertificacionesMosByFolio(folioContrato, idRegion);
			
			setCertificacionesMos(certificacionesAsignadas);

			if (rol === typesTiposUsuario.empleado) {
				const datosUsuario = certificacionesAsignadas.find(cert => cert.idPerfil === idPerfil);
				
				if (datosUsuario !== undefined) {
					setDatosUsuarioElegido(datosUsuario);
				}
			}
		} catch (error) {
			console.log("error al consultar las certificaciones mos del usuario: ", error);
		}
	};

	useEffect(() => {
		const next15Days = eachDayOfInterval({
			start: new Date(),
			end: addBusinessDays(new Date(), 15 * 5), // 15 días hábiles son 3 semanas (5 días hábiles por semana)
		});

		const invalidDates = next15Days.filter(isDisabledDate);

		setInvalidDates(invalidDates);

		consultarCertificacionesUsuario();
	}, []);

	return (
		<Container className="text-start">
			{
				rol !== typesTiposUsuario.empleado ?
				<Row className="mb-4">
					<Col>
						<FloatingLabel label="Elige para quién agendarás el examen">
							<Form.Select onChange={handleSeleccionUsuario} aria-label="Floating label select example">
								<option value=''>Elige una persona</option>
								
								{
									certificacionesMos.map((cert, idx) => 
										<option key={idx} value={cert.idCertificacionMos}>{ `${ cert.nombreCompleto } ${ cert.apellidos }` }</option>
									)
								}
							</Form.Select>
						</FloatingLabel>
					</Col>
				</Row>
				: null
			}

			{
				datosUsuarioElegido !== null ?
				<Row>
					<Col>
						<Form noValidate validated={validated} onSubmit={agendarExamen}>
							<Row>
								<Col>
									<Form.Group>
										<Form.Label>
											<h6>Nombre completo</h6>
										</Form.Label>
										<Form.Control className="input-form" required onChange={handleOnChange} value={formValues.nombre} name="nombre" type="text" placeholder="Tu nombre completo"></Form.Control>

										<Form.Control.Feedback type="invalid">
											<p>Este campo es obligatorio</p>
										</Form.Control.Feedback>
									</Form.Group>
								</Col>

								<Col>
									<Form.Group>
										<Form.Label>
											<h6>Número de teléfono</h6>
										</Form.Label>
										{/* <Form.Control className='input-form' required onChange={handleOnChange} value={formValues.telefono} name="telefono" type="tel" placeholder="Número de teléfono"></Form.Control> */}

										<ReactInputMask
											className="input-form form-control"
											mask="(999) 999-9999"
											maskChar=" "
											required
											onChange={handleOnChange}
											value={formValues.telefono}
											name="telefono"
											type="tel"
											placeholder="Número de teléfono"
										/>

										<Form.Control.Feedback type="invalid">
											<p>Este campo es obligatorio</p>
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Row>

							<Row>
								<Col>
									<Form.Group>
										<Form.Label>
											<h6>Correo electrónico</h6>
										</Form.Label>
										<Form.Control
											className="input-form"
											required
											// disabled
											onChange={handleOnChange}
											value={formValues.correo}
											name="correo"
											type="email"
											placeholder="Correo electrónico registrado"></Form.Control>

										<Form.Control.Feedback type="invalid">
											<p>Este campo es obligatorio</p>
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
								<Col>
									<Form.Group>
										<Form.Label>
											<h6>Fecha para el examen</h6>
										</Form.Label>

										<div>
											<Calendar
												required
												locale="es"
												placeholder="Fecha del examen"
												onChange={(e) =>
													setFormValues({
														...formValues,
														date: e.value,
														fecha: getUnixFromDate(e.value),
													})
												}
												value={formValues.date}
												name="fecha"
												className="calendario"
												showIcon
												dateFormat="dd/MM/yy"
												disabledDates={invalidDates}
												style={{ width: "100%", height: "2.3rem" }}
											/>
										</div>

										<small>
											<FontAwesomeIcon className="text-warning" icon={faExclamationTriangle} /> La fecha del examen deberá ser minimo 15 dias hábiles despues de la fecha actual.
										</small>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col className="col-6">
									<Form.Group>
										<Form.Label>
											<h6>Nombre de usuario Certiport</h6>
										</Form.Label>
										<Form.Control
											required
											onChange={handleOnChange}
											type="text"
											name="certiportUser"
											value={formValues.certiportUser}
											placeholder="Nombre de usuario de tu cuenta Certiport"></Form.Control>
									</Form.Group>
								</Col>

								<Col className="col-6">
									<Form.Group>
										<Form.Label>
											<h6>Hora deseada para tu examen</h6>
										</Form.Label>
										<Form.Control
											required
											onChange={handleOnChange}
											type="time"
											name="hora"
											value={formValues.hora}
											placeholder="Hora en la que deseas realizar tu examen"
											min="9:00"
											max="13:00"></Form.Control>
									</Form.Group>
									<small>
										{" "}
										<span className="text-danger">*</span> El horario permitido para realizar el examen es de 9:00am a 1:00pm
									</small>
								</Col>
							</Row>

							{/* <Row className="mt-5">
								<Col className="col-9 mb-3">
									<small className="text-danger">
										<FontAwesomeIcon icon={faExclamationTriangle} /> La fecha del examen deberá ser minimo 15 dias hábiles despues de la fecha actual.
									</small>
								</Col>
							</Row> */}

							<Panel header={<h5>Confirma tus datos</h5>}>
								<p>
									<b>Nombre:</b> <span className={`${formValues.nombre === "" ? "text-danger" : "text-success"}`}>{formValues.nombre === "" ? "Aun no has escrito un nombre" : formValues.nombre}</span>
								</p>
								<p>
									<b>Correo:</b> <span className={ formValues.correo === "" ? 'text-danger' : 'text-success' }>{formValues.correo === "" ? "Ingresa un correo para esta persona" : formValues.correo}</span>
								</p>
								<p>
									<b>Fecha:</b>
									<span className={`${formValues.fecha === "" ? "text-danger" : "text-success"}`}>
										{formValues.fecha === "" ? " Aún no has seleccionado una fecha" : ` ${getDateFromUnix(formValues.fecha)}`}
									</span>
								</p>
								<p>
									<b>Número de teléfono:</b>
									<span className={`${formValues.telefono !== "" ? "text-success" : "text-danger"}`}>{formValues.telefono === "" ? " Agrega un teléfono de contacto" : ` ${formValues.telefono}`}</span>
								</p>
								<p>
									<b>Hora:</b>
									<span className={`${formValues.hora === "" ? "text-danger" : "text-success"}`}>{formValues.hora === "" ? " Aún no has seleccionado una hora" : ` ${formValues.hora} hrs`}</span>
								</p>
							</Panel>

							<Form.Check
								onChange={handleCheckBox}
								className="mt-3 mb-3"
								required
								type={"checkbox"}
								id={`default-checkbox`}
								label={<small>{`Estoy de acuerdo en que he leido todos y cada uno de los requisitos solicitados para agendar y realizar mi examen de certificación.`}</small>}
							/>
							{/* 
							<Button type="submit" block>
								Agendar Examen
							</Button> */}

							<PrimeButton disabled={requisitosLeidos} className="btn-prime" style={{ width: "100%", marginTop: ".5rem" }} label="Agendar Examen" type="submit" icon="pi pi-send" loading={loading} />
						</Form>
					</Col>
				</Row>
				: null
			}
		</Container>
	);
};

export default AgendarExamen;
