import TitleDashboard from '@components/general/Titles/TitleDashboard'
import React from 'react'
import { Accordion, Col, Container, Image, Row } from 'react-bootstrap'

const DashboardDocumentacionFrontend = () => {
  return (
    <Container fluid>
        <Row className="mb-4">
            <Col>
                <h2>Estructura del proyecto</h2>
                <small>Diagramas con la estructura básica de la plataforma web y móvil.</small>
                <Row className="mt-3">
                    <Col>
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Estructura base de la plataforma</Accordion.Header>
                                <Accordion.Body className="text-center">
                                    <Image fluid width={"80%"} src={"https://globalclickmexico.com/img/documentacion/estructura_plataforma.png"} />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Estructura base del frontend (plataforma web)</Accordion.Header>
                                <Accordion.Body className="text-center">
                                    <Image fluid width={"80%"} src={"https://globalclickmexico.com/img/documentacion/estructura_frontend.png"} />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Estructura base del frontend (plataforma móvil)</Accordion.Header>
                                <Accordion.Body className="text-center">
                                    <Image fluid width={"80%"} src={"https://globalclickmexico.com/img/documentacion/estructura_frontend_app_movil.png"} />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Col>
        </Row>

        <Row className="mb-4">
            <Col>
                <h2>Flujo de información</h2>
                <small>Proceso por el que pasa la información del usuario desde que se dá de alta en SAI hasta que se registra en plataforma.</small>
                <Row className="mt-3">
                    <Col>
                    <Accordion>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Flujo de información Click+ (web)</Accordion.Header>
                                <Accordion.Body className="text-center">
                                    <Image fluid width={"80%"} src={"https://globalclickmexico.com/img/documentacion/flujo_informacion_web.png"} />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Flujo de información Click+ (móvil)</Accordion.Header>
                                <Accordion.Body className="text-center">
                                    <Image fluid width={"65%"} src={"https://globalclickmexico.com/img/documentacion/flujo_informacion_movil.png"} />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
  )
}

export default DashboardDocumentacionFrontend