const endpointsPaths = {
	accesosAdministradores: 'accesos-administradores',
	agendaAsesorias: 'agenda-asesorias',
	altasMasivas: "altas-masivas",
	aparienciaPlataforma: "apariencia-plataforma",
	aulaVirtual: "aula-virtual",
	bancoPreguntas: "preguntas",
	categoriasCursos: "categorias-cursos",
	certificacionCursos: "certificacion-cursos",
	certificacionMos: "certificacion-mos",
	codigoQR: "codigoQR",
	comentariosTemas: "comentarios-temas",
	consejosRapidos: "consejos-rapidos",
	cursoIngles: "curso-ingles",
	cursos: "cursos",
	cursoUsuario: "curso-usuario",
	estadisticasPlataforma: "estadisticas-plataforma",
	examenDiagnostico: "examen-diagnostico",
	foroEmpresarial: "foro-empresarial",
	grupos: "grupos",
	imagenes: "imagenes",
	// puede eliminarase, función desconocida
	lecciones: "lecciones",
	leccionesPersonalizadas: "lecciones-personalizadas",
	leccionesVistas: "lecciones-vistas",
	licencias: "licencias",
	likes: "likes",
	mailers: "mailers",
	misiones: "misiones",
	notificaciones: "notificaciones",
	openAI: "open-ai",
	pagos: "pagos",
	perfiles: "perfiles",
	plantillasTrabajo: "plantillas-trabajo",
	recomendacionesDiagnostico: "recomendaciones-diagnostico",
	reportes: "reportes",
	respuestasTema: "respuestas-tema",
	rutasAprendizaje: "rutasAprendizaje",
	rutasAprendizajePersonalizadas: "rutasAprendizajePersonalizadas",
	sai: "sai",
	salaEntretenimiento: "sala-entretenimiento",
	secciones: "secciones",
	temas: "temas",
	tokens: "tokens",
	usuarios: "usuarios",
	usuariosAppMovil: 'usuarios-app-movil',
	usuariosMoodle: "usuarios-moodle",
	usuariosRankings: "usuariosRanking",
	validaciones: "validaciones",
	verificarVigencia: "verificar-vigencia",
	pixelFacebook: 'pixel-fb',

};

export default endpointsPaths;
