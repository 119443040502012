import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const getUsuariosActivosAppMovil = (correo) => {
  return new Promise(async (resolve, reject) => {
    try {
      
        const {data: usuariosApp} = await httpRequest({
          url: `${endpointsPaths.usuariosAppMovil}/${httpRequestsPaths.usuarios.appMovil.getUsuariosActivos}`,
          method: "GET",
        });

        resolve(usuariosApp);
      
    } catch (error) {
      // console.log("Error validando el correo: ", error);

      reject(error);
    }
  });
};