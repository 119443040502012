import "@assets/css/components/Cursos/style.css";

import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  OverlayTrigger,
  Popover,
  Row,
} from "react-bootstrap";

import TitleDashboard from "@components/general/Titles/TitleDashboard";
import { getCategoriasCursos } from "@helpers/httpRequests/httpRequestsCategoriasCursos/httpRequestsCategoriasCursos";
import { getCursos } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import { useContext } from "react";
import UserContext from "@context/User/UserContext";
import multiViewComponentsPaths from "@helpers/multiViewComponentsPaths/multiViewComponentsPaths";
import { Animated } from "react-animated-css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { Link as LinkScroll } from "react-scroll";
import Loader from "@helpers/loader/loader";
import { getCursosLicencia } from "@helpers/httpRequests/httpRequestsLicencias/httpRequestsLicencias";

const ListadoCursos = ({ handleChangeComponent, showScrollButton }) => {
  const {
    contextValue: userContextValue,
    setContextValue: setUserContextValue,
  } = useContext(UserContext);

  // const [cursoSeleccionado, setCursoSeleccionado] = useState(null);

  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(1);

  const [categorias, setCategorias] = useState([]);

  const [cursos, setCursos] = useState([]);

  const [cursosLicencia, setCursosLicencia] = useState([]);

  const [cursosPopOver, setCursosPopOver] = useState([]);

  //* States bandera para mostrar o no contenido

  const [showLoader, setShowLoader] = useState(true);

  const [showCategorias, setShowCategorias] = useState(true);

  const [showCursos, setShowCursos] = useState(false);

  const [showRegresar, setShowRegresar] = useState(false);

  const { usuarioLogeado } = userContextValue;

  const {rol, correo} = usuarioLogeado;

  const cursosFiltrados = cursos.filter((curso) =>
    cursosLicencia.find(
      (cursoLicencia) => cursoLicencia.codigoCurso === curso.codigoCurso
    )
  );

  const categoriasFiltradas = categorias.filter((categoria) =>
    cursosFiltrados.some((curso) => curso.idCategoria === categoria.idCategoria)
  );

  const handleCursoSelection = (codigoCurso) => {

    if(codigoCurso === "LV23"){
      abrirCursoLectura();
      return;
    }

    const codigoSeccionSeleccionada = codigoCurso + "01";

    setUserContextValue({
      ...userContextValue,
      codigoCursoSeleccionado: codigoCurso,
      ultimoCursoTomado: codigoCurso,
      codigoSeccionSeleccionada,
    });

    handleChangeComponent(multiViewComponentsPaths.curso);
  };

  //*FUNCION PARA SELECCIONAR LA CATEGORIA Y ASIGNARLA AL STATE
  const handleCategoria = (idCategoria) => {
    setShowCategorias(false);

    setCategoriaSeleccionada(idCategoria);

    setShowCursos(true);

    setShowRegresar(true);
  };

  //*FUNCION PARA SELECCIONAR EL CURSO Y ASIGNARLO AL STATE

  const handleRegresar = () => {
    if (showCursos) {
      setShowCursos(false);

      setShowCategorias(true);

      setShowRegresar(false);
    }
  };

  const abrirCursoLectura = () => {
    // window.location.href = `https://https://virtual.globalclickmexico.com/lectura/initCurso.php?nombreUsuario=${correo}`;
    window.open(`https://virtual.globalclickmexico.com/lectura/initCurso.php?nombreUsuario=${correo}`);
  }

  //Listar los cursos por categoria

  useEffect(() => {
    const listarCategorias = async () => {
      try {
        let { data } = await getCategoriasCursos();

        if (!Array.isArray(data)) {
          data = [data];
        }

        setCategorias(data);
      } catch (error) {
        console.log(error);
      }
    };

    const listarCursos = async () => {
      try {
        let { data } = await getCursos();

        if (!Array.isArray(data)) {
          data = [data];
        }

        setCursos(data);
      } catch (error) {
        console.log(error);
      }
    };

    const consultarCursosLicencia = async () => {
      try {
        const {
          data: { cursos },
        } = await getCursosLicencia(usuarioLogeado.idCredencial);

        setCursosLicencia(JSON.parse(cursos));

        setShowLoader(false);
      } catch (error) {
        console.log("Error al consultar los cursos de la licencia: ", error);
      }
    };

    listarCursos();

    listarCategorias();

    consultarCursosLicencia();
  }, []);

  const listaCursos = (idCategoria) => {
    return setCursosPopOver(
      cursosFiltrados.filter((curso) => curso.idCategoria === idCategoria)
    );
  };

  return (
    <>
      {showLoader ? (
        <Loader height={"80vh"} setShowLoader={setShowLoader} />
      ) : (
        <>
          {showScrollButton && (
            <Animated
              animationIn="fadeIn"
              isVisible={true}
              style={{ zIndex: "9999", position: "absolute" }}
            >
              <span
                className={`rounded-circle scrollTop text-center d-flex`}
                style={{
                  height: "50px",
                  width: "50px",
                  fontSize: "2rem",
                  backgroundColor: "#F59E0B",
                  position: "fixed",
                  bottom: "40px",
                  right: "40px",
                }}
              >
                <LinkScroll
                  className="pointer"
                  activeClass="active"
                  to="scrollTopElement"
                  spy={true}
                  smooth={true}
                  duration={800}
                  containerId="scrollTop"
                  style={{ color: "black", width: "100%" }}
                >
                  <FontAwesomeIcon icon={faArrowUp} />
                </LinkScroll>
              </span>
            </Animated>
          )}

          {/* //*COMPONENTE ScrollToTop */}
          <Animated
            animationIn="fadeIn"
            animationInDuration={2000}
            animationOut="fadeOut"
            isVisible={true}
          >
            <Container fluid className="p-0 mb-5 mt-5">
              <Row>
                <Col>
                  <Row className="mb-3 justify-content-between">
                    {showRegresar ? (
                      <Col className="text-capitalize col-auto">
                        <TitleDashboard title="Selecciona un curso" />
                      </Col>
                    ) : (
                      <Col className="text-capitalize col-auto">
                        <TitleDashboard title="Selecciona una categoría" />
                      </Col>
                    )}

                    {showRegresar && (
                      <Col className="col-auto">
                        <span onClick={handleRegresar} className="pointer">
                          <FontAwesomeIcon icon={faArrowLeft} /> Volver a
                          categorías
                        </span>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>

              {/* Vista de las categorias */}
              {showCategorias && (
                <Row className="justify-content-center categorias-disponibles">
                  {cursosLicencia.length > 0 &&
                    categoriasFiltradas.map((categoria, idx) => (
                      <Col
                        className="col-9 col-sm-6 col-md-4 col-lg-3"
                        key={idx}
                      >
                        <OverlayTrigger
                          trigger={["hover", "focus"]}
                          onEnter={() => listaCursos(categoria.idCategoria)}
                          key={"auto"}
                          placement={"auto"}
                          overlay={
                            <Popover id={`popover-positioned`}>
                              <Popover.Header as="h3">
                                <b>¿De qué va la categoría?</b>
                              </Popover.Header>

                              <Popover.Body>
                                <p>{categoria.descripcion}</p>
                                <p>
                                  <b>Cursos que encontrarás aquí</b>
                                </p>

                                {
                                  // listaCursos(categoria.idCategoria)

                                  <ul>
                                    {cursosPopOver.map((cursoFiltrado, idx) => (
                                      <li key={idx}>{cursoFiltrado.nombre}</li>
                                    ))}
                                  </ul>
                                }
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <Card
                            onClick={() =>
                              handleCategoria(categoria.idCategoria)
                            }
                            id={idx}
                            className=" mb-4 card-predefinidas border-0"
                          >
                            <picture width="100%" height="100%">
                              <source
                                srcSet={`${require(`@assets/images/portadas_rutasAprendizaje/${categoria.portadaCategoria}`)}`}
                              />
                              <img
                                loading="lazy"
                                className="img-fluid"
                                src={`${require(`@assets/images/portadas_rutasAprendizaje/${categoria.portadaCategoria}`)}`}
                                alt="imagen leccion"
                              />
                            </picture>
                          </Card>
                        </OverlayTrigger>
                      </Col>
                    ))}
                </Row>
              )}

              {/* Vista de los cursos por categoria */}
              {showCursos && (
                <Row className="justify-content-center justify-content-lg-start ">
                  {cursosFiltrados.map(
                    (curso, idx) =>
                      curso.idCategoria === categoriaSeleccionada &&
                      curso.estatus === 1 && (
                        <Col
                          id={idx}
                          className="pointer col-9 col-sm-6 col-md-4 col-lg-4 col-xl-3"
                          onClick={() => {
                             handleCursoSelection(curso.codigoCurso);
                          }}
                        >
                          <Card className=" mb-4 card-predefinidas border-0">
                            <picture width="100%" height="100%">
                              <source
                                srcSet={`${require(`@assets/images/portadas/${curso.portadaCurso}`)}`}
                              />
                              <img
                                loading="lazy"
                                className="img-fluid"
                                src={`${require(`@assets/images/portadas/${curso.portadaCurso}`)}`} // Aquí va la URL de la imagen de fondo
                                alt="Imagen de fondo"
                              />
                            </picture>
                          </Card>
                        </Col>
                      )
                  )}
                </Row>
              )}
            </Container>
          </Animated>
        </>
      )}
    </>
  );
};

export default ListadoCursos;
