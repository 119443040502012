import "@assets/css/components/DashboardUsuarios/Modales/Empleado/style.css";

import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import React, { useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Notificacion } from "@components/general/Alertas/Alertas";
import { NotificationManager } from "react-notifications";
import capitalizeString from "@helpers/strings/capitalizeString/capitalizeString";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import regExpStrings from "@helpers/regExpStrings/regExpStrings";
import upperCaseString from "@helpers/strings/upperCaseString/upperCaseString";
import validarRestriccionesContrasenia from "@helpers/validaciones/validarRestriccionesContrasenia/validarRestriccionesContrasenia";

import useCapsLockWatcher from "@customHooks/useCapsLockWatcher";
import CapsLockTooltip from "@components/general/CapsLockTooltip/CapsLockTooltip";
import { addAdministrador } from "@helpers/httpRequests/httpRequestsUsuarios/registro/httpRequestsRegistroUsuarios";

export const AgregarAdministrador = ({ setAdminAgregado }) => {
  const inputContrasenia = useRef(null);

  const isCapsLockOn = useCapsLockWatcher(inputContrasenia);

  const [formValues, setFormValues] = useState({
    folioContrato: "",
    nombreCompleto: "",
    apellidos: "",
    correo: "",
    contrasenia: "",
    confirmacionContrasenia: "",
    profesion: "",
    nombreUsuario: "",
    curp: "",
    // idGrupo: 0
  });

  const [validacion, setValidacion] = useState({
    folioContrato: false,
    nombreCompleto: false,
    apellidos: false,
    correo: false,
    contrasenia: false,
    contraseniaConfirmacion: false,
    profesion: false,
    nombreUsuario: false,
    curp: false,
    nombreEmpresa: false,
  });

  const [showPasswords, setShowPasswords] = useState(false);

  const handleFolioContrato = async ({ target }) => {
    try {
      const { value } = target;

      if (value === "" || !regExpStrings.regexLetras(value)) {
        setFormValues({
          ...formValues,
          folioContrato: value.replace(/[^0-9]/g, ""),
        });

        return setValidacion({
          ...validacion,
          folioContrato: false,
        });
      }

      setFormValues({
        ...formValues,
        folioContrato: value.replace(/[^0-9]/g, ""),
      });

      setValidacion({
        ...validacion,
        folioContrato: true,
      });
    } catch (error) {
      console.log("Error al actualizar formValues: ", error);
    }
  };

  const handleCorreo = ({ target }) => {
    try {
      const { value } = target;

      setFormValues({
        ...formValues,
        correo: value,
      });

      if (!regExpStrings.regexEmail(value)) {
        return setValidacion({
          ...validacion,
          correo: false,
        });
      }

      setValidacion({
        ...validacion,
        correo: true,
      });
    } catch (error) {
      console.log("Error validando el correo: ", error);
    }
  };

  const handleOnChange = ({ target }) => {
    try {
      const { name, value } = target;

      if (name === "curp" && value.length === 18) {
        setValidacion({
          ...validacion,
          curp: true,
        });
      } else if ((name === "nombreCompleto" || name === "apellidos") && value !== "") {
        setValidacion({
          ...validacion,
          [name]: true,
        });
      } else if (name === "nombreUsuario" && value !== "") {
        setValidacion({
          ...validacion,
          nombreUsuario: true,
        });
      }

      setFormValues({
        ...formValues,
        [name]: value,
      });
    } catch (error) {
      console.log("Error al actualizar formValues: ", error);
    }
  };

  const handleShowPasswords = () => {
    setShowPasswords(!showPasswords);
  };

  const handleOnChangePasswords = async ({ target }) => {
    try {
      const { name, value } = target;

      if (name === "contrasenia") {
        setFormValues({
          ...formValues,
          contrasenia: value,
        });

        const validacionesContrasenia = await validarRestriccionesContrasenia({
          contrasenia: value,
        });

        setValidacion({
          ...validacion,
          ...validacionesContrasenia,
        });
      } else if (name === "confirmacionContrasenia") {
        setFormValues({
          ...formValues,
          confirmacionContrasenia: value,
        });

        let contraseniasCoincidentes = false;

        if (value === formValues.contrasenia) {
          contraseniasCoincidentes = true;
        }

        setValidacion({
          ...validacion,
          confirmacionContrasenia: contraseniasCoincidentes,
        });
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await addAdministrador(formValues);

      setAdminAgregado(true);

      Notificacion("success", "Administrador agregado correctamente");
    } catch (error) {
      console.log("Error al agregar al administrador: ", error);

      NotificationManager.error(
        "No se pudo agregar al administrador.\n" + error.message
      );
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Container fluid className="text-start">
        <Row className="mb-6">
          <Form.Group as={Col}>
            <Form.Label>Folio</Form.Label>

            <Form.Control
              onChange={handleFolioContrato}
              name="folioContrato"
              type="text"
              placeholder="Ingresa el folio de contrato"
              value={formValues.folioContrato}
              autoComplete="off"
              autoFocus
              className={validacion.folioContrato ? "is-valid" : "is-invalid"}
            />

            <Form.Control.Feedback type="invalid">
              Ingresa el folio contrato
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>CURP</Form.Label>

            <Form.Control
              className={validacion.curp ? "is-valid" : "is-invalid"}
              onChange={handleOnChange}
              name="curp"
              type="text"
              placeholder="Ingresa la CURP del empleado"
              maxLength="18"
              value={upperCaseString(formValues.curp)}
              autoComplete="off"
              autoFocus
            />

            <Form.Control.Feedback type="invalid">
              Ingresa la CURP del empleado
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        {/* <Row className="mb-3">
					
					<Form.Group as={Col} controlId="curp">
						<Form.Label>Profesión</Form.Label>
						
						<Form.Control 
							className={ validacion.profesion ? "is-valid" : "is-invalid" }
							onChange={handleOnChange} 
							name="profesion" 
							type="text" 
							placeholder="Ingresa la profesion del empleado" 
							value={formValues.profesion} 
							autoComplete="off" 
							autoFocus 
						/>

						<Form.Control.Feedback type="invalid">Ingresa la profesion del empleado</Form.Control.Feedback>
					</Form.Group>
				</Row> */}

        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Nombre</Form.Label>

            <Form.Control
              className={!validacion.nombre && "is-invalid"}
              onChange={handleOnChange}
              name="nombreCompleto"
              type="text"
              placeholder="Escribe el nombre del empleado"
              value={capitalizeString(formValues.nombreCompleto)}
              autoComplete="off"
              autoFocus
            />

            <Form.Control.Feedback type="invalid">
              Escribe el nombre del empleado
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Apellidos</Form.Label>

            <Form.Control
              className={!validacion.apellidos && "is-invalid"}
              onChange={handleOnChange}
              name="apellidos"
              type="text"
              placeholder="Ingresa los apellidos del empleado"
              value={capitalizeString(formValues.apellidos)}
              autoComplete="off"
              autoFocus
            />

            <Form.Control.Feedback type="invalid">
              Ingresa los apellidos del empleado
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Correo electrónico</Form.Label>

            <Form.Control
              className={validacion.correo ? "is-valid" : "is-invalid"}
              onChange={handleCorreo}
              name="correo"
              type="text"
              placeholder="Ingresa el correo electrónico del empleado"
              value={formValues.correo}
              autoComplete="off"
              autoFocus
            />

            <Form.Control.Feedback type="invalid">
              Ingresa el correo electrónico del empleado
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Nombre de usuario</Form.Label>

            <Form.Control
              className={validacion.nombreUsuario ? "is-valid" : "is-invalid"}
              onChange={handleOnChange}
              name="nombreUsuario"
              type="text"
              placeholder="Ingresa un nombre de usuario"
              value={formValues.nombreUsuario}
              autoComplete="off"
              autoFocus
            />

            <Form.Control.Feedback type="invalid">
              Ingresa un nombre de usuario válido
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} md={6}>
            <Form.Label>Contraseña</Form.Label>

            <InputGroup hasValidation>
              <InputGroup.Text
                onClick={handleShowPasswords}
                className="pointer"
              >
                <FontAwesomeIcon icon={faEye} />
              </InputGroup.Text>

              <Form.Control
                className={validacion.contrasenia ? "is-valid" : "is-invalid"}
                name="contrasenia"
                value={formValues.contrasenia}
                type={showPasswords ? "text" : "password"}
                placeholder="Ingresa una contraseña"
                onChange={handleOnChangePasswords}
                autoComplete="off"
                ref={inputContrasenia}
              />

              <Form.Control.Feedback type="invalid">
                Genera una contraseña v&aacute;lida
              </Form.Control.Feedback>

              <CapsLockTooltip
                input={inputContrasenia}
                isCapsLockOn={isCapsLockOn}
              />
            </InputGroup>

            <Form.Text id="passwordHelpBlock" muted>
              La contraseña debe contener:
              <ul>
                <li
                  className={
                    validacion.caracteresContrasenia
                      ? "text-success"
                      : "text-danger"
                  }
                >
                  8 caracteres
                </li>
                <li
                  className={
                    validacion.mayusculaContrasenia
                      ? "text-success"
                      : "text-danger"
                  }
                >
                  1 mayúscula
                </li>
                <li
                  className={
                    validacion.numeroContrasenia
                      ? "text-success"
                      : "text-danger"
                  }
                >
                  1 número
                </li>
              </ul>
              {/* La contraseña debe ser de al menos 8 caracteres, 1 mayúscula y 1 número */}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} md={6}>
            <Form.Label>Confirma la contraseña</Form.Label>

            <InputGroup hasValidation>
              <InputGroup.Text
                onClick={handleShowPasswords}
                className="pointer"
              >
                <FontAwesomeIcon icon={faEye} />
              </InputGroup.Text>

              <Form.Control
                className={
                  validacion.confirmacionContrasenia ? "is-valid" : "is-invalid"
                }
                name="confirmacionContrasenia"
                value={formValues.confirmacionContrasenia}
                type={showPasswords ? "text" : "password"}
                placeholder="Confirma tu contraseña"
                onChange={handleOnChangePasswords}
                autoComplete="off"
              />

              <Form.Control.Feedback type="invalid">
                Las contraseñas no coinciden
              </Form.Control.Feedback>
            </InputGroup>

            <Form.Text id="passwordHelpBlock2" muted>
              La contraseña debe ser igual a la anterior
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Col>
            <div className="d-grid gap-2 mt-4">
              <Button variant="success" type="submit" size="lg">
                Agregar administrador
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};
