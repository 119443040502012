import React, { useEffect } from "react";
import "@assets/css/components/Home/style.css";
import "react-circular-progressbar/dist/styles.css";

import { Button, Card, Col, Container, Image, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Carousel as ReactCarousel } from "react-responsive-carousel";

import lottie from "lottie-web";
import { defineElement } from "lord-icon-element";
import TitleDashboard from "@components/general/Titles/TitleDashboard";
import { useContext } from "react";
import UserContext from "@context/User/UserContext";
import multiViewComponentsPaths from "@helpers/multiViewComponentsPaths/multiViewComponentsPaths";
import { getUltimaLeccionTomada } from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faQuestion } from "@fortawesome/free-solid-svg-icons";
import useWindowResize from "@customHooks/useWindowResize";
import RecomendacionesIntereses from "@components/general/RecomendacionesIntereses/RecomendacionesIntereses";
import AtajosUsuarioEstudiante from "../components/AtajosUsuarioEstudiante";

import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Rating, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { getAgendasAsesoriasPublicas } from "@helpers/httpRequests/httpRequestAgendaAsesorias/httpRequestAgendaAsesorias";
import confetti from "canvas-confetti";
import { ModalSweetAlert } from "@components/general/Alertas/Alertas";
import { ModalNotificacion } from "../modales/ModalNotificacion";
import { updateFechaUltimoAcceso } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import getUnixTimestamp from "@helpers/dates/getUnixTimestamp/getUnixTimestamp";
import ReactStars from "react-rating-stars-component";
import ListadoCursos from "@components/general/Cursos/ListadoCursos";
import SplitText from "@helpers/animated/SplitText";
import SeccionSliderHome from "../components/SeccionSliderHome";


defineElement(lottie.loadAnimation);

const percentage = 66;

const SeccionPrincipalHome = ({ handleChangeComponent, handleStartTour }) => {
	const {
		size: { width },
	} = useWindowResize();

	const [ultimaLeccion, setUltimaLeccion] = useState(null);

	const [agendasAsesorias, setAgendasAsesorias] = useState([]);

	const slides = [
		{
			image: (
				<div className="image-wrapper" style={{ margin: "0 auto" }}>
					<picture width="100%" height="100%">
						<source srcSet={`${require("@assets/images/recursos_mos/logo_excel.png")}`} />
						<Image className="imageMos" src={`${require("@assets/images/recursos_mos/logo_excel.png")}`} alt="Imagen de slide" />
					</picture>
				</div>
			),
			title: "Certificado MOS",
			subtitle:
				"Prepárate para tu Certificado Microsoft Office Specialist Te ayudará a mejorar tu análisis de datos, la toma de decisiones y optimización de procesos, además de destacar para mejores puestos y oportunidades laborales.",
			control: (
				<Button
					onClick={() => {
						handleChangeComponent("cursoPropedeuticoMos");
					}}
					className="mt-3 btn-sm btn-responsive">
					Haz clic aquí
				</Button>
			),
		},
		{
			image: (
				<picture width="100%" height="100%">
					<source srcSet={`${require("@assets/images/recursos_mos/reseña_mos.webp")}`} />

					<source srcSet={`${require("@assets/images/recursos_mos/reseña_mos.png")}`} />
					<Image loading="lazy" className="img-fluid comentario-mos" src={`${require("@assets/images/recursos_mos/reseña_mos.webp")}`} alt="Imagen de slide" />
				</picture>
			),
		},
		{
			image: (
				<picture width="100%" height="100%">
					<source srcSet={`${require("@assets/images/recursos_mos/reseña_mos2.webp")}`} />

					<source srcSet={`${require("@assets/images/recursos_mos/reseña_mos2.png")}`} />
					<Image loading="lazy" className="img-fluid comentario-mos" src={`${require("@assets/images/recursos_mos/reseña_mos2.webp")}`} alt="Imagen de slide" />
				</picture>
			),
		},
		{
			image: (
				<picture width="100%" height="100%">
					<source srcSet={`${require("@assets/images/recursos_mos/reseña_mos3.webp")}`} />
					<source srcSet={`${require("@assets/images/recursos_mos/reseña_mos3.png")}`} />
					<Image loading="lazy" className="img-fluid comentario-mos" src={`${require("@assets/images/recursos_mos/reseña_mos3.webp")}`} alt="Imagen de slide" />
				</picture>
			),
		},
	];

	const { contextValue: userContextValue, setContextValue } = useContext(UserContext);


	const { usuarioLogeado } = userContextValue;

	const { modalAniversarioVisto, setModalAniversarioVisto } = useState(false);

	const { nombreCompleto, idPerfil, rol, idPersona } = usuarioLogeado;

	const modalNotificacion = localStorage.getItem('modalNotificacionVista');

	const handleUltimaLeccion = (codigoLeccion) => {
		// console.log(ultimaLeccion);

		const codigoCurso = codigoLeccion.substring(0, 4);

		const codigoSeccionSeleccionada = codigoLeccion.substring(6, 0);

		// console.log("Ultima Leccion Vista: ", codigoLeccion);

		setContextValue({
			...userContextValue,
			codigoCursoSeleccionado: codigoCurso,
			ultimoCursoTomado: codigoCurso,
			codigoSeccionSeleccionada,
		});

		handleChangeComponent(multiViewComponentsPaths.curso);
	};

	const handleMaterialCapacitacion = () => {
		const { materialCapacitacion } = multiViewComponentsPaths;

		handleChangeComponent(materialCapacitacion);
	};

	const obtenerUltimaLeccionVista = async () => {
		try {
			const { data } = await getUltimaLeccionTomada(idPerfil);
			// console.log(data);
			if (Array.isArray(data)) {
				setUltimaLeccion(data[0].codigoLeccion);
			} else {
				setUltimaLeccion(data.codigoLeccion);
			}
		} catch (error) {
			console.log(error);
			setUltimaLeccion(null);
		}
	};

	const obtenerAgendasPublicas = async () => {
		try {
			console.log('Obteniendo asesorias publicas')
			const agendas = await getAgendasAsesoriasPublicas();

			setAgendasAsesorias(agendas);
		} catch (error) {
			console.log("error al obtener las agendas publicas: ", error);
		}
	};


	// const fire = (particleRatio, opts) => {

	// 	const count = 550,
	// 	defaults = {
	// 		origin: { y: 0.7 },
	// 	};


	// 	confetti(
	// 		Object.assign({}, defaults, opts, {
	// 		particleCount: Math.floor(count * particleRatio),
	// 		})
	// 	);

	// }


	useEffect(() => {

		// if(modalNotificacion === null) {

		// 	fire(0.25, {
		// 		spread: 26,
		// 		startVelocity: 55,
		// 	  });

		// 	  fire(0.2, {
		// 		spread: 60,
		// 	  });

		// 	  fire(0.35, {
		// 		spread: 100,
		// 		decay: 0.91,
		// 		scalar: 0.8,
		// 	  });

		// 	  fire(0.1, {
		// 		spread: 120,
		// 		startVelocity: 25,
		// 		decay: 0.92,
		// 		scalar: 1.2,
		// 	  });

		// 	  fire(0.1, {
		// 		spread: 120,
		// 		startVelocity: 45,
		// 	  });

		// 	  setTimeout(async() => {
		// 		try {
		// 			await ModalSweetAlert({
		// 				html: <ModalNotificacion/>,
		// 				showConfirmButton: false,
		// 				showCloseButton: true,
		// 			});


		// 			} catch (error) {
		// 			console.log("Error al agregar una nueva lección: ", error);
		// 		}
		// 	}, 1000);
		// }




		// console.log('Entra en use effect de vista home principal')
		// mostrarNotificacion();

		const ultimoAcceso = getUnixTimestamp();

		const updateFechaAcceso = async () => {
			try {
				await updateFechaUltimoAcceso({
					idPersona: idPersona,
					fecha: ultimoAcceso,
				});
			} catch (error) {
				console.log(error);
			}
		}

		updateFechaAcceso();

		if (agendasAsesorias.length === 0) {
			obtenerAgendasPublicas();
		}

		obtenerUltimaLeccionVista();

	}, []);

	// const handleAnimationComplete = () => {
	// 	console.log("Finaliza la animacion")
	// }


	return (
		<>
			<Container fluid className="p-0 mt-4 mb-3 podria-interesar-container">
				<Row className="justify-content-between mt-5">
					<Col className="col-12 col-lg-9 mb-5 text-center text-md-start">

					<SplitText
						text={`¡Bienvenido/a de nuevo ${nombreCompleto}!`}
						delay={25}
						animationFrom={{ opacity: 0, transform: 'translate3d(0,50px,0)' }}
						animationTo={{ opacity: 1, transform: 'translate3d(0,0,0)' }}
						easing="easeOutCubic"
						threshold={0.2}
						rootMargin="-50px"
						// onLetterAnimationComplete={handleAnimationComplete}
						width={width}
						/>

						{/* // ) : (
						// 	<span style={{ fontSize: "1.3rem" }}>
						// 		¡Hola {nombreCompleto}! Te damos la bienvenida a la plataforma{" "}
						// 		<b style={{ fontSize: "1.5rem" }}>
						// 			Click <span style={{ color: "rgb(245, 158, 11)" }}>+</span>
						// 		</b>
						// 	</span>
						// )} */}
					</Col>

					{width >= 1200 && (
						<Col className="col-md-2 mb-4 " style={{ height: "0px" }}>
							<OverlayTrigger placement={"top"} overlay={<Tooltip>Conoce tu entorno</Tooltip>}>
								<Button onClick={handleStartTour} className="btn btn-primary mx-2">
									<FontAwesomeIcon className="mx-2 pointer question" style={{ fontSize: "1.8rem" }} icon={faQuestion} />
								</Button>
							</OverlayTrigger>


							<OverlayTrigger placement={"top"} overlay={<Tooltip>Descarga el manual de usuario</Tooltip>}>
								<a
									className="btn btn-primary mx-2"
									style={{ textDecoration: "none" }}
									label="Descargar PDF"
									href={rol === "asesor" ? "https://globalclickmexico.com/item/Manual para asesor Click+.pdf" : "https://globalclickmexico.com/item/Manual de usuario Click+.pdf"}
									target="_blank"
									rel="noreferrer"
								>
									<FontAwesomeIcon className="mx-2 pointer question" style={{ fontSize: "1.8rem" }} icon={faDownload} />
								</a>

							</OverlayTrigger>
						</Col>
					)}
				</Row>

				<Row className="justify-content-center justify-content-lg-between text-center mb-5 contenedor-lordicons">
					<AtajosUsuarioEstudiante handleChangeComponent={handleChangeComponent} handleUltimaLeccion={handleUltimaLeccion} ultimaLeccion={ultimaLeccion} rol={rol} />
				</Row>

				<Row>
					<Col>
						<RecomendacionesIntereses handleChangeComponent={handleChangeComponent} />
					</Col>
				</Row>

				<TitleDashboard title='Te podria interesar' className="interes mt-4" />

				<Row className="podria-interesar mt-4 mb-0 mb-md-5">
					<Col className={`col-12 columna-mos col-lg-5`}>
						<div className="contenedor-mos">
							<div className="contenedor-carrusel">
								<ReactCarousel interval={5000} showArrows={false} showStatus={false} showThumbs={false} swipeable={true} emulateTouch={true} infiniteLoop={true} autoPlay={false}>
									{slides.map((slide, key) => (
										<div className="contenido-mos" key={key}>
											{slide.image}

											<h6 className="mt-2">{slide.title}</h6>

											<div className="m-2 slide-subtitle">{slide.subtitle}</div>

											{slide.control}
										</div>
									))}
								</ReactCarousel>
							</div>
						</div>
					</Col>

					<Col className={`col-12 columna-capacitacion col-lg-7`}>
						<div className="parallax contenedor-material-capacitacion mt-3 mt-md-0">
							<div className="text-container">
								<div className="parallax-text">
									<h2>Material visto en capacitación</h2>

									<Button onClick={handleMaterialCapacitacion} className="btn-responsive" variant="warning">
										Ver material
									</Button>
								</div>
							</div>
						</div>
					</Col>


				</Row>


				<Row className={`podria-interesar mt-0 ${agendasAsesorias.length > 0 ? 'mt-md-5 mb-5' : 'mt-0 mb-0' }`}>
					{/* Seccion para mostrar las agendas de asesorias que son publicas. */}
					{agendasAsesorias.length > 0 ? (
							<>
							<Col className={`col-12 columna-capacitacion col-lg-7 `}>
								<div className="contenedor-feedback-fondo contenedor-material-capacitacion mt-3 mt-md-0">
									<div className="text-container">
										<div className="parallax-text">
											<h2>Reseñas de nuestros clientes</h2>

											<h4>Conoce los comentarios de nuestras asesorías personalizadas</h4>
										</div>
									</div>
								</div>
							</Col>

							<Col className={`col-12 columna-mos col-lg-5 mt-5 mt-lg-0`}>
								<div className="contenedor-feedback-inicio">
									<div className=" feedbacks shadow-lg">
										<ReactCarousel interval={5000} showArrows={false} showStatus={false} showThumbs={false} swipeable={true} emulateTouch={true} infiniteLoop={true} autoPlay={false}>
											{agendasAsesorias.map((item, key) => (
												<div className="pt-0" key={key}>
													<Row className="justify-content-center h-100">
														<Col className="col-auto text-center">
															<div style={{ background: "#fff", borderRadius: 10 }} className="m-5 p-3 shadow-lg">

																<ListItemAvatar className="mb-2" >
																	<Avatar style={{ margin: '0px auto' }} sx={{ width: 75, height: 75 }}>
																		<AccountCircleIcon sx={{ fontSize: 60 }} />
																	</Avatar>
																</ListItemAvatar>

																<Row className="text-center justify-content-center">
																	<Col className="col-12">

																		<h4 className="mt-2">Asesoría en {item.temaElegido}</h4>

																		<span className="mb-2" style={{ color: '#000', fontWeight: 'bold', fontStyle: 'italic' }}>"{item.feedback}"</span>

																		<p className="mt-4"><small>{`${item.nombreCompleto} ${item.apellidos}`}</small></p>
																	</Col>
																	<Col className="col-auto">
																		<ReactStars count={5} edit={false} value={item.calificacion} size={35} activeColor="#ff9900" />
																	</Col>
																</Row>

															</div>
														</Col>
													</Row>
												</div>
											))}
										</ReactCarousel>
									</div>
								</div>
							</Col>
							</>
					) : null}
				</Row>

			
			</Container>

		</>

	);
};

export default SeccionPrincipalHome;
