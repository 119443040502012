import { faCheck, faDownload, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import getDateFromUnix from "@helpers/dates/getDateFromUnix/getDateFromUnix";
import getUnixTimestamp from "@helpers/dates/getUnixTimestamp/getUnixTimestamp";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import _ from "lodash";
import typesTiposUsuario from "@helpers/types/typesTiposUsuario";
import { getDatosSegundoUsuario } from "@helpers/httpRequests/httpRequestsUsuarios/estudiantes/httpRequestsEstudiantes";
import { getGrupoById } from "@helpers/httpRequests/httpRequestsGrupos/httpRequestsGrupos";
import { getLicencia } from "@helpers/httpRequests/httpRequestsLicencias/httpRequestsLicencias";
import { getCertificacionesByIdPerfil, getFechaFinalizacionCurso, guardarCertificado, verificarCertificado } from "@helpers/httpRequests/httpRequestsCertificadosEmitidos/httpRequestsCertificadosEmitidos";
import { getTiempoPlataforma } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import moment from "moment";
import confetti from "canvas-confetti";
import getFechaSinHora from "@helpers/dates/getDateFinalizacionCurso/getFechaSinHora";
import getURLCodigoQR from "@components/general/GenerarQR/getURLCodigoQR/getURLCodigoQR";
import QRCode from "qrcode";
import getFilledPdfBytes from "@components/general/GenerarQR/getFilledPdfBytes/getFilledPdfBytes";
import { NotificacionExtendida } from "@components/general/Alertas/Alertas";
import { getCursoByCodigo } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";


const VerUsuario = ({ usuario }) => {

	const [datosLicencia, setDatosLicencia] = useState([]);

	const [cursosLicencia, setCursosLicencia] = useState(JSON.parse(usuario.cursos));

	const [tiempoVigencia, setTiempoVigencia] = useState({
		diasVigencia: "",
		mesesVigencia: "",
	});

	const [showProgreso, setShowProgreso] = useState(false);

	const [cursosTerminados, setCursosTerminados] = useState([]);

	const [datosUsuarioSecundario, setDatosUsuarioSecundario] = useState({});

	const [grupoEmpresarial, setGrupoEmpresarial] = useState([]);

	const [diasPlataforma, setDiasPlataforma] = useState(0);

	const [horasPlataforma, setHorasPlataforma] = useState(0);

	const [minutosPlataforma, setMinutosPlataforma] = useState(0);

	const [yearsPlataforma, setYearsPlataforma] = useState(0);

	const [mesesPlataforma, setMesesPlataforma] = useState(0);

	const [dataImage, setDataImage] = useState({
		dataImage: "",
	});

	const { diasVigencia, mesesVigencia } = tiempoVigencia;

	const calcularTiempoVigencia = () => {
		const diferenciaEnMilisegundos = usuario.fechaExpiracion - getUnixTimestamp();

		const diferenciaEnDias = diferenciaEnMilisegundos / 86400;

		// if (diferenciaEnDias < 30) {
		// 	console.log(`Faltan ${Math.round(diferenciaEnDias)} días`);
		// } else {
		// 	console.log(`Faltan ${Math.floor(diferenciaEnDias / 30)} meses`);
		// }

		setTiempoVigencia({
			diasVigencia: Math.round(diferenciaEnDias),
			mesesVigencia: Math.floor(diferenciaEnDias / 30),
		});
	};

	const consultarUsuarioSecundario = async () => {
		try {
			const { data: datosSegundoUsuario } = await getDatosSegundoUsuario(usuario.segundoPerfil);

			const { data } = datosSegundoUsuario;

			setDatosUsuarioSecundario(data);
		} catch (error) {
			console.log(error);
		}
	};

	const consultarGrupoEmpresarial = async () => {
		try {
			const { data: grupoEmpleado } = await getGrupoById(usuario.idGrupo);

			setGrupoEmpresarial(grupoEmpleado);
		} catch (error) {
			console.log("Error al consultar el grupo del empleado seleccionado: ", error);
		}
	};

    const consultarDatosLicencia = async () => {
        try {
          const { data: licencia } = await getLicencia(usuario.idLicencia);

          setDatosLicencia(licencia);

          setCursosLicencia(JSON.parse(licencia.cursos));

          calcularTiempoVigencia();
        } catch (error) {
          console.log("Error al consultar los datos de la licencia: ", error);
        }
      };

	  const validarCodigoCurso = (codigoCurso) => {
		if(cursosTerminados.includes(codigoCurso)){
			return true;
		}else{
			return false
		}
	  }

	  const exportarInformacion= ()=> {
		try{
			const html2pdf = require('html2pdf.js');
			var element = document.getElementById('informe');
			html2pdf(element, {
				filename: `Reporte_${usuario.nombreCompleto}_${usuario.apellidos}_${getUnixTimestamp()}`
			});
		}catch(error){
			console.log("Error al exportar la informacion del usuario")
		}
	  }

	  const validarCursoFinalizado = async() => {
		try {
			const res = await getCertificacionesByIdPerfil(usuario.idPerfil);

			if(!Array.isArray){
				setCursosTerminados(res.data.curso);
			}else{
				let arrAux = [];
				for (const curso in res.data) {
					if (Object.prototype.hasOwnProperty.call(res.data, curso)) {
						const element = res.data[curso];
						console.log('Se inserta la info en el arreglo')
						console.log(element.curso)
						arrAux.push(element.curso)
					}
				}
				setCursosTerminados(arrAux);
			}
		} catch (error) {
			console.log(error);
		}
	  }

	  const regresar = () => {
		setCursosTerminados([]);
		setShowProgreso(false)
	  }

	  const generarQR = async (codigoCurso) => {
		var end = Date.now() + 15 * 250;

		// go Buckeyes!
		var colors = ["#00152A", "#ff9900"];

		(function frame() {
		  confetti({
			particleCount: 2,
			angle: 60,
			spread: 35,
			origin: { x: 0 },
			colors: colors,
		  });
		  confetti({
			particleCount: 2,
			angle: 120,
			spread: 35,
			origin: { x: 1 },
			colors: colors,
		  });

		  if (Date.now() < end) {
			requestAnimationFrame(frame);
		  }
		})();

		const {data: infoCurso} = await getCursoByCodigo(codigoCurso);

		try {
		  const infoCertificado = {
			rol: usuario.rol,
			idUsuarioEmpresarial: usuario.idUsuarioEmpresarial,
			idPerfil: usuario.idPerfil,
			codigoCurso: codigoCurso,
			nombre: usuario.nombreUsuario,
			curp: usuario.curp,
			fecha: getUnixTimestamp(),
			nombreCurso: infoCurso.nombre,
		  };


		  const { data } = await guardarCertificado(infoCertificado);

		  const { data: fechaFinalizacion } = await getFechaFinalizacionCurso({
			idPerfil: usuario.idPerfil,
			codigoCurso,
		  });

		  let payloadCodigoQR = {
			fechaFinalizacion: getFechaSinHora(fechaFinalizacion.fecha),
			fechaUnix: fechaFinalizacion.fecha,
			nombre: data.info.data.nombre,
			nombreCurso: infoCurso.nombre,
			tiempoCurso: infoCurso.tiempoCurso,
			idPerfil: usuario.idPerfil,
			codigoCurso,
			curso: infoCurso.isMsOffice,
		  };

		  //TODO Hacer debug para encontrar el porque no pasa correctamente la informacion para generar el qr

		  const urlQR = getURLCodigoQR(payloadCodigoQR);

		  const qrData = await QRCode.toDataURL(urlQR);

		  payloadCodigoQR.dataQR = qrData;

		  await createPdf(payloadCodigoQR, infoCurso.isMsOffice, infoCurso.nombre);
		} catch (error) {
		  console.log(error);
		}
	  };

	  //Dentro de este componente se deberá validar la categoría del curso para seleccionar el fetch correcto de la plantilla de certificado

	  const createPdf = async (dataCodigoQR, isMsOffice, nombreCurso) => {
		try {
		  let url = `${window.location.origin}/assets/templates/template_certificado.pdf`;

		  if (isMsOffice) {
			url = `${window.location.origin}/assets/templates/template_certificado_microsoft_qr.pdf`;
		  }

		  const data = await fetch(url);

		  const pdfTemplateBytes = await data.arrayBuffer();

		  const filledPdfBytes = await getFilledPdfBytes(
			pdfTemplateBytes,
			dataCodigoQR
		  );

		  saveByteArray(`Certificado_${nombreCurso}_${usuario.nombreCompleto}_${usuario.apellidos}`, filledPdfBytes);
		} catch (error) {
		  console.log(error);
		}
	  };

	  //Funcion para descargar el pdf sin la libreria
	  const saveByteArray = (reportName, byte) => {
		var blob = new Blob([byte], { type: "application/pdf" });

		var link = document.createElement("a");

		link.href = window.URL.createObjectURL(blob);

		var fileName = reportName;

		setDataImage({
		  ...dataImage,
		  dataImage: link.href,
		});

		link.download = fileName;

		link.click();

		// NotificacionExtendida("success", "Certificado generado con éxito");
	  };

	useEffect(() => {
        consultarDatosLicencia();

		if (usuario.rol === typesTiposUsuario.estudiante) {
			if (usuario.usuarioTitular) {
                if (usuario.segundoPerfilRegistrado) {
                    consultarUsuarioSecundario();
                }
			}
		}

		if (usuario.rol === typesTiposUsuario.asesor || usuario.rol === typesTiposUsuario.empleado) {
			consultarGrupoEmpresarial();
		}
	}, []);

	useEffect(() => {
	  if(showProgreso){

		const tiempoPlataforma = async () => {
			try {
				const { data } = await getTiempoPlataforma(usuario.idPersona);
				// console.log(data);
				const { tiempoEnPlataforma } = data;

				const duracion = moment.duration(tiempoEnPlataforma, "minutes");

				const { days, hours, minutes, months, years } = duracion._data;

				setDiasPlataforma(days);
				setHorasPlataforma(hours);
				setMinutosPlataforma(minutes);
				setMesesPlataforma(months);
				setYearsPlataforma(years);
			} catch (error) {
				console.log(error);
			}
		};

		tiempoPlataforma();
		validarCursoFinalizado();
	  }
	}, [showProgreso])


	return (
		<Container style={{position: "relative"}} fluid className="text-start" id="informe">

			{showProgreso ? (
				<>
					<div className="text-center" style={{position: "absolute", zIndex: -1}}>
					<Image style={{opacity: "20%"}} src={require("@assets/images/logoGCvertical.png")} fluid/>
					</div>
					<Row className="mb-4 mt-2">
						<Col>
							<Row>
								<Col>
									<Image src={require("@assets/images/click.png")} width={250} />
								</Col>
							</Row>
							<Row className="justify-content-between">
								<Col className="col-auto">
									<h3>Estadísticas generales</h3>
								</Col>
								<Col className="col-auto">
									<Button onClick={regresar} className="btn btn-danger" data-html2canvas-ignore>Regresar</Button>
									<OverlayTrigger placement={"top"} overlay={<Tooltip>Exportar información</Tooltip>}>
										<Button onClick={exportarInformacion} className="mx-3" data-html2canvas-ignore>
											<FontAwesomeIcon className=" pointer question" icon={faDownload} />
										</Button>
									</OverlayTrigger>
								</Col>
							</Row>
							<hr />

							<Container fluid>
								<Row>
									<Col>
										<p>
											<b>Nombre:</b> {usuario.nombreCompleto} {usuario.apellidos}
										</p>

										<p>
											<b>Certificados obtenidos: {cursosTerminados.length}</b>
										</p>

										<p>
											<span>
												<b>Tiempo en plataforma:</b> {yearsPlataforma > 0 && `${yearsPlataforma} ${yearsPlataforma === 1 ? "Año" : "Años"}`}{" "}
												{mesesPlataforma > 0 && `${mesesPlataforma} ${mesesPlataforma === 1 ? "Mes" : "Meses"}`} {diasPlataforma > 0 && `${diasPlataforma} ${diasPlataforma === 1 ? "Día" : "Días"}`}{" "}
												{horasPlataforma > 0 && `${horasPlataforma} ${horasPlataforma === 1 ? "Hora" : "Horas"}`}{" "}
												{minutosPlataforma > 0 && `${minutosPlataforma} ${minutosPlataforma === 1 ? "Minuto" : "Minutos"}`}
											</span>
										</p>


									</Col>
								</Row>
							</Container>

						</Col>
					</Row>

					<Row className="mb-3">
						<Col>
							<Row className="justify-content-between">
								<Col className="col-auto">
									<h3>Tus cursos</h3>
								</Col>
							</Row>
							<hr />

							<Container fluid>
								<Row>
									{(() => {
										const chunkSize = cursosLicencia.length > 1 ? Math.ceil(cursosLicencia.length / 2) : cursosLicencia.length;

										const chunks = _.chunk(cursosLicencia, chunkSize);

										return chunks.map((chunk, idxChunk) => (
											<Col key={idxChunk} className="p-0 mx-4">
												{chunk.map((curso, idxCurso) => (
												<Row key={idxCurso} className="mb-3">
													<Col className="ml-2">
														<h6><b>- {curso.nombre}</b></h6>
													</Col>
													<Col>
														<Row className="justify-content-between">
															<Col className="col-auto">
																<h6>{validarCodigoCurso(curso.codigoCurso) ? <h6 className="text-success"><b>Finalizado</b></h6> : <h6 className="text-danger"><b>Sin Finalizar</b></h6>}</h6>
															</Col>
															<Col className="col-auto">
																{validarCodigoCurso(curso.codigoCurso) ? (
																	<OverlayTrigger placement={"top"} overlay={<Tooltip>Descargar certificado</Tooltip>}>
																	<Button onClick={() => generarQR(curso.codigoCurso)} className="btn-sm mx-2" data-html2canvas-ignore>
																		<FontAwesomeIcon className=" pointer" icon={faDownload} />
																	</Button>
																</OverlayTrigger>
																): null}
															</Col>
														</Row>
													</Col>
												</Row>
												))}
											</Col>
										));
									})()}

								</Row>
							</Container>

						</Col>
					</Row>
				</>
			): null}

			{!showProgreso ? (
				<Row>
					<Col>
						<Row className="justify-content-between">
							<Col className="col-auto">
								<h3>Datos personales</h3>
							</Col>
							<Col className="col-auto">
								<Button onClick={()=> setShowProgreso(true)} className="btn btn-primary">Ver progreso</Button>
							</Col>
						</Row>
						<hr />

						<Container fluid>
							<Row>
								<Col>
									<p>
										<b>Nombre:</b> {usuario.nombreCompleto} {usuario.apellidos}
									</p>

									<p>
										<b>Correo electrónico:</b> {usuario.correo}
									</p>

									<p>
										<b>CURP:</b> {usuario.curp}
									</p>

									<p>
										<b>{usuario.usuarioTitular ? <span className="text-success">Usuario titular</span> : <span className="text-success">Usuario Secundario</span>}</b>
									</p>

									{usuario.rol === typesTiposUsuario.estudiante ? (
										<p>
											{usuario.usuarioTitular ? (
												<span>
													<b>Usuario secundario:</b> {(datosUsuarioSecundario.nombreCompleto !== undefined && datosUsuarioSecundario.apellidos !== undefined) ? datosUsuarioSecundario.nombreCompleto + " " + datosUsuarioSecundario.apellidos : <span className="text-danger">Sin usuario registrado</span>}
												</span>
											) : (
												<span>
													<b>Usuario titular:</b> {usuario.nombreCompleto + " " + usuario.apellidos}
												</span>
											)}
										</p>
									) : null}
								</Col>

								<Col>
									<p>
										<b>Folio de contrato:</b> {`${usuario.codigoRegion}-${usuario.folioContrato}`}
									</p>

									<p>
										<b>Nombre de usuario:</b> {usuario.nombreUsuario}
									</p>

									<p>
										<b>Profesión:</b> {usuario.profesion !== "" ? usuario.profesion : "No especificado"}
									</p>
								</Col>
							</Row>
						</Container>

					</Col>
				</Row>
			): null}

			{!showProgreso ? (
				<Row>
					<Col>
						<h3>Datos de la Licencia</h3>
						<hr />

						<Container fluid>
							<Row>
								<Col>
									<p>
										<b>Fecha de registro: </b> {getDateFromUnix(usuario.fechaRegistro)}
									</p>

									<p>
										<b>Fecha último acceso: </b> {usuario.fechaUltimoAcceso !== 0 && usuario.fechaUltimoAcceso !== null ? getDateFromUnix(usuario.fechaUltimoAcceso) : "Sin ingresos registrados"}
									</p>

									<p>
										<b>Tiempo restante: </b> {usuario.fechaUltimoAcceso !== 0 ? (mesesVigencia > 0 ? `${mesesVigencia} meses` : `${diasVigencia} días`) : `${datosLicencia.tiempoVigencia} meses`}
									</p>
								</Col>

								<Col>
									<p>
										<b>Licencia: </b> {usuario.nombreLicencia}
									</p>

									<p>
										<b>Tiempo de vigencia: </b> {usuario.tiempoVigencia} meses
									</p>

									<p>
										<b>Usuario adicional: </b>
										{usuario.usuarioExtra ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : <FontAwesomeIcon icon={faTimes} className="text-danger" />}
									</p>
								</Col>
							</Row>

							<Row>
								<Col>
									<b>Cursos de la licencia</b>

									<Container fluid className="p-0">
										<Row>
											{(() => {
												const chunkSize = cursosLicencia.length > 1 ? Math.ceil(cursosLicencia.length / 2) : cursosLicencia.length;

												const chunks = _.chunk(cursosLicencia, chunkSize);

												return chunks.map((chunk, idxChunk) => (
													<Col key={idxChunk} className="p-0">
														<ul>
															{chunk.map((curso, idxCurso) => (
																<li key={idxCurso}>
																	<h6>{curso.nombre}</h6>
																</li>
															))}
														</ul>
													</Col>
												));
											})()}
										</Row>
									</Container>
								</Col>
							</Row>
						</Container>
					</Col>
				</Row>
			): null }

			{!showProgreso ? (
				usuario.rol === typesTiposUsuario.asesor || usuario.rol === typesTiposUsuario.empleado ? (
					<Row>
						<Col>
							<h3>Datos de grupo empresarial</h3>
							<hr />

							<Container fluid>
								<Row>
									<Col>
										<p>
											<b>Nombre de grupo empresarial: </b> {grupoEmpresarial.nombre !== "" ? grupoEmpresarial.nombre : "Sin nombre asignado"}
										</p>

										<p>
											<b>Nombre de la empresa: </b> {grupoEmpresarial.nombreEmpresa !== "" ? grupoEmpresarial.nombreEmpresa : "Sin nombre asignado"}
										</p>
									</Col>

									<Col>
										<p>
											<b>Folio del grupo: </b> {grupoEmpresarial.folioGrupo}
										</p>
									</Col>
								</Row>
							</Container>
						</Col>
					</Row>
				) : null
			): null}

		</Container>
	);
};

export default VerUsuario;
