import { Notificacion } from '@components/general/Alertas/Alertas';
import endpointsPaths from '@helpers/httpRequests/endpointsPaths/endpointsPaths';
import httpFormDataRequest from '@helpers/httpRequests/httpFormDataRequest';
import httpRequestsPaths from '@helpers/httpRequestsPaths/httpRequestsPaths';
import regExpStrings from '@helpers/regExpStrings/regExpStrings';
import React, { useEffect, useRef, useState } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import { NotificationManager } from 'react-notifications';

const ImportarSecciones = ({setSeccionesImportadas}) => {
      const refInputFile = useRef(null);

      const [archivoValido, setArchivoValido] = useState(false);

      const [formValues, setFormValues] = useState({
        archivoAdjunto: '',
      })

    const importarSecciones = async (e) => {

    e.preventDefault();

        const {archivoAdjunto} = formValues;
        try {
            if (archivoAdjunto !== "") {
                console.log("Enviando archivo al servidor")
                //FETCH PARA MANDAR LOS DATOS DEL ARCHIVO AL SERVIDOR
                const formData = new FormData();

                formData.append("archivoAdjunto", archivoAdjunto);

                const { code, message: fetchMessage } = await httpFormDataRequest({
                    url: `${endpointsPaths.secciones}/${httpRequestsPaths.secciones.manejoArchivos.addNuevoArchivo}`,
                    body: formData,
                });

                if (code !== 200) {
                    Notificacion("error", fetchMessage);
                } else {
                    console.log("Archivo subido con exito.")
                    setSeccionesImportadas(true);
                    Notificacion("success", "Información importada con éxito");
                    
                }
            }else{
                console.log("Algo ocurrio al enviar el archivo")
            }
        }
        catch (error) {
            console.log("Error al importar las secciones");
        }
    }


      const handleOnChangeFile = (event) => {
        const { name, value } = event.target;
        // const reader = new FileReader();
        // reader.onload = function() {
    
        //     setBinaryData(reader.result);
        // };
        // reader.readAsArrayBuffer(event.target.files[0]);
    
        let file = event.target.files[0];
    
        if (regExpStrings.regexCaracteresUTF8(file.name)) {
          console.log("tiene utf-8");
    
          refInputFile.current.value = "";
    
          refInputFile.current.previousElementSibling.innerHTML =
            "Seleccionar archivo:";
    
          setArchivoValido(false);
    
          return NotificationManager.error("Nombre de archivo inválido");
        }
    
        setArchivoValido(true);
    
        setFormValues({
          ...formValues,
          [name]: file,
        });
    
        // const reader = new FileReader();
        // reader.readAsDataURL(file);
        // reader.onload = () => {
        //   setFileBase64(reader.result.split(",")[1]);
        // };
      };

      useEffect(() => {
        console.log(formValues);    
      }, [formValues])
      
    
  return (
   <Form onSubmit={importarSecciones}>
        <Form.Group>
              <Form.Label>Adjuntar archivo</Form.Label>

              {/* //* VALIDAR DEL LADO DEL SERVIDOR QUE LOS ARCHIVOS SEAN DE PAQUETERIA OFFICE O PDF */}
              <Form.Control
                ref={refInputFile}
                required
                onChange={handleOnChangeFile}
                name="archivoAdjunto"
                accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf"
                type="file"
              />

              {/* {validacionForm.archivoAdjunto && <p className="text-danger mt-3">Es necesario adjuntar un archivo a la publicación</p>} */}

              <Alert
                variant="warning"
                className={`mt-3 ${!archivoValido ? "" : "d-none"}`}
              >
                Asegúrate que el nombre de tu archivo no contenga tildes ni
                caracteres especiales
              </Alert>

              <Form.Control.Feedback type="invalid">
                <p>Este campo es obligatorio</p>
              </Form.Control.Feedback>
            </Form.Group>

            <div className='gap-2 d-grid mt-3'>
                <Button type="submit" className='btn btn-primary'>Importar secciones</Button>
            </div>

   </Form>
  )
}

export default ImportarSecciones