import { faArrowLeft, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { validateTokenFolio } from "@helpers/httpRequests/httpRequestsSAI/httpRequestsSAI";
import { getUsuario, updateNombreUsuario, validateExistenciaNombreUsuario } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import Loader from "@helpers/loader/loader";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Image, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import "@assets/css/views/Login/style.css";
import { Typography } from "@mui/material";
import dashboardRoutesPaths from "@helpers/dashboardRoutesPaths/dashboardRoutesPaths";
import regExpStrings from "@helpers/regExpStrings/regExpStrings";
import { updateNombreUsuarioExterno } from "@helpers/httpRequests/httpRequestCursoIngles/httpRequestCursoIngles";
import { NotificationManager } from "react-notifications";

const FormularioRecuperacionNombreUsuario = () => {
	const urlParams = new URLSearchParams(useLocation().search);

	const formValuesInitialState = {
		nuevoNombreUsuario: "",
	};

	const [formValues, setFormValues] = useState(formValuesInitialState);

	const [showLoader, setShowLoader] = useState(true);

	const [loading, setLoading] = useState(false);

	const [nombreUsuarioActualizado, setNombreUsuarioActualizado] = useState(false);

	const [idCredencial, setIdCredencial] = useState(0);

	const [usuario, setUsuario] = useState({});

	const [validacion, setValidacion] = useState({
		nombreUsuario: false,
		caracteresEspecialesNombreUsuario: false,
		espaciosNombreUsuario: false,
	});

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);

			await updateNombreUsuarioExterno({
				nombreUsuarioNuevo: formValues.nuevoNombreUsuario,
				correo: usuario.correo,
			});

			await updateNombreUsuario({
				idCredencial: usuario.idCredencial,
				nombreUsuario: formValues.nuevoNombreUsuario,
			});

			setLoading(false);

			setNombreUsuarioActualizado(true);

			NotificationManager.success("Nombre de usuario actualizado con éxito");
		} catch (error) {
			console.log("error al actualizar el nombre de usuario: ", error);

			NotificationManager.error("Algo sucedió al intentar actualizar el nombre de usuario");
		}
	};

	const handleOnChangeNombreUsuario = async ({ target }) => {
		try {
			const { name, value } = target;

			setFormValues({
				...formValues,
				[name]: value,
			});

			let nombreUsuarioValido = false;

			let espacios = true;
			let caracteresEspeciales = true;

			if (regExpStrings.regexCaracteresEspecialesNoArroba(value)) {
				caracteresEspeciales = false;
			}

			try {
				await validateExistenciaNombreUsuario(value);

				nombreUsuarioValido = true;
			} catch (error) {
				console.log("Error validando el nombre de usuario: ", error);

				nombreUsuarioValido = false;
			}

			setValidacion({
				...validacion,
				nombreUsuario: nombreUsuarioValido,
				espaciosNombreUsuario: espacios,
				caracteresEspecialesNombreUsuario: caracteresEspeciales,
			});
		} catch (e) {
			console.log("catch", e);
		}
	};

	const validarToken = async (token, folioContrato, correo) => {
		try {
			// await validateTokenFolio(folioContrato, token);
			console.log("Validando usuario")
			await consultarDatosUsuario(correo);

			setShowLoader(false);
		} catch (error) {
			console.log("algo sucedió al validar el token: ", error);
		}
	};

	const consultarDatosUsuario = async (correo) => {
		try {
			const datosUsuario = await getUsuario(correo);

			setUsuario(datosUsuario);

			setIdCredencial(datosUsuario.idCredencial);
		} catch (error) {
			console.log("error al consultar los datos del usuario: ", error);
		}
	};

	useEffect(() => {
		const correo = urlParams.get("correo");

		const token = urlParams.get("token");

		const folioContrato = urlParams.get("folioContrato");

		if (Object.keys(usuario).length === 0) {
			validarToken(token, folioContrato, correo);
		}
	}, []);

	return showLoader ? (
		<Loader />
	) : (
		<Container fluid className="formRegistro vh-100 d-flex flex-column align-items-center justify-content-center" style={{ overflowY: "hidden" }}>
			<Card body className="w-50">
				<a style={{ textDecoration: "none" }} href={dashboardRoutesPaths.login} className="text-secondary pointer">
					<FontAwesomeIcon icon={faArrowLeft} /> Iniciar sesión
				</a>
				{!nombreUsuarioActualizado ? (
					<>
						<h5 className="text-center mt-3">Restablece tu nombre de usuario</h5>

						<Row className="my-4 mb-3 justify-content-center">
							<Col>
								<Form onSubmit={handleSubmit}>
									<Form.Group className='mb-3'>
										<Form.Label>Nombre de usuario actual</Form.Label>

										<Form.Control value={usuario.nombreUsuario} type="text" disabled />
									</Form.Group>

									<Form.Group>
										<Form.Label>Nuevo nombre de usuario</Form.Label>

										<Form.Control
											onChange={handleOnChangeNombreUsuario}
											value={formValues.nuevoNombreUsuario}
											type="text"
											autoComplete="off"
											name="nuevoNombreUsuario"
											placeholder="Ingres tu nuevo nombre de usuario"
										/>

										<Form.Text className="text-muted">
											No debe contener:
											<ul>
												<li className={validacion.caracteresEspecialesNombreUsuario ? "text-success" : "text-danger"}>Caracteres especiales</li>
												<li className={validacion.espaciosNombreUsuario ? "text-success" : "text-danger"}>Espacios</li>
											</ul>
										</Form.Text>
									</Form.Group>

									<div className="d-grid gap-2 mt-3">
										<Button variant="success" type="submit" size="lg">
											Cambiar Nombre de usuario {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : null}
										</Button>
									</div>
								</Form>
							</Col>
						</Row>
					</>
				) : (
					<>
						<h5 className="text-center mt-3">Tu nombre de usuario fue actualizado correctamente.</h5>

						<Row className="my-4 mb-3 justify-content-center">
							<Col>
								<Typography gutterBottom variant="body2" className="text-center">
									Ingresa con tu nuevo nombre de usuario y la contraseña que recuerdes.
								</Typography>

								<Image className="d-flex align" src={require("@assets/images/check.png")} width={150} />
							</Col>
						</Row>
					</>
				)}
			</Card>
		</Container>
	);
};

export default FormularioRecuperacionNombreUsuario;
