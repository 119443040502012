import { Col, Container, Row, Button, FloatingLabel, Form } from "react-bootstrap";
import React, { useMemo } from "react";
import { getAsesores } from "@helpers/httpRequests/httpRequestsUsuarios/asesores/httpRequestsAsesores";
import { getEmpleados } from "@helpers/httpRequests/httpRequestsUsuarios/empleados/httpRequestsEmpleados";
import { getEstudiantes } from "@helpers/httpRequests/httpRequestsUsuarios/estudiantes/httpRequestsEstudiantes";
import localStorageItemNames from "@helpers/localStorage/localStorageItemNames";
import setLocalStorage from "@helpers/localStorage/setLocalStorage";
import { useEffect } from "react";
import { useState } from "react";
import { ModalSweetAlert, Notificacion } from "@components/general/Alertas/Alertas";
import ExtensionVigencia from "./components/ExtenderVigencia/ExtensionVigencia";
import CambioContraseña from "./components/CambiarContraseña/CambioContraseña";
import { useContext } from "react";
import UserContext from "@context/User/UserContext";
import multiViewComponentsPaths from "@helpers/multiViewComponentsPaths/multiViewComponentsPaths";
import typesTiposUsuario from "@helpers/types/typesTiposUsuario";
import CambioNIP from "./components/CambiarNIP/CambioNIP";
import RemoverUsuario from "./components/RemoverUsuario/RemoverUsuario";
import { MaterialReactTable } from "material-react-table";
import { Alert, Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { NotificationManager } from "react-notifications";
import { buscarUsuarios, getCertificacionesMosByIdCredencial, reactivateUsuario } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import GestionCertificacionMos from "./components/GestionarCertificacionMOS/GestionCertificacionMos";
import TitleDashboard from "@components/general/Titles/TitleDashboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSpinner } from "@fortawesome/free-solid-svg-icons";
import VerUsuario from "./components/VerUsuario/VerUsuario";
import EditarUsuario from "./components/EditarUsuario/EditarUsuario";
import DesactivarUsuario from "./components/DesactivarUsuario/DesactivarUsuario";
import AgregarCurso from "./components/AgregarCurso/AgregarCurso";
import EliminarCurso from "./components/EliminarCurso/EliminarCurso";
import CambioLicencia from "../CambiosLicencia/CambioLicencia";

function esValido(json) {
	return Object.keys(json).length > 0;
}

const TableBuscadorUsuarios = ({ handleChangeTab, handleChangeComponent }) => {
	const [busquedaActiva, setBusquedaActiva] = useState("");

	const [rolUsuario, setRolUsuario] = useState("");

	const [refetchUsuarios, setRefetchUsuarios] = useState(false);

	const [datosUsuarios, setDatosUsuarios] = useState([]);

	const { contextValue, setContextValue } = useContext(UserContext);

	const { usuarioLogeado } = contextValue;

	const consultarDatosUsuarios = async () => {
		try {
			let { data: asesores } = await getAsesores();

			let { data: empleados } = await getEmpleados();

			let { data: estudiantes } = await getEstudiantes();

			if (!Array.isArray(asesores)) {
				asesores = [asesores];
			}

			if (!Array.isArray(empleados)) {
				empleados = [empleados];
			}

			if (!Array.isArray(estudiantes)) {
				estudiantes = [estudiantes];
			}

			setDatosUsuarios([...asesores, ...empleados, ...estudiantes].sort((a, b) => parseInt(a.folioContrato) - parseInt(b.folioContrato)));

			setRefetchUsuarios(false);
		} catch (error) {
			console.log("Error al consultar los asesores registrados: ", error);
		}
	};

	const handleVerDatosUsuario = async (idPersona) => {
		const dataUsuario = datosUsuarios.filter((usuario) => usuario.idPersona === idPersona)[0];

		await ModalSweetAlert({
			title: "Ver Usuario",
			html: <VerUsuario usuario={dataUsuario} />,
			showConfirmButton: false,
			showCloseButton: true,
		});

		// const { rol, correo } = dataUsuario;

		// setBusquedaActiva(dataUsuario);

		// setRolUsuario(rol);

		// setLocalStorage(localStorageItemNames.busquedaUsuarioActiva, true);

		// setLocalStorage(localStorageItemNames.busquedaUsuarioData, dataUsuario);
	};

	const handleEditarDatosUsuario = async (idPersona) => {
		const dataUsuario = datosUsuarios.filter((usuario) => usuario.idPersona === idPersona)[0];

		await ModalSweetAlert({
			title: "Editar información",
			html: <EditarUsuario usuario={dataUsuario} />,
			showConfirmButton: false,
			showCloseButton: true,
		});
	};

	const handleEstatusCredencial = async ({ estatusCredencial, idPersona }) => {
		const dataUsuario = datosUsuarios.filter((usuario) => usuario.idPersona === idPersona)[0];

		if (estatusCredencial) {
			await ModalSweetAlert({
				title: "Desactivar usuario",
				html: <DesactivarUsuario usuario={dataUsuario} />,
				showConfirmButton: false,
				showCloseButton: true,
			});
		} else {
			await reactivateUsuario(dataUsuario.idCredencial);

			Notificacion("success", "El usuario se reactivó correctamente");
		}
	};

	const handleAgregarCurso = async (idPersona) => {
		const dataUsuario = datosUsuarios.filter((usuario) => usuario.idPersona === idPersona)[0];

		await ModalSweetAlert({
			title: "Agregar cursos",
			html: <AgregarCurso usuario={dataUsuario} />,
			showConfirmButton: false,
			showCloseButton: true,
		});
	};

	const handleEliminarCurso = async (idPersona) => {
		const dataUsuario = datosUsuarios.filter((usuario) => usuario.idPersona === idPersona)[0];

		await ModalSweetAlert({
			title: "Eliminar cursos",
			html: <EliminarCurso usuario={dataUsuario} />,
			showConfirmButton: false,
			showCloseButton: true,
		});
	};

	const handleCambiarLicencia = async (idPersona) => {
		const dataUsuario = datosUsuarios.filter((usuario) => usuario.idPersona === idPersona)[0];

		await ModalSweetAlert({
			title: "Cambiar Licencia",
			html: <CambioLicencia usuario={dataUsuario} />,
			showConfirmButton: false,
			showCloseButton: true,
		});
	};

	const handleExtensionVigencia = async (idPersona) => {
		const dataUsuario = datosUsuarios.filter((usuario) => usuario.idPersona === idPersona)[0];

		await ModalSweetAlert({
			title: "Extender vigencia",
			html: <ExtensionVigencia usuario={dataUsuario} setRefetchUsuarios={setRefetchUsuarios} />,
			showConfirmButton: false,
			showCloseButton: true,
		});
	};

	const handleCambioPassword = async (ids) => {
		const info = ids.split(",");

		let infoUsuario = [];

		switch (info[1]) {
			case "asesor":
				datosUsuarios.map((datoUsuario) => {
					if (datoUsuario.rol === info[1] && datoUsuario.idPersona === parseInt(info[0])) {
						infoUsuario = datoUsuario;
					}
				});
				break;

			case "estudiante":
				datosUsuarios.map((datoUsuario) => {
					if (datoUsuario.rol === info[1] && datoUsuario.idPersona === parseInt(info[0])) {
						infoUsuario = datoUsuario;
					}
				});
				break;

			case "empleado":
				datosUsuarios.map((datoUsuario) => {
					if (datoUsuario.rol === info[1] && datoUsuario.idPersona === parseInt(info[0])) {
						infoUsuario = datoUsuario;
					}
				});
				break;

			default:
				console.log("Default");
				break;
		}

		if (!infoUsuario.usuarioTitular) {
			return NotificationManager.error("Sólo para usuarios titulares");
		}

		ModalSweetAlert({
			title: "Asignar nueva contraseña",
			html: <CambioContraseña infoUsuario={infoUsuario} />,
			showConfirmButton: false,
			showCloseButton: true,
		});
	};

	const handleIngresarComo = async (idPersona) => {
		const dataUsuario = datosUsuarios.filter((usuario) => usuario.idPersona === idPersona)[0];

		setContextValue({
			...contextValue,
			sesionVerComo: true,
			dataAdministradorSesionVerComo: usuarioLogeado,
			usuarioLogeado: dataUsuario,
		});

		handleChangeComponent(multiViewComponentsPaths.home);
	};

	const handleCambiarNip = async (idPersona) => {
		const dataUsuario = datosUsuarios.filter((usuario) => usuario.idPersona === idPersona)[0];

		await ModalSweetAlert({
			title: "Cambiar NIP de seguridad",
			html: <CambioNIP idPerfil={dataUsuario.idPerfil} setRefetchUsuarios={setRefetchUsuarios} />,
			showConfirmButton: false,
			showCloseButton: true,
		});
	};

	const handleRemoverUsuario = async (idPersona) => {
		const dataUsuario = datosUsuarios.filter((usuario) => usuario.idPersona === idPersona)[0];

		await ModalSweetAlert({
			title: "Remover usuario",
			html: <RemoverUsuario usuario={dataUsuario} setRefetchUsuarios={setRefetchUsuarios} />,
			showConfirmButton: false,
			showCloseButton: true,
		});
	};

	const handleGestionCertificacionMos = async (idPersona) => {
		const dataUsuario = datosUsuarios.filter((usuario) => usuario.idPersona === idPersona)[0];

		if (!dataUsuario.derechoMos) {
			return NotificationManager.error("Este usuario no tiene derecho a Certificación");
		}

		const { folioContrato, idCredencial, idPerfil } = dataUsuario;

		const { cantidadCertificacionesMos, certificacionesAsignadas } = await getCertificacionesMosByIdCredencial({ folioContrato, idCredencial, idRegion: usuarioLogeado.idCodigoRegion });

		if (certificacionesAsignadas.length < 1) {
			return NotificationManager.error("El usuario no tiene ninguna Certificación MOS asignada");
		}

		const certificacionAsignada = certificacionesAsignadas.filter((certificacion) => certificacion.idPerfil === idPerfil);

		const certificacionExterna = certificacionesAsignadas.filter((certificacion) => certificacion.idPerfil === null);

		setDatosCertificacionAsignada(certificacionAsignada);

		setDatosCertificacionExternaAsignada(certificacionExterna);

		setDatosUsuarioCertMos({ ...dataUsuario, cantidadCertificacionesMos });

		handleOpen();
	};

	const columnas = useMemo(() => [
		{
			accessorFn: (row) => (`${row.codigoRegion}-${row.folioContrato}`),
			header: "Folio",
			size: 50,
		},
		{
			accessorKey: "nombreCompleto",
			header: "Nombre",
		},
		{
			accessorKey: "apellidos",
			header: "Apellidos",
		},
		{
			accessorKey: "rol",
			header: "Rol",
		},
		{
			accessorKey: "correo",
			header: "Correo",
		},
		{
			accessorKey: "curp",
			header: "CURP",
		},
		{
			accessorFn: (row) => (row.estatusCredencial === 1 ? "Activo" : "Ináctivo"),
			// accessorKey: "estatusCredencial",
			header: "Estado",
			// size: 50,
		},
	]);

	const toast = useRef(null);

	const menuRight = useRef(null);

	const [open, setOpen] = useState(false);

	const [searching, setSearching] = useState(false);

	const [datosUsuarioCertMos, setDatosUsuarioCertMos] = useState({});

	const [datosCertificacionAsignada, setDatosCertificacionAsignada] = useState(undefined);

	const [datosCertificacionExternaAsignada, setDatosCertificacionExternaAsignada] = useState(undefined);

	const [itemsMenuOpciones, setItemsMenuOpciones] = useState([]);

	const resetFormValues = () => {
		setFormValues({
			folioContrato: "",
			correo: "",
			nombreCompleto: "",
			apellidos: "",
		});
	};

	const [formValues, setFormValues] = useState({
		folioContrato: "",
		correo: "",
		nombreCompleto: "",
		apellidos: "",
	});

	const { folioContrato, correo, nombreCompleto, apellidos } = formValues;

	const handleInputChange = ({ target }) => {
		try {
			setFormValues({
				...formValues,
				[target.name]: target.value,
			});
		} catch (error) {
			console.log("error en input change", error);
		}
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleMenuSelection = (event) => {
		const { currentTarget } = event;

		const dataUsuario = datosUsuarios.filter((usuario) => usuario.idPersona === parseInt(currentTarget.id))[0];

		const { idPersona, rol, estatusCredencial } = dataUsuario;

		let menuItems = [
			{
				label: "Opciones",
				items: [
					{
						label: "Ingresar como",
						icon: "pi pi-users",
						command: () => handleIngresarComo(idPersona),
					},
					{
						label: "Ver Usuario",
						icon: "pi pi-eye",
						command: () => handleVerDatosUsuario(idPersona),
					},
					{
						label: "Editar Usuario",
						icon: "pi pi-user-edit",
						command: () => handleEditarDatosUsuario(idPersona),
					},
					{
						label: estatusCredencial ? "Desactivar Usuario" : "Activar Usuario",
						icon: `pi ${estatusCredencial ? "pi-ban" : "pi-check-circle"}`,
						command: () => handleEstatusCredencial({ estatusCredencial, idPersona }),
					},
					{
						label: "Remover Usuario",
						icon: "pi pi-trash",
						command: () => handleRemoverUsuario(idPersona),
					},

					{
						label: "Agregar curso",
						icon: "pi pi-plus",
						command: () => handleAgregarCurso(idPersona),
					},
					{
						label: "Eliminar curso",
						icon: "pi pi-minus",
						command: () => handleEliminarCurso(idPersona),
					},
					{
						label: "Cambiar Licencia",
						icon: "pi pi-book",
						command: () => handleCambiarLicencia(idPersona),
					},

					{
						label: "Extender Vigencia",
						icon: "pi pi-user-plus",
						command: () => handleExtensionVigencia(idPersona),
					},
					{
						label: "Cambiar Contraseña",
						icon: "pi pi-lock",
						command: () => handleCambioPassword(`${idPersona},${rol}`),
					},
					{
						...(rol === typesTiposUsuario.estudiante && {
							label: "Cambiar NIP",
							icon: "pi pi-key",
							command: () => handleCambiarNip(idPersona),
						}),
					},
					{
						label: "Gestión de Examen MOS",
						icon: "pi pi-briefcase",
						command: () => handleGestionCertificacionMos(idPersona),
					},
				],
			},
		];

		menuItems[0].items = menuItems[0].items.filter(esValido);

		setItemsMenuOpciones(menuItems);

		menuRight.current.toggle(event);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			setSearching(true);

			const usuarios = await buscarUsuarios(formValues);

			resetFormValues();

			setSearching(false);

			if (usuarios.length < 1) {
				return NotificationManager.error("No se encontraron usuarios con estos criterios de búsqueda");
			}

			setDatosUsuarios([...usuarios].sort((a, b) => parseInt(a.folioContrato) - parseInt(b.folioContrato)));
		} catch (error) {
			console.log("error al buscar los usuarios: ", error);

			setSearching(false);

			NotificationManager.error(error.message);
		}
	};

	useEffect(() => {
		if (refetchUsuarios) {
			consultarDatosUsuarios();
		}
	}, [refetchUsuarios]);

	useEffect(() => {
		if (busquedaActiva !== "") {
			let tab = "";

			switch (rolUsuario) {
				case "asesor":
					tab = "asesores";
					break;

				case "empleado":
					tab = "empleados";
					break;

				case "estudiante":
					tab = "estudiantes";
					break;

				default:
					break;
			}

			handleChangeTab(tab);
		}
	}, [busquedaActiva]);

	return (
		<Container fluid>
			<TitleDashboard title="Filtros" />

			<Form className="p-3 shadow info-cuenta-avances mt-3" onSubmit={handleSubmit}>
				<Row>
					<Col>
						<FloatingLabel label="Folio de Contrato" className="mb-3">
							<Form.Control type="text" placeholder="Folio de Contrato" onChange={handleInputChange} name="folioContrato" value={folioContrato} />
						</FloatingLabel>
					</Col>

					<Col>
						<FloatingLabel label="Correo electrónico" className="mb-3">
							<Form.Control type="email" placeholder="Correo electrónico" onChange={handleInputChange} name="correo" value={correo} />
						</FloatingLabel>
					</Col>
				</Row>

				<Row>
					<Col>
						<Row>
							<Col>
								<FloatingLabel label="Nombre(s)" className="mb-3">
									<Form.Control type="text" placeholder="Nombre(s)" onChange={handleInputChange} name="nombreCompleto" value={nombreCompleto} />
								</FloatingLabel>
							</Col>

							<Col>
								<FloatingLabel label="Apellido(s)" className="mb-3">
									<Form.Control type="text" placeholder="Apellido(s)" onChange={handleInputChange} name="apellidos" value={apellidos} />
								</FloatingLabel>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row>
					<Col className="d-flex align-self-end justify-content-start">
						<Alert severity="info">Los filtros son opcionales y puedes combinarlos para un mejor resultado.</Alert>
					</Col>

					<Col className="d-flex align-self-end justify-content-end">
						<div className="d-grid gap-2 mt-2">
							<Button variant="success" size="lg" type="submit">
								Buscar {searching ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faSearch} />}
							</Button>
						</div>
					</Col>
				</Row>
			</Form>

			{datosUsuarios.length > 0 ? (
				<Row>
					<Col>
						<MaterialReactTable
							localization={MRT_Localization_ES}
							columns={columnas}
							data={datosUsuarios}
							enableRowActions
							positionActionsColumn="last"
							renderRowActions={({ row, table }) => (
								<Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
									<IconButton color="primary" onClick={handleMenuSelection} id={row.original.idPersona}>
										<MenuIcon />
									</IconButton>
								</Box>
							)}
						/>
					</Col>
				</Row>
			) : null}

			<Dialog open={open} onClose={handleClose} maxWidth="md">
				<DialogTitle>
					<span>
						<Typography variant="h4" gutterBottom>
							Gestión de Certificación MOS
						</Typography>
					</span>
				</DialogTitle>

				<DialogContent>
					{datosCertificacionAsignada?.length > 0 ? datosCertificacionAsignada.map((certificacionAsignada, idx) => <GestionCertificacionMos key={idx} certificacion={certificacionAsignada} />) : null}

					{datosCertificacionExternaAsignada?.length > 0
						? datosCertificacionExternaAsignada.map((certificacionExternaAsignada, idx) => <GestionCertificacionMos key={idx} certificacion={certificacionExternaAsignada} isUsuarioExterno />)
						: null}

					<Alert variant="outlined" severity="warning">
						Cierra este cuadro de diálogo y selecciona nuevamente al usuario para ver los cambios reflejados.
					</Alert>
				</DialogContent>

				<DialogActions>
					<Button variant="secondary" onClick={handleClose}>
						Cancelar
					</Button>
				</DialogActions>
			</Dialog>

			<div className="card flex justify-content-center">
				<Toast ref={toast}></Toast>

				<Menu model={itemsMenuOpciones} popup ref={menuRight} />
			</div>
		</Container>
	);
};

export default TableBuscadorUsuarios;
