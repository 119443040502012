import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import PaquetesIncluidos from "./PaquetesIncluidos";
import TotalAPagar from "./TotalAPagar";
import AvisoProductoDigital from "./AvisoProductoDigital";
import FormCodigoDescuento from "./FormCodigoDescuento";
import UserContext from "@context/User/UserContext";
import PaquetesIncluidosListItem from "./lists/items/PaquetesIncluidosListItem";

const Body = ({ precios, compraExterna, compraLectura }) => {
	const { contextValue, setContextValue } = useContext(UserContext);

	const [codigoAplicado, setCodigoAplicado] = useState(false);

	const [codigoDescuento, setCodigoDescuento] = useState("");

	const [preciosXCodigo, setPreciosXCodigo] = useState(precios?.descuentoNoAplicado);

	useEffect(() => {
		if (codigoAplicado && precios?.descuentoAplicado) {
			setContextValue({
				...contextValue,
				preciosDescuento: preciosXCodigo,
			});
		}
	}, [preciosXCodigo]);

	return (
		<>
			<Row className="detalle-compra-pricing">
				{compraLectura ? (
					<Col>
					<h3>Incluye:</h3>
					<ul id="listDetallePremium" className="fa-ul">
						
						<PaquetesIncluidosListItem 
							title="Curso completo de lectura veloz"
						/>
						
						<PaquetesIncluidosListItem 
							title="Curso de regalo WordPress"
						/>
						
						<PaquetesIncluidosListItem 
							title="Curso de regalo Canva"
						/>
					</ul></Col>
				): (
					<PaquetesIncluidos compraLectura={compraLectura} codigoAplicado={codigoAplicado} />
				)}

				{compraLectura ? (
					<Col>
						<h3>Total a pagar:</h3>
						<ul>
							<li>De contado: $ {precios?.descuentoAplicado.lecturaVeloz.contado}.00 {compraExterna ? "USD" : "MXN"}</li>
							{!compraExterna ? (
								<li>Pago a meses sin intereses disponible</li>
							): null}
						</ul>
					</Col>
				): (
					<TotalAPagar 
						compraExterna={compraExterna}
						codigoAplicado={codigoAplicado} 
						codigoDescuento={codigoDescuento} 
						descuentoAplicado={precios?.descuentoAplicado} 
						descuentoNoAplicado={precios?.descuentoNoAplicado} 
					/>
				)}
			</Row>
			
			<Row className="detalle-compra-pricing pb-4 pb-lg-5">
				{!compraLectura ? (
					<AvisoProductoDigital />
				): null}
				{/* Se envian los precios con descuento para mostrarlos si tienen el codigo valido */}
				<FormCodigoDescuento 
					compraLectura={compraLectura}
					compraExterna={compraExterna}
					precios={precios?.descuentoAplicado} 
					states={
						{
							stateCodigoAplicado: {
								codigoAplicado,
								setCodigoAplicado,
							}, 
							stateCodigoDescuento: {
								codigoDescuento,
								setCodigoDescuento
							}, 
							statePreciosXCodigo	: {
								preciosXCodigo,
								setPreciosXCodigo,
							}
						}
					}
				/>
			</Row>
		</>
	);
};

export default Body;
