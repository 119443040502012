import ListadoCursos from "@components/general/Cursos/ListadoCursos";
import getURLCodigoQR from "@components/general/GenerarQR/getURLCodigoQR/getURLCodigoQR";
import TitleDashboard from "@components/general/Titles/TitleDashboard";
import UserContext from "@context/User/UserContext";
import useWindowResize from "@customHooks/useWindowResize";
import { faDownload, faPlus, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import getFechaSinHora from "@helpers/dates/getDateFinalizacionCurso/getFechaSinHora";
import getUnixTimestamp from "@helpers/dates/getUnixTimestamp/getUnixTimestamp";
import { getFechaFinalizacionCurso, guardarCertificado } from "@helpers/httpRequests/httpRequestsCertificadosEmitidos/httpRequestsCertificadosEmitidos";
import { getCursos } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import { addCurso, verificarCursoUsuario } from "@helpers/httpRequests/httpRequestsCursosUsuario/httpRequestsCursosUsuario";
import multiViewComponentsPaths from "@helpers/multiViewComponentsPaths/multiViewComponentsPaths";
import spanishOptionsJoyRide from "@helpers/spanishOptionsJoyRide/spanishOptionsJoyRide";
import confetti from "canvas-confetti";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { Animated } from "react-animated-css";
import { Accordion, Button, Card, Col, Container, Image, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Joyride from "react-joyride";
import QRCode from "qrcode";



import { Carousel as ReactCarousel } from "react-responsive-carousel";
import { NotificacionExtendida } from "@components/general/Alertas/Alertas";
import getFilledPdfBytes from "@components/general/GenerarQR/getFilledPdfBytes/getFilledPdfBytes";

const HomeLecturaVeloz = ({
  showScrollButton,
  handleChangeComponent,
  handleScrollToTop,
}) => {
  const { contextValue, setContextValue } = useContext(UserContext);

  const [cursos, setCursos] = useState([]);

  const { usuarioLogeado } = contextValue;

  const { nombreCompleto, idPerfil, correo, rol, idUsuarioEmpresarial, nombreUsuario, curp } = usuarioLogeado;

  const [runTour, setRunTour] = useState(false);


  const joyrideRef = useRef(null);

  const [dataImage, setDataImage] = useState({
    dataImage: "",
  });

  const [tourSteps, setTourSteps] = useState([

    {
      target: ".container-lv",
      content: "Te damos la bienvenida a Click+, completa tu curso de lectura veloz y no pierdas la oportunidad de ir más alla en tu aprendizaje al conocer los demás cursos que la plataforma tiene para tí.",
      disableBeacon: true,
    },


  ]);

  const [certificacionLectura, setCertificacionLectura] = useState(false);

  const {
    size: { width },
  } = useWindowResize();

  const slides = [
    {
      image: (
        <div className="image-wrapper m-auto">
          <picture>
            <source
              srcSet={`${require("@assets/images/recursos_mos/logo_excel.png")}`}
            />
            <Image
              className="imageMos mt-3"
              src={`${require("@assets/images/recursos_mos/logo_excel.png")}`}
              alt="Imagen de slide"
            />
          </picture>
        </div>
      ),
      title: <h2 className="my-2">Certificacación MOS</h2>,
      subtitle: (
        <h5 className="mx-5 px-5">
          Prepárate para tu Certificado Microsoft Office Specialist, te ayudará
          a mejorar tu análisis de datos, la toma de decisiones y optimización
          de procesos, además de destacar para mejores puestos y oportunidades
          laborales.
        </h5>
      ),
      control: (
        <Button
          onClick={() => {
            handleChangeComponent(
              multiViewComponentsPaths.solicitudLlamadaVenta
            );
          }}
          className="mt-2"
        >
          Haz clic aquí para tener acceso a la Certificación MOS
        </Button>
      ),
    },
    {
      image: (
        <picture>
          <source
            srcSet={`${require("@assets/images/recursos_mos/reseña_mos.webp")}`}
          />
          <source
            srcSet={`${require("@assets/images/recursos_mos/reseña_mos.png")}`}
          />
          <Image
            width="100%"
            height="30%"
            loading="lazy"
            className="comentario-mos"
            src={`${require("@assets/images/recursos_mos/reseña_mos.webp")}`}
            alt="Imagen de slide"
          />
        </picture>
      ),
    },
    {
      image: (
        <picture>
          <source
            srcSet={`${require("@assets/images/recursos_mos/reseña_mos2.webp")}`}
          />
          <source
            srcSet={`${require("@assets/images/recursos_mos/reseña_mos2.png")}`}
          />
          <Image
            width="100%"
            height="30%"
            loading="lazy"
            className="comentario-mos"
            src={`${require("@assets/images/recursos_mos/reseña_mos2.webp")}`}
            alt="Imagen de slide"
          />
        </picture>
      ),
    },
    {
      image: (
        <picture>
          <source
            srcSet={`${require("@assets/images/recursos_mos/reseña_mos3.webp")}`}
          />
          <source
            srcSet={`${require("@assets/images/recursos_mos/reseña_mos3.png")}`}
          />
          <Image
            width="100%"
            height="30%"
            loading="lazy"
            className="comentario-mos"
            src={`${require("@assets/images/recursos_mos/reseña_mos3.webp")}`}
            alt="Imagen de slide"
          />
        </picture>
      ),
    },
  ];

  const handleStartTour = async () => {
    console.log('Comienza el tour')
    setRunTour(true);
  };

  const handlePagar = () => {
    handleChangeComponent('solicitudLlamadaVenta');
  }


  const generarCertificado = async() =>{
    var end = Date.now() + 15 * 250;

    // go Buckeyes!
    var colors = ["#00152A", "#ff9900"];

    (function frame() {
      confetti({
        particleCount: 2,
        angle: 60,
        spread: 35,
        origin: { x: 0 },
        colors: colors,
      });
      confetti({
        particleCount: 2,
        angle: 120,
        spread: 35,
        origin: { x: 1 },
        colors: colors,
      });

      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    })();
    try {
      const infoCertificado = {
        rol: rol,
        idUsuarioEmpresarial: idUsuarioEmpresarial,
        idPerfil,
        codigoCurso: "LV23",
        nombre: nombreUsuario,
        curp,
        fecha: getUnixTimestamp(),
        nombreCurso: "Lectura Veloz",
      };

      const { data } = await guardarCertificado(infoCertificado);

      const { data: fechaFinalizacion } = await getFechaFinalizacionCurso({
        idPerfil,
        codigoCurso: "LV23",
      });

      let payloadCodigoQR = {
        fechaFinalizacion: getFechaSinHora(fechaFinalizacion.fecha),
        fechaUnix: fechaFinalizacion.fecha,
        nombre: data.info.data.nombre,
        nombreCurso: "Lectura Veloz",
        tiempoCurso: "14",
        idPerfil,
        codigoCurso: "LV23",
        curso: false,
      };

      //TODO Hacer debug para encontrar el porque no pasa correctamente la informacion para generar el qr

      const urlQR = getURLCodigoQR(payloadCodigoQR);

      const qrData = await QRCode.toDataURL(urlQR);

      payloadCodigoQR.dataQR = qrData;

      await createPdf(payloadCodigoQR);
    } catch (error) {
      console.log(error);
    }
  }

  const createPdf = async (dataCodigoQR) => {
    try {
      let url = `${window.location.origin}/assets/templates/template_certificado.pdf`;

      const data = await fetch(url);

      const pdfTemplateBytes = await data.arrayBuffer();

      const filledPdfBytes = await getFilledPdfBytes(
        pdfTemplateBytes,
        dataCodigoQR
      );

      saveByteArray(`Certificado - Lectura Veloz`, filledPdfBytes);
    } catch (error) {
      console.log(error);
    }
  };

  //Funcion para descargar el pdf sin la libreria
  const saveByteArray = (reportName, byte) => {
    var blob = new Blob([byte], { type: "application/pdf" });

    var link = document.createElement("a");

    link.href = window.URL.createObjectURL(blob);

    var fileName = reportName;

    setDataImage({
      ...dataImage,
      dataImage: link.href,
    });

    link.download = fileName;

    link.click();

    NotificacionExtendida("success", "Certificado generado con éxito");
  };

  useEffect(() => {


    //Se reciben los datos para manejarlos en la vista pública
    const param = new URLSearchParams(window.location.search);

    const datosURL = param.get("email");
    //Transformamos el string del data en arreglo
    console.log(datosURL);

    if (datosURL !== null) {
      //Guardar registro en certificados emitidos

      const guardarCursoLectura = async () => {
        try {
          const infoCurso = {
            rol: 'estudiante',
            codigoCurso: "LV23",
            idPerfil,
            idUltimaLeccionTomada: "LV230101",
            puntajeEvaluacion: 100,
          };

          await addCurso(infoCurso);

        } catch (error) {
          console.log(error);
        }
      }
      guardarCursoLectura()

    }


    const consultarCursos = async () => {
      try {
        let { data: cursos } = await getCursos();

        if (!Array.isArray(cursos)) {
          cursos = [cursos];
        }

        setCursos(cursos);
      } catch (error) {
        console.log("Error al consultar los cursos: ", error);
      }
    };

    consultarCursos();



  }, []);

  useEffect(() => {
    const consultarCertificacion = async () => {
      try {
        const infoCurso = {
          codigoCurso: 'LV23',
          idPerfil
        }
        await verificarCursoUsuario(infoCurso);

        setCertificacionLectura(true);
      } catch (error) {
        console.log(error);
      }
    }
    consultarCertificacion();
  }, [cursos])


  return (
    <Animated
      animationIn="fadeIn"
      animationInDuration={250}
      animationOut="fadeOut"
      isVisible={true}
    >
      <Container fluid className="p-0 container-lv">

        <Joyride
          locale={spanishOptionsJoyRide}
          ref={joyrideRef}
          steps={tourSteps}
          run={runTour}
          hideCloseButton
          showSkipButton
          continuous={true}
          disableOverlayClose={false}
          disableCloseOnEsc={false}
          disableScrolling={false}
          scrollToFirstStep={true}
          scrollOffset={100}
          callback={(data) => {
            if (data.status === "finished" || data.status === "skipped") {
              setRunTour(false);
            }
          }}
          styles={{
            options: {
              primaryColor: "#ff9900",
              textColor: "#00152A",
              width: 900,
              zIndex: 1000,
            },
          }}
        />

        <Row className="mt-4 mx-2 mt-4 headerLV">
          <Col className="text-center">
            <span style={{ fontSize: "1.8rem", color: '#fff' }}>
              ¡Hola {nombreCompleto}! Te damos la bienvenida a la plataforma
              &nbsp;
              <b style={{ fontSize: "2rem" }}>
                Click <span style={{ color: "rgb(245, 158, 11)" }}>+</span>
              </b>
            </span>
          </Col>
        </Row>

        <Row className="justify-content-end mt-4 mb-4">
          {width >= 1024 && (
            <Col className="col-md-2 mb-4 " style={{ height: "0px" }}>
              <OverlayTrigger placement={"top"} overlay={<Tooltip>Conoce tu entorno</Tooltip>}>
                <span>
                  <FontAwesomeIcon className="mx-5 pointer question" onClick={handleStartTour} style={{ fontSize: "2.2rem" }} icon={faQuestion} />
                </span>
              </OverlayTrigger>
            </Col>
          )}
        </Row>

        {/* <Row className="justify-content-center mb-4">
          <h2 class="text-center">Introducción</h2>
          <Col className="col-6">
            <video className="pt-4 h-100 w-100" controls>
              <source src="https://globalclickmexico.com/img/intro_lectura_veloz.mp4" type="video/mp4" />
            </video>
          </Col>
        </Row> */}


        {certificacionLectura ? (
          <Row className="mb-4 justify-content-center">

            <Col className="text-center col-6 shadow" style={{borderRadius: "15px", backgroundColor: "#e2e2e2"}}>
              <script src="https://cdn.lordicon.com/lordicon.js"></script>
              <lord-icon
                src="https://cdn.lordicon.com/fkmafinl.json"
                trigger="loop"
                delay="2000"
                style={{width: "100px", height: "100px", marginTop: "20px"}}>
              </lord-icon>
              <h3>Has completado el curso de lectura veloz, ya puedes descargar tu certificado</h3>
              <Button onClick={generarCertificado} className="btn btn-success mt-2 mb-5">Descargar <FontAwesomeIcon icon={faDownload}/></Button>
            </Col>
          </Row>
        ) : null}

        <Row className="mb-4">
          <Col>
            <Accordion defaultActiveKey="0" >
              <Accordion.Item eventKey="0">
                <Accordion.Header>Cursos disponibles</Accordion.Header>
                <Accordion.Body>


                  <Row className="justify-content-end">
                    <Col className="col-auto">
                      <Button onClick={handlePagar} className="btn btn-success btn-lg" style={{ fontSize: '1rem' }}>Obtener más cursos <FontAwesomeIcon icon={faPlus} size="lg" /></Button>
                    </Col>
                  </Row>
                  <ListadoCursos
                    handleChangeComponent={handleChangeComponent}
                    showScrollButton={showScrollButton}
                  />


                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>



        <Row className="justify-content-center mt-3 mb-5">
          <Col className="col-auto text-center">
            <h2>Conoce la Plataforma Click+ con este vídeo demostración</h2>
          </Col>
        </Row>

        <Row>
          <Col>
            {/* <div className="embed-responsive embed-responsive-16by9 mt-5 pt-1">
              <iframe
                title="Video Demostración"
                className="embed-responsive-item"
                src=""
                allowFullScreen
              ></iframe>
            </div> */}
            <video className="pt-4 h-100 w-100" controls>
              <source src="https://globalclickmexico.com/src/click+.mp4" type="video/mp4" />
            </video>
          </Col>

          <Col>
            <div className="contenedor-mos lectura-veloz">
              <div className="contenedor-carrusel">
                <ReactCarousel
                  interval={3500}
                  showArrows={false}
                  showStatus={false}
                  showThumbs={false}
                  swipeable={true}
                  emulateTouch={true}
                  infiniteLoop={true}
                  autoPlay={false}
                >
                  {slides.map((slide, key) => (
                    <div className="contenido-mos" key={key}>
                      {slide.image}

                      {slide.title}

                      {slide.subtitle}

                      {slide.control}
                    </div>
                  ))}
                </ReactCarousel>
              </div>
            </div>
          </Col>
        </Row>


        <h3 className="mt-5 text-center">
          ¡Descubre el mundo de posibilidades que se abre ante ti con
          nuestra Licencia Premium! <br />¿Estás listo para
          potenciar tu aprendizaje?
        </h3>


        <Accordion defaultActiveKey="0" className="mt-4" style={{ paddingBottom: '50px' }} >
          <Accordion.Item eventKey="0">
            <Accordion.Header><b>Da click aquí para explorar los cursos</b></Accordion.Header>
            <Accordion.Body>

              <h4 className="mt-5">Obtén acceso ilimitado a este catálogo de cursos:</h4>
              <Row>
                <Col>
                  <Container fluid className="pt-4">
                    {/* <TitleDashboard title="Obtén acceso ilimitado a este catálogo de cursos" /> */}



                    <Row>
                      {cursos.map(
                        (curso, idx) =>
                          curso.estatus === 1 && (
                            <Col
                              id={idx}
                              className="col-9 col-sm-6 col-md-4 col-lg-4 "
                            >
                              <Card className=" card-predefinidas invitado mb-4">
                                <picture width="100%" height="100%">
                                  <source
                                    srcSet={`${require(`@assets/images/portadas/${curso.portadaCurso}`)}`}
                                  />

                                  <img
                                    loading="lazy"
                                    className="img-fluid"
                                    src={`${require(`@assets/images/portadas/${curso.portadaCurso}`)}`} // Aquí va la URL de la imagen de fondo
                                    alt="Imagen de fondo"
                                  />
                                </picture>
                              </Card>
                            </Col>
                          )
                      )}
                    </Row>

                    <Row className="justify-content-center">
                      <Col className="col-auto">
                        <Button onClick={handlePagar} className="btn btn-primary btn-lg" style={{ fontSize: '2rem' }}>¡Quiero seguir aprendiendo!</Button>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>


            </Accordion.Body>
          </Accordion.Item>
        </Accordion>




        {/* <Row>
					<Col>
						<TitleDashboard title="Conoce la Plataforma Click+ con este vídeo demostración" />

						<div className="embed-responsive embed-responsive-16by9 mt-5 pt-1">
							<iframe title="Video Demostración" className="embed-responsive-item" src="https://globalclickmexico.com/img/video_demo/click_plus.mp4" allowFullScreen></iframe>
						</div>
					</Col>
				</Row> */}
      </Container>
    </Animated>
  );
};

export default HomeLecturaVeloz;
