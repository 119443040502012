import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getHerramientasSuite } from '@helpers/httpRequests/httpRequestsOpenAI/httpRequestsOpenAI'
import React, { useEffect, useState } from 'react'
import { Col, Row, Container, Table, Button } from 'react-bootstrap'

const DashboardSuiteIa = () => {

  //TODO: Refactorizar la forma en la que se listan las herramientas para los usuarios.
  //*Recuperar las herramientas desde la API para poder controlar el estatus y mostrar o no la herramienta.
  //*Refactorizar para poder en base al codigo de la herramienta seleccionar la vista correspondiente.

  const [herramientasSuite, setHerramientasSuite] = useState([]);

  const [herramientaDesactivada, setHerramientaDesactivada] = useState(false);
  
  const [herramientaReactivada, setHerramientaReactivada] = useState(false);

  useEffect(() => {
    const obtenerHerramientasSuite = async() => {
      try {
        const {data} = await getHerramientasSuite();
        const {data: herramientas} = data;
        console.log(herramientas);
        setHerramientasSuite(herramientas);
      } catch (error) {
        console.log(error);
      }
    }

    obtenerHerramientasSuite();
  }, [])
  

  return (
    <Container fluid className='mt-4'>
        <Row>
            <Col>
                <h2>Administrar herramienta de IA </h2>
            </Col>
        </Row>

        <Row className='mt-4'>
          <Col>
            <h4>Herramientas de IA disponibles:</h4>
          </Col>
          <Col className='col-12'>
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th>Código Herramienta</th>
                  <th>Descripción</th>
                  <th>Nombre</th>
                  <th>Estatus</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {herramientasSuite.map((herramienta, idx) => (
                   <tr key={idx} className='text-center'>
                      <td>{herramienta.codigoHerramienta}</td>
                      <td>{herramienta.descripcion}</td>
                      <td>{herramienta.nombre}</td>
                      <td>{herramienta.estatus ? <span className='text-success' style={{fontWeight: 'bold'}}>Activa</span>: <span className='text-danger' style={{fontWeight: 'bold'}}>Desactivada</span>}</td>
                      <td>
                        <Button className='btn btn-danger'><FontAwesomeIcon icon={faPowerOff} /></Button>
                      </td>
                    </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
    </Container>
  )
}

export default DashboardSuiteIa