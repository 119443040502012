import "@assets/css/components/DashboardSecciones/style.css";

import { Button, Col, Container, Row } from "react-bootstrap";
import React, { useState } from "react";

import { AgregarSeccion } from "./Modales/AgregarSeccion/AgregarSeccion";
import { ModalSweetAlert } from "@components/general/Alertas/Alertas";
import TableSecciones from "./DataTables/TableSecciones";
import ImportarSecciones from "./Modales/ImportarSecciones/ImportarSecciones";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPlus } from "@fortawesome/free-solid-svg-icons";

const DashboardSecciones = () => {
  const [seccionAgregada, setSeccionAgregada] = useState(false);

  const [seccionesImportadas, setSeccionesImportadas] = useState(false);

  const handleAgregarSeccion = async (e) => {
    try {
      await ModalSweetAlert({
        title: "Agregar nueva sección",
        width: 600,
        html: <AgregarSeccion setSeccionAgregada={setSeccionAgregada} />,
        showConfirmButton: false,
        showCloseButton: true,
      });
    } catch (error) {
      console.log("Error al agregar una nueva sección: ", error);
    }
  };

  const handleImportarSecciones = async () => {
    try {
      await ModalSweetAlert({
        title: "Importar secciones",
        width: 600,
        html: <ImportarSecciones setSeccionesImportadas={setSeccionesImportadas}/>,
        showConfirmButton: false,
        showCloseButton: true,
      });
    } catch (error) {
      console.log("Error al agregar una nueva sección: ", error);
    }
  };

  return (
    <Container fluid>
      <Row className="justify-content-end">
        <Col className="col-auto">
          <Button variant="primary" onClick={handleAgregarSeccion}>
            Agregar sección <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>

        <Col className="col-auto">
          <Button variant="primary" onClick={handleImportarSecciones}>
            Importar secciones <FontAwesomeIcon icon={faDownload} />
          </Button>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <TableSecciones
            seccionAgregada={seccionAgregada}
            setSeccionAgregada={setSeccionAgregada}
            seccionesImportadas={seccionesImportadas}
            setSeccionesImportadas={setSeccionesImportadas}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardSecciones;
