const httpRequestsPaths = {
	accesosAdministradores: {
		getAccesos: 'get-accesos',	
		insertarRegistroAdministradores:  'insertar-registro-administradores',
		editarRegistroAdministradores: 'editar-registro-administradores',
		eliminarRegistro: 'eliminar-registro-administradores',
	},
	agendaAsesorias: {
		getHistorialAsesorias: "get-historial-asesorias",
		addAgendaAsesoria: "add-agenda-asesoria",
		getUltimaAgenda: "get-ultima-agenda",
		getAgendasAsesorias: "get-agendas-asesorias",
		getDisponibilidadHorario: "get-disponibilidad-horario",
		updateAgendaAsesoriaFeedback: "update-agenda-asesoria-feedback",
		updateAgendaAsesoria: "update-agenda-asesoria",
		updateAgendaAsesoriaResuelta: 'update-agenda-asesoria-resuelta',
		updatePrivacidadAgendaAsesoria: 'update-agenda-asesoria-privacidad',
		cancelAgendaAsesoria: "cancel-agenda-asesoria",
		getAgendasAsesoriasPublicas: 'get-agendas-publicas',
		getAsesoriaById: 'get-asesoria-by-id',
		reenviarCorreo: 'reenviar-correo-feedback',
	},
	aparienciaPlataforma: {
		getTemas: "get-temas",
		selectTema: "select-tema",
		subirImagenTemaPersonalizado: "subir-tema-personalizado",
	},
	aulaVirtual: {
		addTransmission: "add-transmission",
		deleteTransmission: "delete-transmission",
		getCapacitadores: "get-capacitadores",
		getTransmissions: "get-transmissions",
		updateTransmission: "update-transmission",
	},
	bancoPreguntas: {
		addPregunta: "add-pregunta",
		deactivatePregunta: "deactivate-pregunta",
		getPreguntaById: "get-pregunta-by-id",
		getPreguntas: "get-preguntas",
		getPreguntasByCodigoCurso: "get-preguntas-by-codigo-curso",
		getPreguntasByCodigoLeccion: "get-preguntas-by-codigo-leccion",
		reactivatePregunta: "reactivate-pregunta",
		updatePregunta: "update-pregunta",
	},
	bancoPreguntasDiagnostico: {
		getPreguntas: "get-preguntas",
		getPreguntasByCodigo: "get-preguntas-by-codigo",
	},
	categoriasCursos: {
		getCategoriasById: "get-categorias-by-id",
		getCategoriasCursos: "get-categorias",
	},
	certificacionCursos: {
		getFechaFinalizacionCurso: "get-fecha-finalizacion-curso",
		guardarCertificado: "guardar-certificado",
		verificarCertificado: "verificar-certificado",
		getCertificadosByIdPerfil: "get-certificados-perfil"
	},
	certificacionMos: {
		descargables: {
			manualSimulador: "get-manual-simulador",
			materialApoyo: "get-material-apoyo",
			procesoExamen: "get-proceso-examen",
		},
		requisitos: {
			verificarCertificados: "verificar-certificados",
		},
		solicitud: {
			sendSolicitud: "send-solicitud",
		},
	},
	comentariosTemas: {
		deleteComentario: "delete-comentario",
	},
	consejosRapidos: {
		addConsejo: "add-consejo",
		deactivateConsejo: "deactivate-consejo",
		getConsejoById: "get-consejo-by-id",
		getConsejos: "get-consejos",
		getConsejosByCodigo: "get-consejos-by-codigo",
		reactivateConsejo: "reactivate-consejo",
		updateConsejo: "update-consejo",
	},
	cursoIngles: {
		lecciones: {
			getLeccionByCodigo: "get-leccion-by-codigo-ingles",
			getLeccionesByUnidad: "get-lecciones-by-unidad",
			getLeccionesCurso: "get-lecciones-curso",
		},
		unidades: {
			getUnidadesCurso: "get-unidades-curso",
		},
		updateInfo: {
			updateEmail: "update-email",
			updateUsuario: "update-usuario",
		},
	},
	cursos: {
		addCurso: "add-curso",
		deactivateCurso: "deactivate-curso",
		deleteCurso: "delete-curso",
		getCursoByCodigo: "get-curso-by-codigo",
		getCursos: "get-cursos",
		getCursosByIdCategoria: "get-cursos",
		getCursosPapelera: "get-cursos-papelera",
		getNombreCursos: "get-nombre-cursos",
		reactivateCurso: "reactivate-curso",
		updateCurso: "update-curso",
		verifyCodigoCurso: "verify-codigo-curso",
		verifyNombreCurso: "verify-nombre-curso",
	},
	cursoUsuario: {
		addCurso: "add-curso",
		getCalificacionCurso: "get-calificacion-curso",
		updateCurso: "update-curso",
		verificarCursoUsuario: "verificar-curso-usuario",
	},
	estadisticasPlataforma: {
		getCertificadosPorMes: "get-certificados-mes",
		getEstadisticas: "get-estadisticas",
		getPromediosPorMes: "get-promedios-mes",
		registrarUso: "registrar-uso",
		getHorasJuego: "get-horas-juego",
		getVisitasSala: "get-visitas-sala",
		obtenerEstadisticasMos: "get-estadisticas-mos",
		obtenerDetallesMos: "get-detalles-mos",
		obtenerUsuariosSuspendidos: "get-usuarios-suspendidos",
	},
	foroEmpresarial: {
		crudLecciones: {
			addSolicitud: "add-solicitud",
			sendMailNuevaLeccion: "send-mail-nueva-leccion",
			updateArchivoLeccionEmpresarial: "update-archivo-leccion-empresarial",
		},
		crudPublicaciones: {
			addPublicacion: "add-publicacion",
			deletePublicacion: "delete-publicacion",
			getPublicacionesByGrupo: "get-publicaciones-by-grupo",
			reactivatePublicacion: "reactivate-publicacion",
			updateArchivoPublicacion: "update-archivo-publicacion",
			updatePublicacion: "update-publicacion",
		},
		grupoEmpresarial: {
			eliminarUsuario: "eliminar-usuario",
			invitarUsuario: "invitar-usuario",
		},
		manejoArchivos: {
			addArchivoLeccionEmpresarial: "add-archivo-leccion-empresarial",
			addArchivoNuevaPublicacion: "add-archivo-nueva-publicacion",
			addSolicitudPublicacion: "add-solicitud-leccion",
			generarReporteEmpresarial: "generar-reporte-empresarial",
			downloadArchivoLeccion: "download-archivo-leccion",
			downloadArchivoPublicacion: "download-archivo-publicacion",
		},
		reportes: {
			calcularPromedioPorCursoGrupo: "get-promedio-cursos-grupo",
			getCertificadosEmitidosPorCursoGrupo: "get-certificados-emitidos-grupo",
			recuperarCertificacionesGrupo: "get-certificados-mes-grupo",
			getCertificacionesMosAprobadasGrupo: "get-certificaciones-mos-aprobadas",
		},
	},
	generar: {
		getQR: "getQR",
	},
	grupos: {
		deactivateGrupo: "deactivate-grupo",
		getGrupoByFolioContrato: "get-grupo-by-folio-contrato",
		getGrupoById: "get-grupo-by-id",
		getGrupos: "get-grupos",
		getUsuariosByIdGrupo: "get-usuarios-by-id-grupo",
		getUsuariosInvitados: "get-usuarios-invitados",
		reactivateGrupo: "reactivate-grupo",
		updateGrupo: "update-grupo",
	},
	imagenes: {
		uploadImage: "update-image",
	},
	lecciones: {
		addLeccion: "add-leccion",
		addUltimaLeccionTomada: "add-ultima-leccion-tomada",
		deactivateLecccion: "deactivate-leccion",
		deleteLeccion: "delete-leccion",
		getLeccionByCodigo: "get-leccion-by-codigo",
		getLeccionByCodigoCurso: "get-leccion-by-codigo-curso",
		getLecciones: "get-lecciones",
		getLeccionesByCodigoSeccion: "get-lecciones-by-codigo-seccion",
		getLeccionesPapelera: "get-lecciones-papelera",
		getUltimaLeccionTomada: "get-ultima-leccion-tomada",
		getUrlPlantilla: "get-url-plantilla",
		reactivateLeccion: "reactivate-leccion",
		updateLeccion: "update-leccion",
		updateReorderLecciones: "update-reorder-lecciones",
		manejoArchivos: {
			addNuevoArchivo: "add-nuevo-archivo-lecciones",
		}
	},
	leccionesPersonalizadas: {
		addLeccionPersonalizada: "add-leccion-personalizada",
		deactivateLeccionPersonalizada: "deactivate-leccion-personalizada",
		getLecciones: "get-lecciones",
		getLeccionesByGrupo: "get-lecciones-by-grupo",
		reactivateLeccionPersonalizada: "reactivate-leccion-personalizada",
		updateLeccionPersonalizada: "update-leccion-personalizada",
	},
	leccionesVistas: {
		addNuevaLeccion: "add-nueva-leccion",
		getLecciones: "get-lecciones",
		getLeccionesBI: "get-lecciones-bi",
		removeLeccion: "remove-leccion",
	},
	licencias: {
		addCursosLicencia: "add-cursos-licencia",
		addLicencia: "add-licencia",
		// checkTipoLicencia: "check-tipo-licencia",
		deactivateLicencia: "deactivate-licencia",
		getCursosLicencia: "get-cursos-licencia",
		getLicencia: "get-licencia",
		getLicencias: "get-licencias",
		getLicenciasInvitado: "get-licencias-invitado",
		getLicenciasNuevas: "get-licencias-nuevas",
		reactivateLicencia: "reactivate-licencia",
		updateLicencia: "update-licencia",
	},
	salaEntretenimiento: {
		validarCreditoUsuario: "validar-usuario",
		addUsuarioSinFicha: "add-usuario-sin-ficha",
		liberarFicha: "liberar-ficha",
		registrarVisita: "registrar-visita",
		registrarUsoJuegos: "registrar-uso-juegos",
	},
	likes: {
		addLike: "add-like",
		deleteLike: "delete-like",
		getLikes: "get-likes",
		getLikesPorTema: "get-likes-por-tema",
	},
	mailers: {
		accesoAdministradores: {
			intentoAcceso: 'send-alerta-intento-acceso',
			accesoConcedido: 'send-acceso-concedido',
		},
		enviarCorreoFacturacion: "send-solicitud-facturacion",
		estadosCuenta: {
			solicitudEstadoCuenta: 'solicitud-estado-cuenta',
			confirmacionSolicitudEstadoCuenta: 'confirmacion-solicitud-estado-cuenta',
		},
		pagos: {
			enviarComprobanteAbono: "send-comprobante-abono",
			enviarComprobanteCompra: "send-comprobante-compra",
			enviarComprobanteCompraCertificacionMOS: "send-comprobante-cert-mos",
			enviarComprobanteLiquidacion: "send-comprobante-liquidacion",
			enviarComprobanteRenovacion: "send-comprobante-renovacion",
		},
		registro: {
			segundoPerfil: "send-mail-registro-segundo-perfil",
		},
		solicitudCodigoVerificacionAgendaMos: "send-mail-codigo-verificacion-agenda-mos",
		solicitudFormularioCambioContrasenia: "send-mail-formulario-cambio-contrasenia",
		solicitudCodigoVerificacionCambioNombreUsuario: "send-mail-codigo-verificacion-cambio-nombre-usuario",
		solicitudLlamadaVenta: "send-mail-llamada-venta",
		solicitudRestablecimientoNip: "send-restablecimiento-nip",
		solicitudRecuperacionNombreUsuario: 'send-recuperacion-username',
		solicitudRestablecimientoNombreUsuario: 'send-restablecimiento-username',
	},
	misiones: {
		addMision: "add-mision",
		addParticipante: "add-participante",
		addRecompensa: "add-recompensa",
		canjearRecompensa: "canjear-recompensa",
		deleteMision: "delete-mision",
		deleteParticipante: "delete-participante",
		deleteRecompensa: "delete-recompensa",
		downloadTrabajoMision: "download-trabajo",
		getCreditos: "get-creditos",
		getGanadores: "get-ganadores",
		getGanadoresMes: "get-ganadores-mes",
		getGanadorMision: "get-ganador-mision",
		getMisiones: "get-misiones",
		getParticipaciones: "get-participaciones",
		getParticipacionesDelUsuario: "get-participaciones-usuario",
		getProyectosByPerfil: "get-proyectos-by-perfil",
		getRecompensas: "get-recompensas",
		getTrabajosPorMision: "get-trabajos-by-mision",
		getVictoriasParticipaciones: "get-victorias-participaciones",
		otorgarParticipaciones: "otorgar-participaciones",
		saveProyecto: "save-proyecto",
		sendProyectos: "get-proyectos",
		setGanador: "set-ganador",
		updateMision: "update-mision",
		updateRecompensa: "update-recompensa",
	},
	notificaciones: {
		addNotificacion: "add-notificacion",
		deactivateNotificacion: "deactivate-notificacion",
		getNotificacionById: "get-notificacion-by-id",
		getNotificaciones: "get-notificaciones",
		reactivateNotificacion: "reactivate-notificacion",
		updateNotificacion: "update-notificacion",
	},
	openAI: {
		consultarIntentos: "consultar-intentos",
		nuevasConsultas: "nuevas-consultas",
		sendGPTRequest: "send-gpt-request",
		sendTextToImageRequest: "text-to-image-request",
		sendTextToVoiceRequest: "text-to-audio-request",
		sinConsultas: "sin-consultas",
		updateConsultas: "update-consultas",
		getHerramientasSuite: 'get-herramientas-suite'
	},
	pagos: {
		compra: {
			getFolioContratoCompra: "get-folio-compra",
			getFolioContratoLecturaVeloz: "get-folio-lectura-veloz",
			getPricesCompra: "get-prices",
			getPricesCompraExterna: "get-prices-compra-externa",
			getPreciosCompraLinkUnico: "get-prices-compra-link",
			validateCodigoDescuento: "validate-code",
		},
		renovarLicencia: "renovar-licencia",
		createCheckoutSession: "create-checkout-session",
		getPriceAbono: "get-price-abono",
		getPriceCertificacionMosAdicional: "get-price-cert-mos-adicional",
		getPriceCertificacionMosAdicionalExterna: "get-price-cert-mos-adicional-externa",
		getPrecioLecturaVeloz: "get-precio-lectura-veloz",
		getPriceLiquidacion: "get-price-liquidacion",
		getPricesRenovacion: "get-prices-renovacion",
		retrieveCheckoutSession: "retrieve-session",
		retrieveCheckoutSessionByMetadata: "retrieve-session-folio",
		retrievePaymentIntent: "retrieve-payment-intent",
	},
	perfiles: {
		getImagenPerfil: "get-imagen-perfil",
	},
	plantillasTrabajo: {
		addPlantillaTrabajo: "add-plantilla-trabajo",
		deletePlantillaTrabajo: "delete-plantilla-trabajo",
		getPlantillasTrabajo: "get-plantillas-trabajo",
		updatePlantillaTrabajo: "update-plantilla-trabajo",
	},
	pixelFacebook: {
		sendViewContentEvent: "send-view-content-event",
		sendLeadEvent: "send-lead-event",
		sendInitiateCheckoutEvent: "send-initiate-checkout-event",
		sendPurchaseEvent: "send-purchase-event",
	},
	publicacionesGrupo: {
		addPublicacionGrupo: "add-publicacion-grupo",
		deletePublicacionGrupo: "delete-publicacion-grupo",
		getPublicacionesGrupo: "get-publicaciones-grupo",
		updatePublicacionGrupo: "update-publicacion-grupo",
	},
	recomendacionesDiagnostico: {
		addRecomendacion: "add-recomendacion",
		getRecomendaciones: "get-recomendaciones",
		getRecomendacionesByCodigo: "get-recomendaciones-by-codigo",
	},
	reportes: {
		addReporteComentario: "add-reporte-comentario",
		addReporteTema: "add-reporte-tema",
		getReporteComentarioByIdRespuesta: "get-reporte-comentario-by-id",
		getReportes: "get-reportes",
		getReportesByIdTema: "get-reportes-by-idTema",
		getReportesComentarios: "get-reportes-comentarios",
		getReportesComentariosByIdTema: "get-reportes-comentarios-by-idTema",
		updateEstadoReporteComentario: "update-estado-reporte-comentario",
		updateEstadoReporteTema: "update-estado-reporte-tema",
	},
	respuestasTema: {
		addRespuestaTema: "add-respuesta-tema",
		deleteRespuestaTema: "delete-respuesta-tema",
		getRespuestasTema: "get-respuestas-tema",
		getRespuestaTemaById: "get-respuesta-tema-by-id",
		getRespuestaTemaByIdTema: "get-respuestas-tema-by-id-tema",
		updateRespuestaTema: "update-respuesta-tema",
	},
	rutasAprendizaje: {
		addRutaAprendizaje: "add-ruta-aprendizaje",
		deactivateRutaAprendizaje: "deactivate-ruta-aprendizaje",
		getCursosRutas: "get-cursos-rutas",
		getRutasActivas: "get-rutas-aprendizaje-activas",
		getRutasAprendizaje: "get-rutas-aprendizaje",
		getRutasPorCurso: "get-rutas-por-curso",
		reactivateRutaAprendizaje: "reactivate-ruta-aprendizaje",
		updateRutaAprendizaje: "update-ruta-aprendizaje",
	},
	rutasAprendizajePersonalizadas: {
		addRutaAprendizaje: "add-ruta-aprendizaje",
		deleteRutaPersonalizada: "delete-ruta-aprendizaje",
		getRutasAprendizaje: "get-rutas-aprendizaje",
		updateRutaPersonalizada: "update-ruta-aprendizaje",
	},
	sai: {
		actualizarDatosFirma: "registrar-firma-sai",
		actualizarSegundoUsuario: "actualizar-segundo-usuario-sai",
		confirmarRegistroInicial: "confirmar-registro-sai",
		enviarDomiciliacionSai: "subir-domiciliacion",
		extraerRegionesSai: "extraer-regiones-sai",
		generarDomiciliacion: "generar-domiciliacion",
		getAsistentesCapacitacion: "get-asistentes-capacitacion",
		getDatosFolio: "get-datos-folio",
		getLinkContratoByFolio: "get-link-contrato",
		procesarDatosPreRegistro: "procesar-pre-registro",
		registrarNuevoPago: "registrar-nuevo-pago",
		registrarNuevoPagoCertificacionMos: "registrar-nuevo-pago-mos",
		registrarSegundoUsuario: "registrar-segundo-usuario-sai",
		subirFotosSai: "subir-fotos-sai",
		validarDatosPago: "validate-datos-pago",
		validarDatosPreRegistro: "validar-pre-registro",
		validateCorreoFolio: "validate-correo-folio",
		validateFolio: "validate-folio",
		validateTokenFolio: "validate-token-folio",
	},
	secciones: {
		addSeccion: "add-seccion",
		deactivateSeccion: "deactivate-seccion-by-codigo",
		deleteSeccion: "delete-seccion-by-codigo",
		getSeccionByCodigo: "get-seccion-by-codigo",
		getSecciones: "get-secciones",
		getSeccionesByCodigoCurso: "get-secciones-by-codigo-curso",
		getSeccionesPapelera: "get-secciones-papelera",
		reactivateSeccion: "reactivate-seccion-by-codigo",
		updateReorderSecciones: "update-reorder-secciones",
		updateSeccion: "update-seccion",
		manejoArchivos: {
			addNuevoArchivo: "add-nuevo-archivo-secciones",
		}
	},
	temas: {
		addTema: "add-tema",
		deactivateTema: "deactivate-tema",
		deleteTema: "delete-tema",
		getTemaById: "get-tema-by-id",
		getTemaByIdCategoria: "get-tema-by-id-categoria",
		getTemas: "get-temas",
		getTemasByUsuario: "get-temas-by-usuario",
		getTemasByUsuarioAndCategoria: "get-temas-by-usuario-and-categoria",
		getTemasComentados: "get-temas-comentados",
		reactivateTema: "reactivate-tema",
		updateTema: "update-tema",
	},
	tokens: {
		getToken: "get-token",
		validateTokenExpiry: "validate-token-expiry",
		verifyToken: "verify-token",
	},
	usuarios: {
		administrador: {
			addAdministrador: "add-administrador",
			getAdministradores: "get-administradores",
		},
		appMovil: {
			getUsuariosActivos: "get-usuarios-activos",
		},
		asesor: {
			getAsesorByGrupo: "asesor/get-asesor-by-grupo", // reactivateAsesor: 'asesor/reactivate-asesor',
			// deleteAsesor: 'asesor/delete-asesor',
			getAsesores: "asesor/get-asesores",
		},
		empleados: {
			getEmpleados: "empleado/get-empleados",
			getEmpleadosByGrupo: "empleado/get-empleados-by-grupo",
			updateColaborador: "empleado/update-colaborador", //Esta ruta es para actualizar los datos del empelado desde foro empresarial por el asesor.
			// deleteEmpleado: 'empleado/delete-empleado',
			// reactivateEmpleado: 'empleado/reactivate-empleado'
		},
		estudiantes: {
			getEstudiantes: "estudiante/get-estudiantes",
			getSegundoPerfilById: "estudiante/get-segundo-perfil-by-id",
			// es el idPerfil que pertenece al titular
			getSegundoPerfilByIdPerfilTitular: "estudiante/get-segundo-perfil-by-titular",
			// reactivateEstudiante: 'estudiante/reactivate-estudiante',
			// deleteEstudiante: 'estudiante/delete-estudiante',
			updateSegundoPerfil: "estudiante/update-segundo-perfil",
		},
		general: {
			renovarLicencia: "renovar-licencia",
			actualizarIntentoCertificacionMos: "update-intento-cert-mos",
			addCertificacionMos: "add-cert-mos",
			addExtensionTiempoVigencia: "add-tiempo-vigencia-extra",
			addNipPerfil: "add-nip-perfil",
			agendarExamenCertificacionMos: "agendar-fecha-examen-mos",
			agregarSegundoIntentoCertificacionMos: "add-segundo-intento-cert-mos",
			asignarCertificacionMos: "assign-cert-mos",
			asignarCertificacionMosEmpresarial: "assign-cert-mos-empleado",
			buscarUsuarios: "buscar-usuarios",
			deactivateUsuario: "deactivate-usuario",
			deleteNipPerfil: "delete-nip-perfil",
			getCertificacionesMosByFolio: "get-certificaciones-mos",
			getCertificacionesMosByIdCredencial: "get-certificaciones-mos",
			getDatosUsuario: "get-datos-usuario",
			getUsuarioByFolio: "get-usuario-by-folio",
			getDarkMode: "get-dark-mode",
			getNotificacionesUsuario: "get-notificaciones-usuario",
			getTiempoPlataforma: "get-tiempo-plataforma",
			getTokenRegistro: "get-token-registro",
			getUsuarioRecuperacionContrasenia: "get-usuario",
			getUsuarios: "get-usuarios",
      getTemasInteres: 'get-temas-interes',
			reactivateUsuario: "reactivate-usuario",
			recoverCredenciales: "recover-credenciales",
			registrarTokenRegistro: "registrar-token-registro",
			removerUsuario: "remover-usuario",
			setDarkMode: "set-dark-mode",
			updateContrasenia: "update-password",
			updateCursosUsuario: "update-cursos-usuario",
			updateFechaExpiracion: "update-fecha-expiracion",
			updateFechaRegistro: "update-fecha-registro",
			updateFechaUltimoAcceso: "update-fecha-ultimo-acceso",
			updateIntereses: "update-intereses",
			updateLicenciaUsuario: "update-licencia-usuario",
			updateNipPerfil: "update-nip-perfil",
			updateNombreUsuario: "update-nombre-usuario",
			updateNotificacionUsuario: "update-notificacion-usuario",
			updatePerfil: "update-perfil",
			updateStatusTour: "update-tour-status",
			updateTiempoPlataforma: "update-tiempo-plataforma",
			validarVigenciaUsuario: "validar-vigencia-usuario",
			validateCredenciales: "validate-credenciales",
			validateExistenciaAlias: "validate-existencia-alias",
			validateExistenciaCorreo: "validate-existencia-correo",
			validateExistenciaCURP: "validate-existencia-curp",
			validateExistenciaFolioContrato: "validate-existencia-folio",
			validateExistenciaNombreUsuario: "validate-existencia-nombre-usuario",
			validateNipPerfil: "validate-nip-perfil",
		},
		invitados: {
			generarCodigosInvitado: "generar-codigos-invitado",
			getCodigosInvitado: "get-codigos-invitado",
			getInvitados: "get-usuarios-invitados",
			validateCodigoInvitado: "validate-codigo-invitado",
		},
		lecturaVeloz: {
			getUsuariosLecturaVeloz: "get-usuarios-lectura-veloz",
		},
		registro: {
			addEmpleado: "add-empleado",
			// debe haber un asesor registrado
			addEmpleadoMasivo: "add-empleado-masivo",
			// puede ser asesor o estudiante
			addSegundoPerfilEstudiante: "add-segundo-perfil-estudiante",
			addUsuarioInvitado: "add-usuario-invitado",
			addUsuarioLecturaVeloz: "add-usuario-lectura-veloz",
			addUsuarioTitular: "add-usuario-titular",
		},
	},
	usuariosMoodle: {
		getAvancesPerfil: "get-avances-perfil",
		getCertificacionesPerfil: "get-certificaciones-perfil",
		getFechaRegistro: "get-fecha-registro",
		getPuntajesPerfil: "get-puntajes-perfil",
	},
	usuariosRanking: {
		getRanking: "get-ranking",
	},
	validaciones: {
		validateCurp: "validate-curp",
		validateDisponibilidadCorreo: "validate-disponibilidad-correo",
		validateDisponibilidadNombreUsuario: "validate-nombre-usuario",
	},
};

export default httpRequestsPaths;
