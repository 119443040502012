import "@assets/css/components/DashboardLecciones/style.css";

import { Button, Col, Container, Row } from "react-bootstrap";
import React, { useState } from "react";

import { AgregarLeccion } from "./Modales/AgregarLeccion/AgregarLeccion";
import { ModalSweetAlert } from "@components/general/Alertas/Alertas";
import { TableLecciones } from "@components/administrador/DashboardLecciones/DataTables/TableLecciones";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPlus } from "@fortawesome/free-solid-svg-icons";
import ImportarLecciones from "./Modales/ImportarLecciones/ImportarLecciones";

export const DashboardLecciones = () => {

  const [leccionAgregada, setLeccionAgregada] = useState(false);

  const [leccionesImportadas, setLeccionesImportadas] = useState(false);

  const handleAgregarLeccion = async () => {
    try {
      await ModalSweetAlert({
        title: "Agregar nueva lección",
        html: <AgregarLeccion setLeccionAgregada={setLeccionAgregada} />,
        showConfirmButton: false,
        showCloseButton: true,
      });
    } catch (error) {
      console.log("Error al agregar una nueva lección: ", error);
    }
  };

  const handleImportarLecciones = async () => {
    try {
      await ModalSweetAlert({
        title: "Importar lecciones",
        html: <ImportarLecciones setLeccionesImportadas={setLeccionesImportadas} />,
        showConfirmButton: false,
        showCloseButton: true,
      });
    } catch (error) {
      console.log("Error al agregar una nueva lección: ", error);
    }
  };


  return (
    <Container fluid>
      <Row className="justify-content-end">
        <Col className="col-auto">
          <Button variant="primary" onClick={handleAgregarLeccion}>
            Agregar lección <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>

        <Col className="col-auto">
          <Button variant="primary" onClick={handleImportarLecciones}>
            Importar lecciones <FontAwesomeIcon icon={faDownload} />
          </Button>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <TableLecciones
            leccionAgregada={leccionAgregada}
            setLeccionAgregada={setLeccionAgregada}
            leccionesImportadas={leccionesImportadas}
            setLeccionesImportadas={setLeccionesImportadas}
          />
        </Col>
      </Row>
    </Container>
  );
};
