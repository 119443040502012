import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const verificarCertificado = (infoCertificado) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpRequest({
        url: `${endpointsPaths.certificacionCursos}/${httpRequestsPaths.certificacionCursos.verificarCertificado}`,
        method: "POST",
        body: JSON.stringify(infoCertificado),
      });

      resolve(result);
    } catch (error) {
      // console.log("Error a obtener todas las categorias: ", error);

      reject(error);
    }
  });
};

export const guardarCertificado = async (infoCertificado) => {
  return new Promise(async (resolve, reject) => {
    try {
      const saveCertificado = await httpRequest({
        url: `${endpointsPaths.certificacionCursos}/${httpRequestsPaths.certificacionCursos.guardarCertificado}`,
        method: "POST",
        body: JSON.stringify(infoCertificado),
      });

      resolve(saveCertificado);
    } catch (error) {
      // console.log("Error al registrar el certificado del curso: ", error);

      reject(error);
    }
  });
};

export const getFechaFinalizacionCurso = async (datosCertificado) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: fechaFinalizacionCurso } = await httpRequest({
        url: `${endpointsPaths.certificacionCursos}/${httpRequestsPaths.certificacionCursos.getFechaFinalizacionCurso}`,
        method: "POST",
        body: JSON.stringify(datosCertificado),
      });

      resolve(fechaFinalizacionCurso);
    } catch (error) {
      // console.log("Error al obtener la fecha de finalizacion de curso: ", error);

      reject(error);
    }
  });
};


export const getCertificacionesByIdPerfil = async (idPerfil) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: certificados } = await httpRequest({
        url: `${endpointsPaths.certificacionCursos}/${httpRequestsPaths.certificacionCursos.getCertificadosByIdPerfil}/${idPerfil}`,
        method: "POST",
      });

      resolve(certificados);
    } catch (error) {
      // console.log("Error al obtener la fecha de finalizacion de curso: ", error);

      reject(error);
    }
  });
};
