import UserContext from "@context/User/UserContext";
import { faSpinner, faUserGraduate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { asignarCertificacionMosEmpresarial, getCertificacionesMosByFolio } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, TableContainer, Typography } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Card, Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import { FaCheck, FaPlus, FaTimes } from "react-icons/fa";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import getDateFromUnix from "@helpers/dates/getDateFromUnix/getDateFromUnix";
import { ModalSweetAlert } from "@components/general/Alertas/Alertas";
import { createCheckoutSession, getPriceCertificacionMosAdicional } from "@helpers/httpRequests/httpRequestsPagos/httpRequestsPagos";
import dashboardRoutesPaths from "@helpers/dashboardRoutesPaths/dashboardRoutesPaths";
import Swal from "sweetalert2";
import stripe from "@helpers/stripe/stripe";
import { getUsuariosByIdGrupo, getUsuariosInvitados } from "@helpers/httpRequests/httpRequestsGrupos/httpRequestsGrupos";

const CircularProgressWithLabel = (props) => {
	return (
		<Box sx={{ position: "relative", display: "inline-flex" }}>
			<CircularProgress variant="determinate" {...props} />

			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: "absolute",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}>
				<Typography variant="overline" component="div" color="text.secondary">
					{`${Math.round(props.value)}%`}
				</Typography>
			</Box>
		</Box>
	);
};

const CertificacionesMos = ({ isTabActive }) => {
	const { contextValue, setContextValue } = useContext(UserContext);

	const { usuarioLogeado } = contextValue;

	const { folioContrato, idGrupo, idCodigoRegion } = usuarioLogeado;

	const columnas = useMemo(() => [
		{
			id: "nombreCompleto",
			header: "Nombre",
			accessorFn: (row) => `${row.nombreCompleto} ${row.apellidos}`,
		},
		{
			id: "cantidadIntentos",
			header: "Cantidad de intentos registrados",
			accessorFn: (row) => row.intentos.length,
		}
	]);

	const [certificacionesAsignadas, setCertificacionesAsignadas] = useState([]);

	const [cantidadCertificacionesMos, setCantidadCertificacionesMos] = useState([]);

	const [colaboradores, setColaboradores] = useState([]);

	const [progresoUsoCertificaciones, setProgresoUsoCertificaciones] = useState(0);

	const [cantidadExamenesRealizados, setCantidadExamenesRealizados] = useState(0);

	const [cantidadCertificacionesAprobadas, setCantidadCertificacionesAprobadas] = useState(0);

	const [refetchData, setRefetchData] = useState(false);

	const [showLoader, setShowLoader] = useState(false);

	const [formValues, setFormValues] = useState({
		folioContrato,
		idPerfil: null,
		nombreCompleto: "",
		apellidos: "",
		tipoUsuario: 0,
		correoTitular: usuarioLogeado.correo,
		correoPersonaCertificacion: "",
		nombreCompletoTitular: `${usuarioLogeado.nombreCompleto} ${usuarioLogeado.apellidos}`,
		nombreTitular: usuarioLogeado.nombreCompleto,
		derechoMos: false,
	});

	const formButtonRef = useRef(null);

	const consultarCertificaciones = async () => {
		try {
			let { cantidadCertificacionesMos: cantidadCertificacionesMosAux, certificacionesAsignadas: certificacionesAsignadasAux } = await getCertificacionesMosByFolio(folioContrato, idCodigoRegion);

			if (!Array.isArray(certificacionesAsignadasAux)) {
				certificacionesAsignadasAux = [certificacionesAsignadasAux];
			}

			const porcentajeCubierto = Math.floor((certificacionesAsignadasAux.length * 100) / cantidadCertificacionesMosAux);

			let examenesRealizados = 0;

			let examenesAprobados = 0;

			certificacionesAsignadasAux.forEach(({ intentos }) => {
				intentos.forEach(({ examenRealizado, aprobado }) => {
					if (examenRealizado) {
						examenesRealizados++;
					}

					if (aprobado) {
						examenesAprobados++;
					}
				});
			});

			setCertificacionesAsignadas(certificacionesAsignadasAux);

			setCantidadCertificacionesMos(cantidadCertificacionesMosAux);

			setProgresoUsoCertificaciones(porcentajeCubierto);

			setCantidadCertificacionesAprobadas(examenesAprobados);

			setCantidadExamenesRealizados(examenesRealizados);
		} catch (error) {
			console.log("Error al consultar las certificaciones MOS asignadas: ", error);
		}
	};

	const obtenerColaboradores = async () => {
        let auxUsuarios = [];

		try {
			let { data: empleados } = await getUsuariosByIdGrupo(idGrupo);

			if (!Array.isArray(empleados)) {
				empleados = [empleados];
			}

            auxUsuarios = [
                ...auxUsuarios,
                ...empleados
            ];
		} catch (error) {
            console.log("Error al obtener los colaboradores: ", error);
		}

        try {
            let { data: usuariosInvitadosGrupo } = await getUsuariosInvitados(idGrupo);

			if (!Array.isArray(usuariosInvitadosGrupo)) {
				usuariosInvitadosGrupo = [usuariosInvitadosGrupo];
			}

            auxUsuarios = [
                ...auxUsuarios,
                ...usuariosInvitadosGrupo
            ];
        } catch (error) {
            // console.log("Error al obtener los usuarios invitados: ", error);
        }
        
        setColaboradores(auxUsuarios);
	};

	const table = useMaterialReactTable({
		localization: MRT_Localization_ES,
		columns: columnas,
		data: certificacionesAsignadas,
		renderDetailPanel: ({ row }) => {
			const intentosCertificacion = row.original.intentos;

			return (
				<Container fluid>
					<Row>
						<Col>
							<TableContainer component={Paper}>
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell className="text-center fw-bold text-primary">Número de intento</TableCell>

											<TableCell className="text-center fw-bold text-primary">Fecha de Solicitud</TableCell>

											<TableCell className="text-center fw-bold text-primary">Fecha de Aplicación</TableCell>

											<TableCell className="text-center fw-bold text-primary">Examen realizado</TableCell>

											<TableCell className="text-center fw-bold text-primary">Examen aprobado</TableCell>
										</TableRow>
									</TableHead>

									<TableBody>
										{intentosCertificacion.map(({ numeroIntento, fechaSolicitud, fechaAgenda, examenRealizado, aprobado }, idx) => (
											<TableRow key={idx} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
												<TableCell component="th" className="text-center">
													# {numeroIntento}
												</TableCell>

												<TableCell component="th" className="text-center">
													{fechaSolicitud === null ? "No se ha solicitado la agenda" : getDateFromUnix(fechaSolicitud)}
												</TableCell>

												<TableCell component="th" className="text-center">
													{fechaAgenda === null ? "No se ha solicitado la agenda" : getDateFromUnix(fechaAgenda)}
												</TableCell>

												<TableCell component="th" className="text-center">
													{examenRealizado === 1 ? <FaCheck className="text-success" /> : <FaTimes className="text-danger" />}
												</TableCell>

												<TableCell component="th" className="text-center">
													{aprobado === 1 ? <FaCheck className="text-success" /> : <FaTimes className="text-danger" />}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Col>
					</Row>
				</Container>
			);
		},
	});

	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		if (certificacionesAsignadas.length === cantidadCertificacionesMos) {
			return ModalSweetAlert({
				icon: "error",
				text: "Has alcanzado el límite de certificaciones adquiridas, para agregar otra persona, adquiere otra Certificación MOS",
			});
		}

		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleAgregarCertificacion = async (e) => {
		e.preventDefault();

		try {
			setShowLoader(true);

			await asignarCertificacionMosEmpresarial(formValues, idCodigoRegion);

			setRefetchData(true);

			setShowLoader(false);

			setOpen(false);
		} catch (error) {
			console.log("Error al asignar la certificacion: ", error);
		}
	};

	const handleSeleccionUsuario = async ({ target }) => {
		const { value } = target;

		if (value === "") return;

        const datosPersona = colaboradores.find(colaborador => colaborador.idPerfil === parseInt(value));
        
        setFormValues({
            ...formValues,
            ...datosPersona,
        });
	};

	const handleComprarCertificacion = async () => {
		try {
			const { idPrecio } = await getPriceCertificacionMosAdicional();

			const sessionData = {
				success_url: `${window.location.origin}${dashboardRoutesPaths.pagos.compra.compraCertificacionMosRealizada}`,
				cancel_url: `${window.location.origin}${dashboardRoutesPaths.dashboard}`,
				line_items: [{ price: idPrecio, quantity: 1 }],
				mode: "payment",
				currency: "mxn",
				metadata: {
					folioContrato,
					pagoCertificacionMos: true,
				},
			};

			const checkoutSession = await createCheckoutSession(sessionData);

			const { isConfirmed } = await Swal.fire({
				title: "Saliendo de Click+",
				text: "Estás a punto de ser redirigido al sitio de pago",
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: "Entendido",
				cancelButtonText: "Cancelar",
				confirmButtonColor: "#0d6efd",
				allowOutsideClick: false,
			});

			if (!isConfirmed) {
				return;
			}

			setContextValue({
				...contextValue,
				stripeSession: checkoutSession,
			});

			await (await stripe).redirectToCheckout({ sessionId: checkoutSession.id });
		} catch (error) {
			console.log("error al generar pago: ", error);
		}
	};

	useEffect(() => {
		if (certificacionesAsignadas.length === 0) {
			consultarCertificaciones();
		}

        if (colaboradores.length === 0) {
            obtenerColaboradores();
        }
	}, []);

	// useEffect(() => {
    //     console.log(colaboradores);
    // }, [colaboradores]);

	useEffect(() => {
		if (!open) {
			setFormValues({
				...formValues,
				nombreCompleto: "",
				apellidos: "",
				correoPersonaCertificacion: "",
			});
		}
	}, [open]);

	useEffect(() => {
		if (refetchData) {
			consultarCertificaciones();

			setRefetchData(false);
		}
	}, [refetchData]);

	return (
		isTabActive && 
		<>
			<Container fluid className="mt-3 mb-5">
				<Row>
					<Col>
						{/* <Card body className="shadow mt-4"> */}
						<Typography variant="h4" gutterBottom>
							Datos de Certificaciones MOS
						</Typography>

						<Container fluid>
							<Row className="align-items-center">
								<Col>
									<Card body className="shadow w-100">
										<Typography variant="h5" gutterBottom className="text-center">
											Certificaciones adquiridas
										</Typography>

										<div className="d-flex justify-content-center">
											<div className="d-flex align-items-center progreso-certificaciones-mos">
												<CircularProgressWithLabel value={progresoUsoCertificaciones} size={150} />

												<div className="d-flex flex-column ms-3">
													<Typography variant="subtitle1" gutterBottom>
														{certificacionesAsignadas.length} usadas /
													</Typography>

													<Typography variant="subtitle2" gutterBottom>
														de {cantidadCertificacionesMos} adquiridas
													</Typography>

													<Button variant="success" className="mt-1" onClick={handleComprarCertificacion}>
														Comprar más <FaPlus />
													</Button>
												</div>
											</div>
										</div>
									</Card>
								</Col>

								<Col>
									<Card body className="shadow w-100 text-center">
										<Typography variant="h5" gutterBottom>
											Exámenes realizados
										</Typography>

										<Typography variant="subtitle1" gutterBottom className="text-center">
											{cantidadExamenesRealizados} realizados
										</Typography>
										<div className="d-flex align-items-center progreso-certificaciones-mos"></div>
									</Card>
								</Col>

								<Col>
									<Card body className="shadow w-100 text-center">
										<Typography variant="h5" gutterBottom>
											Certificaciones aprobadas
										</Typography>

										<Typography variant="subtitle2" gutterBottom className="text-center">
											{cantidadCertificacionesAprobadas} aprobadas
										</Typography>
										<div className="d-flex align-items-center progreso-certificaciones-mos"></div>
									</Card>
								</Col>
							</Row>
						</Container>
						{/* </Card> */}
					</Col>
				</Row>

				<Row className="mt-5 mb-3">
					<Col xs="auto">
						<Typography variant="h4" gutterBottom>
							Certificaciones asignadas
						</Typography>
					</Col>

					<Col xs="3">
						{certificacionesAsignadas.length !== cantidadCertificacionesMos ? (
							<Button variant="primary" onClick={handleOpen}>
								Asignar Certificación <FontAwesomeIcon icon={faUserGraduate} />{" "}
							</Button>
						) : null}
					</Col>
				</Row>

				<Row>
					<Col>
						<MaterialReactTable table={table} />
					</Col>
				</Row>
			</Container>

			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>Asignar Certificación MOS</DialogTitle>

				<DialogContent>
					<DialogContentText>Para que la persona que elegiste pueda tener acceso a su proceso de Certificación MOS, deberás darla de alta en este formulario.</DialogContentText>

					<Form onSubmit={handleAgregarCertificacion} className="mt-3">
						<Form.Group className="mb-3">
							<FloatingLabel label="Elige quién se va a certificar">
								<Form.Select onChange={handleSeleccionUsuario}>
									<option value="">Elige una opción</option>

									{
                                        colaboradores.length > 0 &&
                                        colaboradores.map((colaborador, idx) => 
                                            <option key={idx} value={colaborador.idPerfil}>
                                                { `${ colaborador.nombreCompleto } ${ colaborador.apellidos }` } 
                                            </option>
                                        )
                                    }
								</Form.Select>
							</FloatingLabel>
						</Form.Group>

						<Button hidden type="submit" ref={formButtonRef} />
					</Form>
				</DialogContent>

				<DialogActions>
					<Button variant="success" onClick={() => formButtonRef?.current.click()}>
						Asignar certificación {showLoader ? <FontAwesomeIcon icon={faSpinner} spin /> : null}
					</Button>

					<Button variant="secondary" onClick={handleClose}>
						Cancelar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default CertificacionesMos;
