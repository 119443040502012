import { BsCurrencyDollar, BsJournalText, BsLightbulb } from "react-icons/bs";
import { FaBriefcase, FaChalkboardTeacher, FaChartLine, FaGamepad, FaRoute, FaTrophy } from "react-icons/fa";
import { IoIosHome, IoMdHelpBuoy } from "react-icons/io";

import { ImBooks } from "react-icons/im";
import { MdLaptop, MdOutlineAdminPanelSettings, MdShoppingCart } from "react-icons/md";
import { IoIosBookmarks } from "react-icons/io";
import { GiProcessor } from "react-icons/gi";

import React from "react";
import multiViewComponentsPaths from "@helpers/multiViewComponentsPaths/multiViewComponentsPaths";
import typesTiposUsuario from "@helpers/types/typesTiposUsuario";
import { Badge } from "primereact/badge";

const SidebarItems = [
	{
		title: "Inicio",
		descripcion: "Regresa al menú principal" ,
		id: "",
		path: multiViewComponentsPaths.home,
		icon: <IoIosHome />,
		classNames: "home-icon",
		permittedTo: [...typesTiposUsuario.todosUsuarios],
	},
	{
		title: "Inicio",
		descripcion: "Regresa al menú principal" ,
		id: "",
		path: multiViewComponentsPaths.homeInvitado,
		icon: <IoIosHome />,
		classNames: "home-icon",
		permittedTo: [typesTiposUsuario.invitado, typesTiposUsuario.cortesia],
	},
	{
		title: "Inicio",
		descripcion: "Regresa al menú principal" ,
		id: "",
		path: multiViewComponentsPaths.homeLecturaVeloz,
		icon: <IoIosHome />,
		classNames: "home-icon",
		permittedTo: typesTiposUsuario.lecturaVeloz,
	},
	{
		title: "Misiones Click+",
		descripcion: "Completa los retos, gana créditos y canjealos por increíbles premios" ,
		id: "",
		path: multiViewComponentsPaths.misiones,
		icon: <FaTrophy />,
		classNames: "nav-text",
		permittedTo: typesTiposUsuario.todosUsuarios,
	},
	{
		title: "Sala de entretenimiento",
		descripcion: "Entra a la sala de entretenimiento y relájate un momento antes de continuar con tu aprendizaje" ,
		id: "",
		path: multiViewComponentsPaths.salaEntretenimiento,
		icon: <FaGamepad />,
		classNames: "nav-text",
		permittedTo: typesTiposUsuario.todosUsuarios,
	},
	{
		title: "Rutas de Aprendizaje",
		descripcion: "Crea listas de reproducción personalizadas con temas de nuestro catálogo de cursos que sea de tu interés" ,
		id: "",
		path: multiViewComponentsPaths.rutasAprendizaje,
		icon: <FaRoute />,
		classNames: "nav-text",
		permittedTo: typesTiposUsuario.todosUsuarios,
	},
	{
		title: "Consejos Rápidos",
		descripcion: "Aumenta tu productividad con la lista de atajos de teclados que tenemos para consultar, en ella encontrarás atajos para toda la paquetería office y cursos con los que cuenta la plataforma" ,
		id: "",
		path: multiViewComponentsPaths.consejosRapidos,
		icon: <BsLightbulb />,
		classNames: "nav-text",
		permittedTo: typesTiposUsuario.todosUsuarios,
	},
	{
		// title: 'Foro de Ayuda: Notificaciones nuevas',
		title: (notificacionDisponible) => (notificacionDisponible ? "Foro de Ayuda: Notificaciones nuevas" : "Foro de Ayuda"),
		descripcion: "Interactúa con la comunidad Click+ y comparte temas, opiniones o resuelve dudas que surjan durante tu aprendizaje con apoyo de otros estudiantes" ,
		id: "",
		path: multiViewComponentsPaths.foroAyuda,
		// icon: <TiMessages />,
		icon: (notificacionDisponible) => (
			<i className="pi pi-comments p-overlay-badge" style={{ fontSize: "1rem" }}>
				{notificacionDisponible && <Badge className="notificacion-foroayuda" severity="danger"></Badge>}
			</i>
		),
		className: "nav-text",
		permittedTo: typesTiposUsuario.todosUsuarios,
	},
	{
		title: "Mis Cursos",
		descripcion: "Disfruta del catálogo de cursos que tenemos para ti. ¡Complétalos todos!" ,
		id: "sidebar-item-cursos",
		path: multiViewComponentsPaths.misCursos,
		icon: <ImBooks />,
		classNames: "nav-text",
		permittedTo: [...typesTiposUsuario.todosUsuarios, typesTiposUsuario.invitado, typesTiposUsuario.lecturaVeloz, typesTiposUsuario.cortesia],
	},
	{
		title: "Agendar Asesoría",
		descripcion: "¿Necesitas ayuda en temas de paquetería Office? Agenda una asesoría con uno de nuestros especialistas y con gusto te apoyaremos" ,
		id: "sidebar-item-agendas",
		path: multiViewComponentsPaths.agendasAsesorias,
		icon: <IoIosBookmarks />,
		classNames: "nav-text",
		permittedTo: [...typesTiposUsuario.todosUsuarios],
	},

	// {
	// 	title: "Plantillas de Trabajo",
	// 	descripcion: "" ,
	// 	id: "working-templates",
	// 	path: multiViewComponentsPaths.plantillasTrabajo,
	// 	icon: <BsJournalText />,
	// 	className: "nav-text",
	// 	permittedTo: typesTiposUsuario.todosUsuarios,
	// },
	// {
	// 	title: "Asesor Virtual",
	// 	id: "chat-ia",
	// 	icon: <BiUserPin />,
	// 	path: multiViewComponentsPaths.openAI,
	// 	permittedTo: typesTiposUsuario.todosUsuarios,
	// },
	{
		title: "Suite IA",
		descripcion: "La inteligencia artificial incrementa la productividad y calidad en tu trabajo, explora las diferentes herramientas con inteligencia artificial y libera todo su potencial para aplicarlos en tu día a día" ,
		id: "suite-ia",
		icon: <GiProcessor />,
		path: multiViewComponentsPaths.suiteAI,
		permittedTo: typesTiposUsuario.todosUsuarios,
	},
	{
		title: "Foro Empresarial",
		descripcion: "Mantente en constante contacto con tus colaboradores al asignar tareas o actividades, lleva el control y administra a tu equipo generando un reporte del avance de sus integrantes y de su actividad en la plataforma",
		id: "",
		path: multiViewComponentsPaths.foroEmpresarial,
		icon: <FaBriefcase />,
		className: "nav-text",
		permittedTo: typesTiposUsuario.usuariosEmpresariales,
	},
	// {
	// 	title: "Aula Virtual",
	// 	descripcion: "" ,
	// 	id: "",
	// 	path: multiViewComponentsPaths.aulaVirtual,
	// 	icon: <FaChalkboardTeacher />,
	// 	classNames: "nav-text",
	// 	permittedTo: typesTiposUsuario.todosUsuarios,
	// },
	{
		title: "Mis Avances",
		descripcion: "Mira el progreso en tu aprendizaje, tu tiempo en la plataforma y tu última lección vista" ,
		id: "",
		path: multiViewComponentsPaths.misAvances,
		icon: <FaChartLine />,
		className: "nav-text",
		permittedTo: typesTiposUsuario.todosUsuarios,
	},
	{
		title: "Centro de Ayuda",
		descripcion: "¿Necesitas ayuda? Consulta las preguntas más frecuentes para resolver tus dudas que tengas sobre la plataforma y temas de tu licencia Click+" ,
		id: "",
		path: multiViewComponentsPaths.centroAyuda,
		icon: <IoMdHelpBuoy />,
		classNames: "nav-text",
		permittedTo: typesTiposUsuario.todosUsuarios,
	},
	{
		title: 'Mis Pagos',
		descripcion: "Solicíta tu estado de cuenta o renueva tu licencia Click+." ,
		id: '',
		path: multiViewComponentsPaths.misPagos,
		icon: <BsCurrencyDollar />,
		classNames: 'nav-text',
		permittedTo: [ typesTiposUsuario.administrador, typesTiposUsuario.asesor, typesTiposUsuario.estudiante, typesTiposUsuario.lecturaVeloz ]
	},
	{
		title: "Curso propedéutico MOS",
		descripcion: "Prepárate y mira todos los requisitos previos para tu examen de certificación MOS, cuando estés listo podrás agendar la fecha para tu examen de certificación" ,
		id: "",
		path: multiViewComponentsPaths.cursoPropedeuticoMos,
		icon: <MdLaptop />,
		classNames: "nav-text",
		permittedTo: typesTiposUsuario.todosUsuarios.filter((usuario) => usuario !== typesTiposUsuario.vendedor),
	},
	{
		title: "Panel de Administración",
		descripcion: "Gestiona el contenido de la plataforma" ,
		id: "",
		path: multiViewComponentsPaths.panelAdministracion,
		icon: <MdOutlineAdminPanelSettings />,
		classNames: "nav-text",
		permittedTo: [typesTiposUsuario.administrador, typesTiposUsuario.capacitador, typesTiposUsuario.vendedor],
	},
	{
		title: "Adquirir Licencia Premium",
		descripcion: "Adquiere tu licencia Premium Click+ y disfruta de todas las herramientas y cursos que la plataforma tiene para ti" ,
		id: "",
		path: multiViewComponentsPaths.solicitudLlamadaVenta,
		icon: <MdShoppingCart />,
		classNames: "nav-text",
		permittedTo: [typesTiposUsuario.invitado, typesTiposUsuario.lecturaVeloz, typesTiposUsuario.cortesia],
	},
];

export default SidebarItems;
