import { Notificacion } from "@components/general/Alertas/Alertas";
import CapsLockTooltip from "@components/general/CapsLockTooltip/CapsLockTooltip";
import TitleDashboard from "@components/general/Titles/TitleDashboard";
import useCapsLockWatcher from "@customHooks/useCapsLockWatcher";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateNombreUsuarioExterno } from "@helpers/httpRequests/httpRequestCursoIngles/httpRequestCursoIngles";
import { updateColaborador } from "@helpers/httpRequests/httpRequestsUsuarios/empleados/httpRequestsEmpleados";
import { updateContrasenia, updateNombreUsuario } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import validarRestriccionesContrasenia from "@helpers/validaciones/validarRestriccionesContrasenia/validarRestriccionesContrasenia";
import { Checkbox } from "primereact/checkbox";
import React, { useRef } from "react";
import { useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";

const ModificarUsuario = ({ colaborador, setEmpleadoModificado }) => {
  // console.log(colaborador);
  const inputContrasenia = useRef(null);

  const isCapsLockOn = useCapsLockWatcher(inputContrasenia);

  const { nombreCompleto, apellidos, curp, profesion, idPersona, idCredencial, nombreUsuario, correo } = colaborador;

  console.log(colaborador);

  const [formValues, setFormValues] = useState({
    nombre: nombreCompleto,
    apellidos,
    curp,
    profesion,
    correo
  });

  const [showFormCambioUsuario, setShowFormCambioUsuario] = useState(false);

  const [showFormCambioContra, setShowFormCambioContra] = useState(false);

  const validacionContraseniaInitialState = {
    caracteresContrasenia: false,
    mayusculaContrasenia: false,
    numeroContrasenia: false,
    codigoVerificacion: false,
    contrasenia: false,
    confirmacionContrasenia: false,
  };

  const [validacionContrasenia, setValidacionContrasenia] = useState(validacionContraseniaInitialState);

  const [showPasswords, setShowPasswords] = useState({
    contrasenia: false,
    confirmacionContrasenia: false,
  });

  const handleOnChange = ({ target }) => {
    const { name, value } = target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const showContrasenia = () => {
    setShowPasswords({
      ...showPasswords,
      contrasenia: !showPasswords.contrasenia,
    });
  };

  const showConfirmacionContrasenia = () => {
    setShowPasswords({
      ...showPasswords,
      confirmacionContrasenia: !showPasswords.confirmacionContrasenia,
    });
  };

  const handleFormUsuario = () => setShowFormCambioUsuario(!showFormCambioUsuario);

  const handleFormContra = () => setShowFormCambioContra(!showFormCambioContra);

  const handleOnChangeNombreUsuario = async ({ target }) => {
    try {
      const { name, value } = target;

      setFormValues({
        ...formValues,
        [name]: value,
      });
    } catch (e) {
      console.log("catch", e);
    }
  };

  const handleOnChangePasswords = async ({ target }) => {
    try {
      const { name, value } = target;

      if (name === "contrasenia") {
        setFormValues({
          ...formValues,
          [name]: value,
        });

        const validacionesContrasenia = await validarRestriccionesContrasenia({
          contrasenia: value,
          isNuevaContrasenia: true,
        });

        setValidacionContrasenia({
          ...validacionContrasenia,
          ...validacionesContrasenia,
        });
      } else if (name === "confirmacionContrasenia") {
        setFormValues({
          ...formValues,
          confirmacionContrasenia: value,
        });

        let contraseniasCoincidentes = false;

        if (value === formValues.contrasenia) {
          contraseniasCoincidentes = true;
        }

        setValidacionContrasenia({
          ...validacionContrasenia,
          confirmacionContrasenia: contraseniasCoincidentes,
          contrasenia: true,
        });
      }
    } catch (e) {
      console.log("catch", e);
    }
  };

  const modificarUsuario = async (e) => {
    e.preventDefault();
    try {
      const { nombre, apellidos, curp, profesion, correo } = formValues;

      const informacionEmpleado = {
        nombre,
        apellidos,
        curp,
        profesion,
        idPersona,
        correo
      };

      // console.log(informacionEmpleado);

      const { message } = await updateColaborador(informacionEmpleado);

      if (formValues.contrasenia && showFormCambioContra === true) {
       if(formValues.contrasenia !== "") {
          const credentials = {
            idCredencial: idCredencial,
            nuevaContrasenia: formValues.contrasenia,
          };

          await updateContrasenia(credentials);
       }
      }

      if (formValues.nuevoNombreUsuario && showFormCambioUsuario === true) {
        if(formValues.nuevoNombreUsuario !== "") {
          const credentialsNombreUsuario = {
            idCredencial: idCredencial,
            nombreUsuario: formValues.nuevoNombreUsuario,
          };
  
          await updateNombreUsuarioExterno({
            nombreUsuarioNuevo: formValues.nuevoNombreUsuario,
            correo,
          });
  
          await updateNombreUsuario(credentialsNombreUsuario);
  
        }

      }


      // console.log(message);
      setEmpleadoModificado(true);
      Notificacion("success", "Cambios guardados exitosamente");
    } catch (error) {
      console.log(error);
      Notificacion("error", "Hubo un error al guardar los cambios");
    }
  };

  return (
    <Container className="text-start">
      <Row>
        <Col>
          <Form onSubmit={modificarUsuario}>
            <Form.Group className="mb-3">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                onChange={handleOnChange}
                required
                name="nombre"
                value={formValues.nombre}
                type="text"
                placeholder="Nombre del usuario"
              ></Form.Control>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Apellidos</Form.Label>
              <Form.Control
                onChange={handleOnChange}
                required
                name="apellidos"
                value={formValues.apellidos}
                type="text"
                placeholder="Apellidos del usuario"
              ></Form.Control>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>CURP</Form.Label>
              <Form.Control
                onChange={handleOnChange}
                required
                name="curp"
                value={formValues.curp}
                type="text"
                placeholder="CURP"
              ></Form.Control>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Correo</Form.Label>
              <Form.Control
                onChange={handleOnChange}
                required
                name="correo"
                value={formValues.correo}
                type="text"
                placeholder="Correo electrónico"
              ></Form.Control>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Profesión</Form.Label>
              <Form.Control
                onChange={handleOnChange}
                required
                name="profesion"
                value={formValues.profesion}
                type="text"
                placeholder="Profesión del usuario"
              ></Form.Control>
            </Form.Group>


            <div className="flex align-items-center my-4">
              <Checkbox inputId="cambioUsuario" name="cambiarUsuario" value="Cambiar nombre de usuario" onChange={handleFormUsuario} checked={showFormCambioUsuario} />
              <label htmlFor="cambioUsuario" className="ml-2 mx-3" style={{cursor: 'pointer'}}> Cambiar nombre de usuario</label>
            </div>

            {showFormCambioUsuario && (
              <div className="mt-3">
                <TitleDashboard title={"Modificar nombre de usuario"} />
                <Form.Group className="mt-3 mb-4">
                  <Form.Label>Nombre de usuario actual</Form.Label>

                  <Form.Control value={nombreUsuario} type="text" disabled />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Nuevo nombre de usuario</Form.Label>

                  <Form.Control
                    onChange={handleOnChangeNombreUsuario}
                    value={formValues.nuevoNombreUsuario}
                    type="text"
                    autoComplete="off"
                    name="nuevoNombreUsuario"
                    placeholder="Ingres tu nuevo nombre de usuario"
                  />
                </Form.Group>
              </div>
            )}


            <div className="flex align-items-center my-4">
              <Checkbox inputId="cambiaContra" name="cambiaContra" value="Cambiar contraseña" onChange={handleFormContra} checked={showFormCambioContra} />
              <label htmlFor="cambiaContra" className="ml-2 mx-3" style={{cursor: 'pointer'}}> Cambiar contraseña</label>
            </div>

            {showFormCambioContra && (
              <div className="mt-3 mb-4">
                <TitleDashboard title={"Modificar contraseña"} />

                <Form.Group className="mt-3">
                  <Form.Label>Contraseña nueva</Form.Label>

                  <InputGroup hasValidation>
                    <InputGroup.Text onClick={showContrasenia} className="pointer">
                      <FontAwesomeIcon icon={faEye} />
                    </InputGroup.Text>

                    <Form.Control
                      type={showPasswords.contrasenia ? "text" : "password"}
                      autoComplete="off"
                      value={formValues.contrasenia}
                      className={validacionContrasenia.contrasenia ? "is-valid " : ""}
                      name="contrasenia"
                      placeholder="Contraseña nueva"
                      onChange={handleOnChangePasswords}
                      ref={inputContrasenia}
                    />

                    <Form.Control.Feedback type="invalid">Genera una contraseña v&aacute;lida</Form.Control.Feedback>

                    <CapsLockTooltip input={inputContrasenia} isCapsLockOn={isCapsLockOn} />
                  </InputGroup>

                  <Form.Text id="passwordHelpBlock" muted>
                    La contraseña debe contener:
                    <ul>
                      <li className={validacionContrasenia.caracteresContrasenia ? "text-success" : "text-danger"}>8 caracteres</li>
                      <li className={validacionContrasenia.mayusculaContrasenia ? "text-success" : "text-danger"}>1 mayúscula</li>
                      <li className={validacionContrasenia.numeroContrasenia ? "text-success" : "text-danger"}>1 número</li>
                    </ul>
                    {/* La contraseña debe ser de al menos 8 caracteres, 1 mayúscula y 1 número */}
                  </Form.Text>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Confirmar contraseña</Form.Label>

                  <InputGroup hasValidation>
                    <InputGroup.Text onClick={showConfirmacionContrasenia} className="pointer">
                      <FontAwesomeIcon icon={faEye} />
                    </InputGroup.Text>

                    <Form.Control
                      onChange={handleOnChangePasswords}
                      value={formValues.confirmacionContrasenia}
                      type={showPasswords.confirmacionContrasenia ? "text" : "password"}
                      autoComplete="off"
                      name="confirmacionContrasenia"
                      placeholder="Confirmar nueva contraseña"
                      className={validacionContrasenia.confirmacionContrasenia ? "is-valid" : "is-invalid"}
                    />

                    <Form.Control.Feedback type="invalid">Las contraseñas deben de ser iguales</Form.Control.Feedback>
                    <Form.Control.Feedback type="valid">Las contraseñas son iguales</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </div>
            )}


            <Button type="submit" block>
              Guardar cambios
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ModificarUsuario;
