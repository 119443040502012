import { getAgendasAsesorias } from "@helpers/httpRequests/httpRequestAgendaAsesorias/httpRequestAgendaAsesorias";
import { getHerramientasSuite } from "@helpers/httpRequests/httpRequestsOpenAI/httpRequestsOpenAI";
import React, { useEffect, useState } from "react";
import { Accordion, Col, Row } from "react-bootstrap";

const AccordionEstadisticas = ({
	usuariosPlataforma,
	totalEstudiantes,
	totalEmpleados,
	totalSuspendidos,
	totalVencidos,
	cursoMasVisto,
	categorias,
	cursos,
	secciones,
	lecciones,
	certificados,
	promedioGeneral,
	examenesMosRealizados,
	examenesAprobados,
	examenesReprobados,
	cantidadTiempoJuego,
	cantidadVisitasSala,
	juegoMasUsado,
	publicacionesEmpresariales,
	temasForoAyuda,
	setMostrarUsuariosSuspendidos,
	setMostrarDetallesMos,
	mostrarDetallesMos,
	mostrarUsuariosSuspendidos,
	usuariosSuite,
	vecesUsadaSuite,
	herramientaSuite,
	agendasAsesorias,
	asesoriasResueltas,
	mostrarAgendasAsesorias,
	setMostrarAgendasAsesorias,
	usuariosAppMovil,
}) => {

	const [herramientasSuite, setHerramientasSuite] = useState([]);

	const [nombreHerramientaSuite, setNombreHerramientaSuite] = useState('');

 	const obtenerHerramientaMasUsada = (codigoHerramienta) => {
		if(herramientasSuite.length > 0){
		const nombreHerramienta = herramientasSuite.filter(herramienta => herramienta.codigoHerramienta === codigoHerramienta);
		setNombreHerramientaSuite(nombreHerramienta[0].nombre);
		}
	}

	useEffect(() => {
		const obtenerHerramientasSuite = async() => {
			try {
				const {data} = await getHerramientasSuite();
				
				if(Array.isArray(data.data)){
					setHerramientasSuite(data.data);
				}else{
					setHerramientasSuite([data.data]);
				}

			} catch (error) {
				console.log(error);
			}
		}

		obtenerHerramientasSuite();
	}, [])

	useEffect(() => {
	  if(herramientasSuite.length > 0) {
		obtenerHerramientaMasUsada(herramientaSuite);
	  }
	}, [herramientaSuite])
	
	

	return (
		<Accordion flush>
			<Accordion.Item eventKey="0">
				<Accordion.Header>Usuarios</Accordion.Header>
				<Accordion.Body>
					<Row className="mt-3 mb-3 justify-content-center">
						<Col className="text-center col-4">
							<b>{usuariosPlataforma}</b>
							<p>
								<small>Usuarios en la plataforma</small>
							</p>
						</Col>
						<Col className="text-center col-4">
							<b>{totalEstudiantes}</b>
							<p>
								<small>Estudiantes</small>
							</p>
						</Col>
						<Col className="text-center col-4">
							<b>{totalEmpleados}</b>
							<p>
								<small>Usuarios empresariales</small>
							</p>
						</Col>
						<Col className="text-center col-4">
							<b
								className="pointer text-info"
								onClick={() => {
									if (mostrarDetallesMos || mostrarAgendasAsesorias) {
										setMostrarDetallesMos(false);
										setMostrarAgendasAsesorias(false);
									}
									setMostrarUsuariosSuspendidos(true);
								}}>
								{totalSuspendidos}
							</b>
							<p>
								<small>Usuarios suspendidos</small>
							</p>
						</Col>
						<Col className="text-center col-4">
							<b>{totalVencidos}</b>
							<p>
								<small>Usuarios vencidos</small>
							</p>
						</Col>
					</Row>
				</Accordion.Body>
			</Accordion.Item>

			<Accordion.Item eventKey="1">
				<Accordion.Header>Cursos</Accordion.Header>
				<Accordion.Body>
					<Row className="mt-3 mb-3 justify-content-center">
						<Col className="text-center col-4">
							<b>
								<small>{cursoMasVisto}</small>
							</b>
							<p>
								<small>Más visto</small>
							</p>
						</Col>
						<Col className="text-center col-4">
							<b>{categorias}</b>
							<p>
								<small>Categorías</small>
							</p>
						</Col>
						<Col className="text-center col-4">
							<b>{cursos}</b>
							<p>
								<small>Cursos</small>
							</p>
						</Col>
						<Col className="text-center col-4">
							<b>{secciones}</b>
							<p>
								<small>Secciones</small>
							</p>
						</Col>
						<Col className="text-center col-4">
							<b>{lecciones}</b>
							<p>
								<small>Lecciones</small>
							</p>
						</Col>

						<Col className="text-center col-4">
							<b>{certificados}</b>
							<p>
								<small>Certificados emitidos</small>
							</p>
						</Col>

						<Col className="text-center col-4">
							<b>{promedioGeneral}%</b>
							<p>
								<small>Promedio general</small>
							</p>
						</Col>
					</Row>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="2">
				<Accordion.Header>Certificación MOS</Accordion.Header>
				<Accordion.Body>
					<Row className="mt-3 mb-3 justify-content-center">
						<Col className="text-center col-4">
							<b
								className="pointer text-info"
								onClick={() => {
									if (mostrarUsuariosSuspendidos || mostrarAgendasAsesorias) {
										setMostrarUsuariosSuspendidos(false);
										setMostrarAgendasAsesorias(false);

									}
									setMostrarDetallesMos(true);
								}}>
								<small>{examenesMosRealizados.examenes_realizados}</small>
							</b>
							<p>
								<small>Examenes realizados</small>
							</p>
						</Col>

						<Col className="text-center col-4">
							<b>
								<small>{examenesAprobados.cantidad_aprobados}</small>
							</b>
							<p>
								<small>Examenes aprobados</small>
							</p>
						</Col>

						<Col className="text-center col-4">
							<b>
								<small>{examenesReprobados.cantidad_reprobados}</small>
							</b>
							<p>
								<small>Examenes reprobados</small>
							</p>
						</Col>
					</Row>
				</Accordion.Body>
			</Accordion.Item>

			<Accordion.Item eventKey="3">
				<Accordion.Header>Sala de entretenimientos</Accordion.Header>
				<Accordion.Body>
					<Row className="mt-3 mb-3 justify-content-center">
						<Col className="text-center col-6">
							<b>
								<small>{cantidadTiempoJuego}</small>
							</b>
							<p>
								<small>Total de tiempo de juego</small>
							</p>
						</Col>

						<Col className="text-center col-4">
							<b>
								<small>{cantidadVisitasSala}</small>
							</b>
							<p>
								<small>Total de visitas a la sala</small>
							</p>
						</Col>

						<Col className="text-center col-4">
							<b>
								<small>{juegoMasUsado}</small>
							</b>
							<p>
								<small>Juego más usado</small>
							</p>
						</Col>
					</Row>
				</Accordion.Body>
			</Accordion.Item>

			<Accordion.Item eventKey="4">
				<Accordion.Header>Suite de IA</Accordion.Header>
				<Accordion.Body>
					<Row className="mt-3 mb-3 justify-content-center">
						<Col className="text-center col-6">
							<b>
								<small>{nombreHerramientaSuite}</small>
							</b>
							<p>
								<small>Herramienta más usada</small>
							</p>
						</Col>
						<Col className="text-center col-6">
							<b>
								<small>{usuariosSuite}</small>
							</b>
							<p>
								<small>Usuarios han usado la suite</small>
							</p>
						</Col>
						<Col className="text-center col-6">
							<b>
								<small>{vecesUsadaSuite}</small>
							</b>
							<p>
								<small>Veces ha sido usada la suite</small>
							</p>
						</Col>
					</Row>
					<Row>
						<Col className="col-12">
								<h5 className="self-center">Herramientas disponibles en la suite</h5>
								<ul>
									{herramientasSuite.map((herramienta, indexHerramienta) => (
										<li key={indexHerramienta}>{herramienta.nombre}</li>
									))}
								</ul>
						</Col>
					</Row>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="5">
				<Accordion.Header>Agendas de asesoría</Accordion.Header>
				<Accordion.Body>
					<Row className="mt-3 mb-3 justify-content-center">
						<Col className="text-center col-6">
							<b
								className="pointer text-info"
								onClick={() => {
									if (mostrarDetallesMos || mostrarUsuariosSuspendidos) {
										setMostrarDetallesMos(false);
										setMostrarUsuariosSuspendidos(false);

									}
									setMostrarAgendasAsesorias(true);
								}}>
								<small>{agendasAsesorias.length}</small>
							</b>
							<p>
								<small>Agendas de asesoría en total</small>
							</p>
						</Col>
						<Col className="text-center col-6">
							<b>
								<small>{asesoriasResueltas.length}</small>
							</b>
							<p>
								<small>Asesorías resueltas</small>
							</p>
						</Col>
					</Row>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="6">
				<Accordion.Header>Otros</Accordion.Header>
				<Accordion.Body>
					<Row className="mt-3 mb-3 justify-content-center">
						<Col className="text-center col-4">
							<b>
								<small>{publicacionesEmpresariales}</small>
							</b>
							<p>
								<small>{publicacionesEmpresariales >= 2 || publicacionesEmpresariales === 0 ? "Publicaciones" : "Publicación"} de foros empresariales</small>
							</p>
						</Col>

						<Col className="text-center col-4">
							<b>
								<small>{temasForoAyuda}</small>
							</b>
							<p>
								<small>{temasForoAyuda >= 2 || temasForoAyuda === 0 ? "Publicaciones" : "Publicación"} en foro de ayuda</small>
							</p>
						</Col>

						<Col className="text-center col-4">
							<b>
								<small>{usuariosAppMovil}</small>
							</b>
							<p>
								<small>{usuariosAppMovil === 1 ? "Cuenta" : "Cuentas" } {usuariosAppMovil === 1 ? "activa" : "activas"} en app móvil</small>
							</p>
						</Col>
					</Row>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	);
};

export default AccordionEstadisticas;
