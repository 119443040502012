import "@assets/css/components/DashboardLecciones/DataTables/style.css";

import { Col, Container, Row } from "react-bootstrap";
import {
  ModalSweetAlert,
  Notificacion,
} from "@components/general/Alertas/Alertas";
import React, { useEffect, useState } from "react";
import {
  faEdit,
  faEye,
  faPowerOff,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  getLecciones,
  reactivateLeccion,
} from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";

import { DataTablesRecipient } from "@components/general/DataTablesRecipient/DataTablesRecipient";
import { EditarLeccion } from "../Modales/EditarLeccion/EditarLeccion";
import { EliminarLeccion } from "../Modales/EliminarLeccion/EliminarLeccion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import columnas from "./columnas";

export const TableLecciones = ({ leccionAgregada, setLeccionAgregada, leccionesImportadas, setLeccionesImportadas }) => {
  const [lecciones, setLecciones] = useState([]);

  const [dataTableColumns, setDataTableColumns] = useState([]);

  const [leccionEditada, setLeccionEditada] = useState(false);

  const [leccionEliminada, setLeccionEliminada] = useState(false);

  const [leccionReactivada, setLeccionReactivada] = useState(false);

  /**
   * ENCARGADO DE FILTRAR CADA QUE SE DETECTE CAMBIO EN EL BUSCADOR
   */
  // const filtrarElementos = (e) => {
  // 	console.log("data");
  // 	console.log(data);
  // 	let busqueda = e.target.value.toLowerCase();
  // 	console.log("busqueda");
  // 	console.log(busqueda);
  // 	let search = data.data.filter((registro) => {
  // 		console.log("registro");
  // 		console.log(registro);

  // 		if (busqueda.length > 0) {
  // 			if (
  // 				registro.nombre.toLowerCase().includes(busqueda) ||
  // 				registro.codigoSeccion.toLowerCase().includes(busqueda) ||
  // 				registro.codigoLeccion.toLowerCase().includes(busqueda) ||
  // 				registro.urlPlantilla.toString().toLowerCase().includes(busqueda)
  // 			)
  // 				return registro;
  // 		}
  // 		return false;
  // 	});

  // 	setState({ ...state, leccionesFiltradas: search });
  // };

  useEffect(() => {
    const consultarLecciones = async () => {
      try {
        const { data: lecciones } = await getLecciones();

        setLecciones(lecciones);
      } catch (error) {
        console.log("Error al consultar las lecciones:", error);

        Notificacion(
          "error",
          "Ocurrió algo al consultar las lecciones, vuelve a intentar"
        );
      }
    };

    consultarLecciones();
  }, []);

  useEffect(() => {
    if (lecciones.length > 0) {
      const handleEditLeccion = async ({ currentTarget }) => {
        try {
          const codigoLeccion = currentTarget.id.split("-")[1];

          const leccion = lecciones.filter(
            (leccion) => leccion.codigoLeccion === codigoLeccion
          )[0];

          await ModalSweetAlert({
            title: "Editar lección",
            html: (
              <EditarLeccion
                leccion={leccion}
                setLeccionEditada={setLeccionEditada}
              />
            ),
            showConfirmButton: false,
            showCloseButton: true,
          });
        } catch (error) {
          console.log("Error al editar la lección: ", error);
        }
      };

      const handleDeleteLeccion = async ({ currentTarget }) => {
        try {
          const codigoLeccion = currentTarget.id.split("-")[1];

          await ModalSweetAlert({
            title: "Eliminar lección",
            html: (
              <EliminarLeccion
                setLeccionEliminada={setLeccionEliminada}
                codigoLeccion={codigoLeccion}
              />
            ),
            showConfirmButton: false,
            showCloseButton: true,
          });
        } catch (error) {
          console.log("Error al eliminar la lección: ", error);
        }
      };

      const handleReactivateLeccion = async ({ currentTarget }) => {
        try {
          const codigoLeccion = currentTarget.id.split("-")[1];

          const leccionReactivada = await reactivateLeccion(codigoLeccion);

          Notificacion("success", "La lección se reactivó correctamente");

          setLeccionReactivada(true);
        } catch (error) {
          console.log("Error al reactivar la lección: ", error);
        }
      };

      setDataTableColumns([
        ...columnas,
        {
          name: "Acciones",
          cell: (row) => (
            <>
              <FontAwesomeIcon
                id={`edit-${row.codigoLeccion}`}
                icon={faEdit}
                className="ms-2 pointer text-primary"
                onClick={handleEditLeccion}
              />

              {row.estatus ? (
                <FontAwesomeIcon
                  id={`delete-${row.codigoLeccion}`}
                  icon={faTrashAlt}
                  className="ms-2 pointer text-danger"
                  onClick={handleDeleteLeccion}
                />
              ) : (
                <FontAwesomeIcon
                  id={`reactivate-${row.codigoLeccion}`}
                  icon={faPowerOff}
                  className="ms-2 pointer text-success"
                  onClick={handleReactivateLeccion}
                />
              )}
            </>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ]);
    }
  }, [lecciones]);

  useEffect(() => {
    if (
      leccionAgregada ||
      leccionEditada ||
      leccionEliminada ||
      leccionReactivada ||
      leccionesImportadas
    ) {
      const consultarLecciones = async () => {
        try {
          const { data: lecciones } = await getLecciones();

          setLecciones(lecciones);

          setLeccionAgregada(false);
          setLeccionEditada(false);
          setLeccionEliminada(false);
          setLeccionReactivada(false);
          setLeccionesImportadas(false);
        } catch (error) {
          console.log("Error al consultar las lecciones:", error);

          Notificacion(
            "error",
            "Ocurrió algo al consultar las lecciones, vuelve a intentar"
          );
        }
      };

      consultarLecciones();
    }
  }, [leccionAgregada, leccionEditada, leccionEliminada, leccionReactivada, leccionesImportadas]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <DataTablesRecipient columns={dataTableColumns} data={lecciones} />
        </Col>

        {/* <AgregarLeccion state={state} setState={setState} handleCloseModal={handleCloseModal} handleRefetch={handleRefetch} />

					<VerLeccion state={state} setState={setState} handleCloseModal={handleCloseModal} handleRefetch={handleRefetch} />
					<EditarLeccion state={state} setState={setState} handleCloseModal={handleCloseModal} handleRefetch={handleRefetch} />
					<EliminarLeccion state={state} setState={setState} handleCloseModal={handleCloseModal} handleRefetch={handleRefetch} /> */}
      </Row>
    </Container>
  );
};
