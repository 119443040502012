import "primereact/resources/primereact.min.css";

// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/themes/lara-dark-indigo/theme.css";

import "animate.css";
import "bootstrap/dist/css/bootstrap.min.css";

import AppRoutes from "@routes/AppRoutes/AppRoutes";
import NotificationContainer from "react-notifications/lib/NotificationContainer";
import React from "react";
// import { PrimeReactProvider, PrimeReactContext } from "primereact/api";
import UserProvider from "@context/User/UserProvider";

const PlataformaClickPlus = () => {

	return (
		// {/* UserProvider - UserProvider para useContext(UserContext) */}
		<UserProvider>
			{/* ProSidebarProvider - Despliega el sidebar */}
				{/* Rutas de la aplicación */}
				{/* <PrimeReactProvider> */}
					<AppRoutes />
				{/* </PrimeReactProvider> */}

				{/* Notificaciones que no cierran SweetAlert */}
				<NotificationContainer />
		</UserProvider>
	);
};

export default PlataformaClickPlus;
