import { Notificacion, NotificacionExtendida } from "@components/general/Alertas/Alertas";
import CapsLockTooltip from "@components/general/CapsLockTooltip/CapsLockTooltip";
import UserContext from "@context/User/UserContext";
import useCapsLockWatcher from "@customHooks/useCapsLockWatcher";
import { faEye, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmarRegistroInicial, extraerRegionesSai, getDatosFolio, validateTokenFolio } from "@helpers/httpRequests/httpRequestsSAI/httpRequestsSAI";
import { addSegundoPerfilEstudiante, updateFechaRegistro } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import { addUsuarioLecturaVeloz, addUsuarioTitular } from "@helpers/httpRequests/httpRequestsUsuarios/registro/httpRequestsRegistroUsuarios";
import validarRestriccionesContrasenia from "@helpers/validaciones/validarRestriccionesContrasenia/validarRestriccionesContrasenia";
import { RadioButton } from "primereact/radiobutton";
import React, { useContext, useRef, useState, useEffect } from "react";
import { Alert, Button, Col, Container, Form, FormControl, FormGroup, FormLabel, InputGroup, Row, Spinner } from "react-bootstrap";
import { FaSearch, FaWindowClose } from "react-icons/fa";
import { InputSwitch } from "primereact/inputswitch";
import { getAvancesPerfil, getCertificacionesPerfil, getFechaRegistro, getPuntajesPerfil } from "@helpers/httpRequests/httpRequestUsuariosMoodle/httpRequestUsuariosMoodle";
import getUnixFromDate from "@helpers/dates/getUnixFromDate/getUnixFromDate";
import { addNuevaLeccionVista } from "@helpers/httpRequests/httpRequestLeccionesVistas/httpRequestsLeccionesVistas";
import { guardarCertificado } from "@helpers/httpRequests/httpRequestsCertificadosEmitidos/httpRequestsCertificadosEmitidos";
import { addCurso } from "@helpers/httpRequests/httpRequestsCursosUsuario/httpRequestsCursosUsuario";
import regExpStrings from "@helpers/regExpStrings/regExpStrings";
import getUnixTimestamp from "@helpers/dates/getUnixTimestamp/getUnixTimestamp";
import { getLicencias } from "@helpers/httpRequests/httpRequestsLicencias/httpRequestsLicencias";

const DashboardNuevoEstudiante = () => {
	const inputContrasenia = useRef(null);

	const isCapsLockOn = useCapsLockWatcher(inputContrasenia);

	const { contextValue, setContextValue } = useContext(UserContext);

	const { usuarioLogeado } = contextValue;

	const [tieneSegundoUsuario, setTieneSegundoUsuario] = useState(false);

	const [registrarSegundoUsuario, setRegistrarSegundoUsuario] = useState(false);

	const formValuesInitialState = {
		contrasenia: "",
		confirmacionContrasenia: "",
		nombreCompleto: "",
		apellidos: "",
		correo: "",
		curp: "",
		alias: "",
		nombreUsuario: "",
		folioContrato: 0,
		idLicencia: 0,
		cantidadEstudiantes: 0,
		fechaExpiracion: "",
	};

	const validacionContraseniaInitialState = {
		caracteresContrasenia: false,
		mayusculaContrasenia: false,
		numeroContrasenia: false,
		contrasenia: false,
		confirmacionContrasenia: false,
	};

	const [formValues, setFormValues] = useState(formValuesInitialState);

	const [formValuesSegundoUsuario, setFormValuesSegundoUsuario] = useState({
		nombreSegundoUsuario: "",
		apellidosSegundoUsuario: "",
		correoSegundoUsuario: "",
		curpSegundoUsuario: "",
		avatarPerfil: "",
		folioContrato: formValues.folioContrato,
		idLicencia: formValues.idLicencia,
	});

	const [validacionContrasenia, setValidacionContrasenia] = useState(validacionContraseniaInitialState);

	const [showPasswords, setShowPasswords] = useState({
		contrasenia: false,
		confirmacionContrasenia: false,
	});

	const [folioVerificado, setFolioVerificado] = useState(false);

	const [camposVacios, setCamposVacios] = useState(false);

	const [showLoaderFolio, setShowLoaderFolio] = useState(false);

	const [showLoader, setShowLoader] = useState(false);

	const [sinFolio, setSinFolio] = useState(false);

	const [proceso, setProceso] = useState("");

	const [licenciasDisponibles, setLicenciasDisponibles] = useState([]);

	const [regiones, setRegiones] = useState([]);

	const cleanFechaExpiracion = () => {
		setFormValues({
			...formValues,
			fechaExpiracion: "",
		})
	}

	const showContrasenia = () => {
		setShowPasswords({
			...showPasswords,
			contrasenia: !showPasswords.contrasenia,
		});
	};

	const handleOnChangePasswords = async ({ target }) => {
		try {
			const { name, value } = target;

			if (name === "contrasenia") {
				setFormValues({
					...formValues,
					[name]: value,
				});

				const validacionesContrasenia = await validarRestriccionesContrasenia({
					contrasenia: value,
					isNuevaContrasenia: true,
				});

				setValidacionContrasenia({
					...validacionContrasenia,
					...validacionesContrasenia,
				});
			} else if (name === "confirmacionContrasenia") {
				setFormValues({
					...formValues,
					confirmacionContrasenia: value,
				});

				let contraseniasCoincidentes = false;

				if (value === formValues.contrasenia) {
					contraseniasCoincidentes = true;
				}

				setValidacionContrasenia({
					...validacionContrasenia,
					confirmacionContrasenia: contraseniasCoincidentes,
					contrasenia: true,
				});
			}
		} catch (e) {
			console.log("catch", e);
		}
	};

	const showConfirmacionContrasenia = () => {
		setShowPasswords({
			...showPasswords,
			confirmacionContrasenia: !showPasswords.confirmacionContrasenia,
		});
	};

	const handleOnChange = ({ currentTarget }) => {
		const { name, value } = currentTarget;

		setFormValues({
			...formValues,
			[name]: value,
		});
	};

	const handleOnChangeRegion  = ({currentTarget}) => {
		const {value} = currentTarget;

		const valores = value.split(",");

		setFormValues({
			...formValues,
			idRegion: parseInt(valores[0]),
			codigoRegion: valores[1],
		});
	}

	const buscarDatosFolio = async () => {
		try {
			setShowLoaderFolio(true);
			const { cliente: datosContrato } = await getDatosFolio(formValues.folioContrato, formValues.idRegion);
			const { nombre, apellidoMaterno, apellidoPaterno, email } = datosContrato;

			setFormValues({
				...formValues,
				nombreCompleto: nombre,
				apellidos: apellidoPaterno + " " + apellidoMaterno,
				correo: email,
			});

			setFolioVerificado(true);
			setShowLoaderFolio(false);
		} catch (error) {
			console.log(error);
			setShowLoaderFolio(false);
		}
	};

	const [sinAvances, setSinAvances] = useState(false);

	const [sinCertificaciones, setSinCertificaciones] = useState(false);

	const migracionAvances = async (correo, idPerfil, curp) => {
		//VERIFICAR SI TIENE AVANCES EN LA BASE DE DATOS DE MOODLE
		try {
			setProceso("Importando avances");
			const avances = await getAvancesPerfil(correo);

			if (avances.length === 0) {
				setSinAvances(true);
			} else {
				avances.map(async (avance) => {
					const fechaFormateada = getUnixFromDate(avance.fecha);

					await addNuevaLeccionVista({
						idPerfil: idPerfil,
						codigoLeccion: avance.leccion_codigo,
						codigoCurso: avance.curso_codigo,
						fecha: fechaFormateada,
					});
				});
			}
		} catch (error) {
			console.log("No se encontraron avances en la cuenta");
			//Estado para validar que no hay avances de la cuenta
			setSinAvances(true);
		}

		try {
			setProceso("Obteniendo puntaje de cursos realizados en moodle");

			const puntajes = await getPuntajesPerfil(correo);

			if (puntajes.length === 0) {
				setProceso("");
			} else {
				puntajes.map(async (puntaje) => {
					await addCurso({
						codigoCurso: puntaje.idnumber,
						idPerfil: idPerfil,
						puntajeEvaluacion: puntaje.grade,
					});
				});
				setProceso("");
			}
		} catch (error) {
			console.log("No se encontraron puntajes registrados");
		}

		try {
			setProceso("Comprobando si hay certificaciones");
			const certificaciones = await getCertificacionesPerfil(formValues.correo);
			if (certificaciones.length === 0) {
				setSinCertificaciones(true);
				setProceso("");
			} else {
				certificaciones.map(async (certificacion) => {
					await guardarCertificado({
						codigoCurso: certificacion.idnumber,
						idPerfil: idPerfil,
						nombre: certificacion.firstname + "" + certificacion.lastname,
						curp: curp,
						fecha: certificacion.timecreated,
					});
				});
				setProceso("");
			}
		} catch (error) {
			console.log("No se recuperaron certificaciones del perfil");
			//Estado para validar que no hay certificaciones de la cuenta
			setSinCertificaciones(true);
		}
	};

	const validarVigencia = (fechaVigenciaCalculada, fechaActual) =>{
		if(fechaVigenciaCalculada > fechaActual){
			return true;
		}else{
			return false;
		}

	}

	const registrarEstudiante = async () => {
		try {
			//Validar que los campos del formulario de titular se hayan completado para no enviar datos vacios

			const { contrasenia, nombreCompleto, apellidos, correo, curp, alias, nombreUsuario, confirmacionContrasenia, fechaExpiracion, idLicencia, folioContrato, cantidadEstudiantes, idRegion } = formValues;

			// const validacionCurp = regExpStrings.regexCurp(curp); 

			const licencia = parseInt(idLicencia);

			// if (validacionCurp) {
			if (curp.length === 18) {
				if (nombreCompleto !== "" && apellidos !== "" && correo !== "" && curp !== "" && alias !== "" && nombreUsuario !== "" && confirmacionContrasenia && idLicencia !== 0) {
					setShowLoader(true);
					setProceso("Registrando estudiante");
					let results;
					if(licencia === 4){
					const { data } = await addUsuarioLecturaVeloz({
						idLicencia: licencia,
						folioContrato,
						contrasenia: confirmacionContrasenia,
						nombreUsuario,
						nombreCompleto,
						apellidos,
						correo,
						curp,
						alias
					});
					results = data;
					}else{
						const { data } = await addUsuarioTitular({
							idRegion,
							idLicencia: licencia,
							folioContrato,
							nombreUsuario,
							nombreCompleto,
							apellidos,
							correo,
							curp,
							alias,
							cantidadEstudiantes,
							contrasenia:confirmacionContrasenia
						});
						results = data;

					}

					if(sinFolio === false){
						try {
							const token = await validateTokenFolio(folioContrato, idRegion);
						

							await confirmarRegistroInicial({
								idRegion: formValues.idRegion,
								codigoRegion: formValues.codigoRegion,
								folioContrato: folioContrato,
								tokenRegistro: token,
								nombreUsuario: formValues.nombreUsuario,
								contrasenia: formValues.contrasenia,
								curp: formValues.curp,
							});
						} catch (error) {
							console.log(error)
							console.log("ERROR AL CONFIRMAR EL REGISTRO INICIAL CON SAI");
						}
					}

					if (tieneSegundoUsuario) {
						//Se insertan valores para segundo usuario en caso de tener.
						setFormValuesSegundoUsuario({
							...formValuesSegundoUsuario,
							idPerfil: results[0].idPerfil,
							idCredencial: results[0].idCredencial,
							folioContrato: results[0].folioContrato,
						});
					}

					if (!sinFolio) {
						setProceso("Actualizando fecha de registro");
						//FUNCION PARA RECUPERAR LA FECHA DE REGISTRO EN BASE AL CORREO QUE SE ESTA REGISTRANDO
						const { data: fecha } = await getFechaRegistro(formValues.correo);

						const fecha_registro = getUnixFromDate(fecha.fecha_registro);

						// Crear un objeto de fecha
						var fechaExpiracionCalculada = new Date(fecha.fecha_registro);

						// Sumar 18 meses
						fechaExpiracionCalculada.setMonth(fechaExpiracionCalculada.getMonth() + 18);

						// Convertir la fecha a una marca de tiempo Unix (entero)
						var timestampUnix = Math.floor(fechaExpiracionCalculada.getTime() / 1000);

						const validacion = validarVigencia(!fecha_registro ? getUnixTimestamp() : timestampUnix , getUnixTimestamp())
						//si la validacion es true es porque aun tiene vigencia y se pasa timestampUnix si no se le dan 18 meses 
						let fechaExpiracionNueva = 0;
						if(validacion === false){
							const fechaActual = new Date();

							fechaActual.setMonth(fechaActual.getMonth() + 18);
							
							fechaExpiracionNueva = Math.floor(fechaActual.getTime() / 1000);
						}

						await updateFechaRegistro({
							fecha_registro: !fecha_registro ? getUnixTimestamp() : fecha_registro,
							fecha_expiracion: formValues.fechaExpiracion !== "" ? getUnixFromDate(fechaExpiracion) : validacion === true ? timestampUnix : fechaExpiracionNueva,
							idPerfil: results[0].idPerfil,
						});
					}

					if (!sinFolio) {
						migracionAvances(results[0].correo, results[0].idPerfil, results[0].curp);
					}

					

					if (tieneSegundoUsuario) {
						setRegistrarSegundoUsuario(true);
					}

					if(formValues.fechaExpiracion !== ""){
						await updateFechaRegistro({
							fecha_registro: getUnixTimestamp(),
							fecha_expiracion: getUnixFromDate(formValues.fechaExpiracion),
							idPerfil: results[0].idPerfil,
						})
					}

					setShowLoader(false);

					setFormValues({
						...formValues,
						codigoVerificacion: "",
						contrasenia: "",
						confirmacionContrasenia: "",
						nombreCompleto: "",
						apellidos: "",
						correo: "",
						curp: "",
						alias: "",
						nombreUsuario: "",
						folioContrato: 0,
						idLicencia: 1,
						cantidadEstudiantes: 0,
					});

					setFolioVerificado(false);
					setCamposVacios(false);
					setShowPasswords({
						...showPasswords,
						contrasenia: false,
						confirmacionContrasenia: false,
					});
					setProceso("");
					setTieneSegundoUsuario(false);

					Notificacion("success", "Estudiante registrado");
				} else {
					setCamposVacios(true);
				}
			} else {
				NotificacionExtendida("info", "El CURP debe tener 18 caracteres, revisa la información");
			}
		} catch (error) {
			console.log(error.message);

			setShowLoader(false);

			setProceso("");

			setTieneSegundoUsuario(false);

			Notificacion("error", error.message);

			setFormValues({
				...formValues,
				codigoVerificacion: "",
				contrasenia: "",
				confirmacionContrasenia: "",
				nombreCompleto: "",
				apellidos: "",
				correo: "",
				curp: "",
				alias: "",
				nombreUsuario: "",
				folioContrato: 0,
				idLicencia: 1,
				cantidadEstudiantes: 0,
			});
      
			setFolioVerificado(false);
		}
	};

	const handleOnChangeSegundoUsuario = ({ currentTarget }) => {
		const { name, value } = currentTarget;

		setFormValuesSegundoUsuario({
			...formValuesSegundoUsuario,
			[name]: value,
		});
	};

	//Funcion para guardar los registros del segundo usuario
	const guardarSegundoUsuario = async () => {
		try {
			setCamposVacios(false);
			const { nombreSegundoUsuario, apellidosSegundoUsuario, correoSegundoUsuario, curpSegundoUsuario } = formValuesSegundoUsuario;

			if (nombreSegundoUsuario !== "" && apellidosSegundoUsuario !== "" && correoSegundoUsuario !== "" && curpSegundoUsuario !== "") {
				setShowLoader(true);
				setProceso("Registrando usuario adicional");

				//
				const { data } = await addSegundoPerfilEstudiante(formValuesSegundoUsuario);

				if (!sinFolio) {
					migracionAvances(data[0][1].correo, data[0][1].idPerfil, data[0][1].curp);
				}

				setShowLoader(false);
				setRegistrarSegundoUsuario(false);
				setProceso("");
				setFormValuesSegundoUsuario({
					...formValuesSegundoUsuario,
					nombreSegundoUsuario: "",
					apellidosSegundoUsuario: "",
					correoSegundoUsuario: "",
					curpSegundoUsuario: "",
					avatarPerfil: "",
					folioContrato: formValues.folioContrato,
					idLicencia: formValues.idLicencia,
				});
				setTieneSegundoUsuario(false);

				Notificacion("success", "Segundo usuario registrado exitosamente");
			} else {
				setCamposVacios(true);
			}
		} catch (error) {
			console.log(error);
			setFormValuesSegundoUsuario({
				...formValuesSegundoUsuario,
				nombreSegundoUsuario: "",
				apellidosSegundoUsuario: "",
				correoSegundoUsuario: "",
				curpSegundoUsuario: "",
				avatarPerfil: "",
				folioContrato: formValues.folioContrato,
				idLicencia: formValues.idLicencia,
			});
			setTieneSegundoUsuario(false);
			setProceso("");
			setShowLoader(false);
			Notificacion("danger", error.message);
		}
	};

	const nuevoFolio = () => {
		setTieneSegundoUsuario(false);
		setFormValues({
			...formValues,
			codigoVerificacion: "",
			contrasenia: "",
			confirmacionContrasenia: "",
			nombreCompleto: "",
			apellidos: "",
			correo: "",
			curp: "",
			alias: "",
			nombreUsuario: "",
			folioContrato: 0,
			idLicencia: 1,
			cantidadEstudiantes: 0,
		});
		setFolioVerificado(false);
	};

	const handleLicencia = ({currentTarget}) => {
		const {value} = currentTarget;
		setFormValues({
			...formValues,
			idLicencia: parseInt(value)
		})
	}


	useEffect(() => {
	 const obtenerLicencias = async() => {
		try{
			const {data} = await getLicencias();
			if(Array.isArray(data)){
				setLicenciasDisponibles(data);
			}else{
				setLicenciasDisponibles([data]);
			}
		}catch(error){
			console.log(error);
		}
	 }
	 obtenerLicencias();

	 const obtenerRegionesSAI= async() => {
		try{
			const {data} = await extraerRegionesSai();
			setRegiones(data);
		}catch(error) {
			console.log("Error al recuperar las regiones disponibles en sai")
		}
	 }
	 obtenerRegionesSAI();
	}, [])
	

	return (
		<Container>
			<Row className="mt-3 mb-4">
				<Col className="text-center">
					<h5>{registrarSegundoUsuario ? "Registro de segundo usuario" : "Registro de Usuario titular"}</h5>
				</Col>
			</Row>

			<Row className="justify-content-center">
				<Col className="col-8">
					{/* Registro de segundo Usuario */}
					{registrarSegundoUsuario ? (
						<Form>
							<Row>
								<Col className="col-6">
									<FormGroup>
										<FormLabel>Nombre</FormLabel>
										<Form.Control
											onChange={handleOnChangeSegundoUsuario}
											name="nombreSegundoUsuario"
											value={formValuesSegundoUsuario.nombreSegundoUsuario}
											type="text"
											placeholder="Nombre(s) del segundo usuario"></Form.Control>
									</FormGroup>
								</Col>
								<Col className="col-6">
									<FormGroup>
										<FormLabel>Apellidos</FormLabel>
										<Form.Control
											onChange={handleOnChangeSegundoUsuario}
											name="apellidosSegundoUsuario"
											value={formValuesSegundoUsuario.apellidosSegundoUsuario}
											type="text"
											placeholder="Apellidos(s) del segundo usuario"></Form.Control>
									</FormGroup>
								</Col>

								<Col className="col-6">
									<FormGroup>
										<FormLabel>Correo electrónico</FormLabel>
										<Form.Control
											onChange={handleOnChangeSegundoUsuario}
											name="correoSegundoUsuario"
											value={formValuesSegundoUsuario.correoSegundoUsuario}
											ype="text"
											placeholder="Correo electrónico del segundo usuario"></Form.Control>
									</FormGroup>
								</Col>

								<Col className="col-6">
									<FormGroup>
										<FormLabel>CURP</FormLabel>
										<Form.Control
											onChange={handleOnChangeSegundoUsuario}
											name="curpSegundoUsuario"
											value={formValuesSegundoUsuario.curpSegundoUsuario}
											type="text"
											placeholder="CURP del segundo usuario"></Form.Control>
									</FormGroup>
								</Col>

								<Col className="col-12">
									{camposVacios && (
										<Alert variant="danger" className="mt-3">
											Todos los campos deben ser llenados
										</Alert>
									)}
									<Button disabled={showLoader} block onClick={guardarSegundoUsuario}>
										{showLoader ? proceso : "Registrar 2do usuario"}
									</Button>
								</Col>
							</Row>
						</Form>
					) : (
						<Form>
							{/* Registro de usuario titular */}

							<Row className="mb-3 justify-content-between">
								<Row className="mb-4">
									<p>Licencia de la cuenta</p>
									<Col>
										<Form.Select onChange={handleLicencia} aria-label="Selecciona una licencia">
											<option value={0}>Selecciona una licencia</option>
											
											{licenciasDisponibles.map((licencia, indexLicencia) => (
												(licencia.idLicencia === 1 || licencia.idLicencia === 2 || licencia.idLicencia === 4)&&
													<option key={indexLicencia} value={licencia.idLicencia}>{licencia.nombre}</option>
												
											))}
											
										</Form.Select>
										
									</Col>
								</Row>

								<Row className="mb-4">
									<p>¿A qué región pertenece el cliente?</p>
									<Col>
										<Form.Select onChange={handleOnChangeRegion} aria-label="Selecciona una región">
											<option value={0}>Selecciona una región</option>
											
											{regiones.map((region, indexRegion) => (
													<option key={indexRegion} value={`${region.regionId}, ${region.codigoRegion}`}>{region.nombreRegion}</option>
												
											))}
											
										</Form.Select>
										
									</Col>
								</Row>
								{formValues.idLicencia === 2 && (
									<Row className="mb-4">
										<Col>
											<FormGroup>
												<FormLabel>
													Cantida de estudiantes
												</FormLabel>
												<Form.Control
													onChange={handleOnChange}
													value={formValues.cantidadEstudiantes}
													name="cantidadEstudiantes"
													type="number"
													placeholder="Cantidad de estudiantes del grupo"></Form.Control>
											</FormGroup>
										</Col>
									</Row>
								)}
								<Col className="col-auto d-flex align-items-center">
									<div className="card justify-content-center">
										<InputSwitch
											checked={sinFolio}
											onChange={(e) => {
												setSinFolio(e.value);
											}}
										/>
									</div>

									<span className="mx-2">Nuevo registro sin folio</span>
								</Col>
								
									<Col className="col-6 d-flex align-items-end">
										<FormGroup>
											<FormLabel>
												Fecha de expiración personalizada
											</FormLabel>
											<Form.Control
												onChange={handleOnChange}
												value={formValues.fechaExpiracion}
												name="fechaExpiracion"
												type="date"
												placeholder="Fecha de expiración personalizada"></Form.Control>
										</FormGroup>
										{formValues.fechaExpiracion !== "" && (
												<FaWindowClose className="mx-2 mb-1 pointer" color="red" size={30} onClick={cleanFechaExpiracion} />
										)}
									</Col>

							</Row>

							<Row className="mb-3">
								<Col className="col-6">
									{/* <FormGroup>
                      <FormLabel>Folio Contrato:</FormLabel>
                          <Form.Control type='text' placeholder='Folio de contrato'></Form.Control>
                      </FormGroup> */}
									<InputGroup className="mb-3">
										<FormControl
											disabled={folioVerificado || sinFolio ? true : false}
											name="folioContrato"
											onChange={handleOnChange}
											value={formValues.folioContrato === 0 ? "" : formValues.folioContrato}
											placeholder="Folio de contrato"
											aria-label="folio"
											aria-describedby="basic-addon1"
										/>
										<InputGroup.Text>
											<Button variant={folioVerificado ? "danger" : "primary"} disabled={showLoaderFolio || sinFolio} onClick={folioVerificado ? nuevoFolio : buscarDatosFolio}>
												{!showLoaderFolio ? !folioVerificado ? <FaSearch /> : <FontAwesomeIcon icon={faTimes} /> : <Spinner animation="border" size="sm" />}{" "}
											</Button>
										</InputGroup.Text>
									</InputGroup>
								</Col>

								<Col>
									<div className="card d-flex justify-content-center">
										<span>Tiene segundo usuario</span>

										<div className="d-flex flex-wrap mt-1">
											<div className="d-flex align-items-center mx-2">
												<RadioButton
													disabled={folioVerificado || sinFolio ? false : true}
													inputId="hasSecondUser"
													name="segundoUsuario"
													value={true}
													onChange={(e) => setTieneSegundoUsuario(e.value)}
													checked={tieneSegundoUsuario === true}
												/>

												<label htmlFor="hasSecondUser" className="mx-2 mb-0">
													Si
												</label>
											</div>

											<div className="d-flex align-items-center mx-2">
												<RadioButton
													disabled={folioVerificado || sinFolio ? false : true}
													inputId="hasSeconUser2"
													name="segundoUsuario"
													value={false}
													onChange={(e) => setTieneSegundoUsuario(e.value)}
													checked={tieneSegundoUsuario === false}
												/>
												<label htmlFor="hasSecondUser2" className="mx-2 mb-0">
													No
												</label>
											</div>
										</div>
									</div>
								</Col>
							</Row>

							<Row>
								<Col className="col-6">
									<FormGroup>
										<FormLabel>
											Nombres<span className="text-danger">*</span>
										</FormLabel>
										<Form.Control
											onChange={handleOnChange}
											name="nombreCompleto"
											value={formValues.nombreCompleto}
											disabled={folioVerificado || sinFolio ? false : true}
											type="text"
											placeholder="Nombre"></Form.Control>
									</FormGroup>
								</Col>

								<Col className="col-6">
									<FormGroup>
										<FormLabel>
											Apellidos<span className="text-danger">*</span>
										</FormLabel>
										<Form.Control
											onChange={handleOnChange}
											name="apellidos"
											value={formValues.apellidos}
											disabled={folioVerificado || sinFolio ? false : true}
											type="text"
											placeholder="Apellidos"></Form.Control>
									</FormGroup>
								</Col>

								<Col className="col-6">
									<FormGroup>
										<FormLabel>
											Correo electrónico<span className="text-danger">*</span>
										</FormLabel>
										<Form.Control
											onChange={handleOnChange}
											name="correo"
											value={formValues.correo}
											disabled={folioVerificado || sinFolio ? false : true}
											type="text"
											placeholder="Correo electrónico"></Form.Control>
									</FormGroup>
								</Col>

								<Col className="col-6">
									<FormGroup>
										<FormLabel>
											CURP<span className="text-danger">*</span>
										</FormLabel>
										<Form.Control onChange={handleOnChange} name="curp" value={formValues.curp} disabled={folioVerificado || sinFolio ? false : true} type="text" placeholder="CURP"></Form.Control>
									</FormGroup>
								</Col>

								<Col className="col-6">
									<FormGroup>
										<FormLabel>
											Alias<span className="text-danger">*</span>
										</FormLabel>
										<Form.Control onChange={handleOnChange} name="alias" value={formValues.alias} disabled={folioVerificado || sinFolio ? false : true} type="text" placeholder="Alias"></Form.Control>
									</FormGroup>
								</Col>

								<Col className="col-6">
									<FormGroup>
										<FormLabel>
											Nombre de usuario<span className="text-danger">*</span>
										</FormLabel>
										<Form.Control
											onChange={handleOnChange}
											name="nombreUsuario"
											value={formValues.nombreUsuario}
											disabled={folioVerificado || sinFolio ? false : true}
											type="text"
											placeholder="Nombre de usuario"></Form.Control>
									</FormGroup>
								</Col>
							</Row>

							<Row>
								<Col className="col-12">
									<Row>
										<Col className="col-6">
											<Form.Group>
												<Form.Label>
													Contraseña<span className="text-danger">*</span>
												</Form.Label>

												<InputGroup hasValidation>
													<InputGroup.Text onClick={showContrasenia} className="pointer">
														<FontAwesomeIcon icon={faEye} />
													</InputGroup.Text>

													<Form.Control
														disabled={folioVerificado || sinFolio ? false : true}
														type={showPasswords.contrasenia ? "text" : "password"}
														autoComplete="off"
														value={formValues.contrasenia}
														className={validacionContrasenia.contrasenia ? "is-valid " : ""}
														name="contrasenia"
														placeholder="Contraseña nueva"
														onChange={handleOnChangePasswords}
														ref={inputContrasenia}
													/>

													<Form.Control.Feedback type="invalid">Genera una contraseña v&aacute;lida</Form.Control.Feedback>

													<CapsLockTooltip input={inputContrasenia} isCapsLockOn={isCapsLockOn} />
												</InputGroup>

												<Form.Text id="passwordHelpBlock" muted>
													La contraseña debe contener:
													<ul>
														<li className={validacionContrasenia.caracteresContrasenia ? "text-success" : "text-danger"}>8 caracteres</li>
														<li className={validacionContrasenia.mayusculaContrasenia ? "text-success" : "text-danger"}>1 mayúscula</li>
														<li className={validacionContrasenia.numeroContrasenia ? "text-success" : "text-danger"}>1 número</li>
													</ul>
													{/* La contraseña debe ser de al menos 8 caracteres, 1 mayúscula y 1 número */}
												</Form.Text>
											</Form.Group>
										</Col>
										<Col className="col-6">
											<Form.Group>
												<Form.Label>
													Confirmar contraseña
													<span className="text-danger">*</span>
												</Form.Label>

												<InputGroup hasValidation>
													<InputGroup.Text onClick={showConfirmacionContrasenia} className="pointer">
														<FontAwesomeIcon icon={faEye} />
													</InputGroup.Text>

													<Form.Control
														disabled={folioVerificado || sinFolio ? false : true}
														onChange={handleOnChangePasswords}
														value={formValues.confirmacionContrasenia}
														type={showPasswords.confirmacionContrasenia ? "text" : "password"}
														autoComplete="off"
														name="confirmacionContrasenia"
														placeholder="Confirmar nueva contraseña"
														className={validacionContrasenia.confirmacionContrasenia ? "is-valid" : "is-invalid"}
													/>

													<Form.Control.Feedback type="invalid">Las contraseñas deben de ser iguales</Form.Control.Feedback>
													<Form.Control.Feedback type="valid">Las contraseñas son iguales</Form.Control.Feedback>
												</InputGroup>
											</Form.Group>
										</Col>
									</Row>

									
								</Col>

								<Col className="col-12">
									{camposVacios && (
										<Alert variant="danger" className="mt-3">
											Todos los campos deben ser llenados
										</Alert>
									)}

									<div className="d-grid gap-2 mt-4">
										<Button disabled={showLoader ? true : false} onClick={registrarEstudiante} size="lg">
											<span className="mx-3">{proceso}</span>
											{!showLoader ? "Registrar estudiante" : <Spinner animation="border" />}{" "}
										</Button>
									</div>
								</Col>

								{/* <Col>
                          <div className="card d-flex justify-content-center">
                              <span>Tiene segundo usuario</span>
                              <div className="d-flex flex-wrap mt-3">
                                  <div className="d-flex align-items-center mx-2">
                                      <RadioButton disabled={folioVerificado ? false : true} inputId="hasSecondUser" name="segundoUsuario" value={true} onChange={(e) => setTieneSegundoUsuario(e.value)} checked={tieneSegundoUsuario === true} />
                                      <label htmlFor="hasSecondUser" className="mx-2 mb-0">Si</label>
                                  </div>
                                  <div className="d-flex align-items-center mx-2">
                                      <RadioButton disabled={folioVerificado ? false : true} inputId="hasSeconUser2" name="segundoUsuario" value={false} onChange={(e) => setTieneSegundoUsuario(e.value)} checked={tieneSegundoUsuario === false} />
                                      <label htmlFor="hasSecondUser2" className="mx-2 mb-0">No</label>
                                  </div>
                                  
                              </div>
                          </div>
                      </Col> */}
							</Row>
						</Form>
					)}
				</Col>
			</Row>
		</Container>
	);
};

export default DashboardNuevoEstudiante;
