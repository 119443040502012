import multiViewPanelAdministracionPaths from "@helpers/multiViewPanelAdministracionPaths/multiViewPanelAdministracionPaths";
import typesTiposUsuario from "@helpers/types/typesTiposUsuario";

const panelAdministracionItems = [
	{
		id: multiViewPanelAdministracionPaths.dashboardAulaVirtual,
		title: "Aula Virtual",
		icon: "gestion-aula-virtual.png",
		permittedTo: [typesTiposUsuario.administrador, typesTiposUsuario.capacitador],
	},
	{
		id: multiViewPanelAdministracionPaths.dashboardRegistroUsuarioInvitado,
		title: "Registro de usuarios de capacitación",
		icon: "gestion-usuarios.png",
		permittedTo: [typesTiposUsuario.administrador, typesTiposUsuario.vendedor],
	},
	{
		id: multiViewPanelAdministracionPaths.dashboardConsejosRapidos,
		title: "Consejos Rápidos",
		icon: "gestion-consejos-rapidos.png",
		permittedTo: [typesTiposUsuario.administrador],
	},
	{
		id: multiViewPanelAdministracionPaths.dashboardUsuarios,
		title: "Gestión de Usuarios",
		icon: "gestion-usuarios.png",
		permittedTo: [typesTiposUsuario.administrador],
	},
	{
		id: multiViewPanelAdministracionPaths.dashboardGestionContenidoCursos,
		title: "Contenido de Cursos",
		icon: "gestion-contenido-cursos.png",
		permittedTo: [typesTiposUsuario.administrador],
	},
	// {
	// 	id: multiViewPanelAdministracionPaths.dashboardSuiteIa,
	// 	title: "Suite de IA",
	// 	icon: "suiteIa.png",
	// 	permittedTo: [typesTiposUsuario.administrador],
	// },
	{
		id: multiViewPanelAdministracionPaths.dashboardBancoPreguntas,
		title: "Banco de Preguntas",
		icon: "gestion-banco-preguntas.png",
		permittedTo: [typesTiposUsuario.administrador],
	},
	{
		id: multiViewPanelAdministracionPaths.dashboardRutasAprendizaje,
		title: "Rutas de aprendizaje",
		icon: "gestion-rutas-aprendizaje.png",
		permittedTo: [typesTiposUsuario.administrador],
	},
	{
		id: multiViewPanelAdministracionPaths.dashboardLicencias,
		title: "Licencias",
		icon: "gestion-licencias.png",
		permittedTo: [typesTiposUsuario.administrador],
	},
	{
		id: multiViewPanelAdministracionPaths.dashboardNotificaciones,
		title: "Notificaciones",
		icon: "gestion-notificaciones.png",
		permittedTo: [typesTiposUsuario.administrador],
	},
	{
		id: multiViewPanelAdministracionPaths.dashboardForoDeAyuda,
		title: "Foro de ayuda",
		icon: "gestion-foro-ayuda.png",
		permittedTo: [typesTiposUsuario.administrador],
	},
	{
		id: multiViewPanelAdministracionPaths.dashboardLeccionesEmpresariales,
		title: "Lecciones empresariales",
		icon: "gestion-lecciones.png",
		permittedTo: [typesTiposUsuario.administrador],
	},
	{
		id: multiViewPanelAdministracionPaths.dashboardApariencia,
		title: "Apariencia de la plataforma",
		icon: "gestion-apariencia.png",
		permittedTo: [typesTiposUsuario.administrador],
	},
	{
		id: multiViewPanelAdministracionPaths.dashboardPlantillasTrabajo,
		title: "Plantillas de Trabajo",
		icon: "plantillas-trabajo.png",
		permittedTo: [typesTiposUsuario.administrador],
	},
	{
		id: multiViewPanelAdministracionPaths.dashboardRegistroNuevoUsuario,
		title: "Nuevo Estudiante",
		icon: "nuevo-estudiante.png",
		permittedTo: [typesTiposUsuario.administrador],
	},
	{
		id: multiViewPanelAdministracionPaths.dashboardEstadisticasPlataforma,
		title: "Estadísticas de la plataforma",
		icon: "estadisticas.png",
		permittedTo: [typesTiposUsuario.administrador],
	},

	{
		id: multiViewPanelAdministracionPaths.dashboardAgendasAsesorias,
		title: "Agenda de Asesorías",
		icon: "agenda-asesorias.png",
		permittedTo: [typesTiposUsuario.administrador],
	},

	{
		id: multiViewPanelAdministracionPaths.dashboardSeguridad,
		title: "Gestión de accesos",
		icon: "security.png",
		permittedTo: [typesTiposUsuario.administrador],
	},

	{
		id: multiViewPanelAdministracionPaths.dashboardAyudaAdministradores,
		title: "Ayuda para administradores",
		icon: "info.png",
		permittedTo: [typesTiposUsuario.administrador],
	},
	{
		id: multiViewPanelAdministracionPaths.documentacionApi,
		title: "Documentación API",
		icon: "api.png",
		permittedTo: [typesTiposUsuario.administrador],
	},
	{
		id: multiViewPanelAdministracionPaths.documentacionFrontend,
		title: "Documentación Click+",
		icon: "contenido-web.png",
		permittedTo: [typesTiposUsuario.administrador],
	},
];

export default panelAdministracionItems;
