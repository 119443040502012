import { ModalSweetAlert, Notificacion, NotificacionExtendida } from "@components/general/Alertas/Alertas";
import DynamicBreadcrumb from "@components/general/DynamicBreadcrumb/DynamicBreadcrumb";
import { DropZone } from "@helpers/DropZone/DropZone";
import TitleDashboard from "@components/general/Titles/TitleDashboard";
import UserContext from "@context/User/UserContext";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { enviarConfirmacionRegistro } from "@helpers/httpRequests/httpRequestsMailers/httpRequestsMailers";
import { addSegundoPerfilEstudiante, getDatosUsuario, getTokenRegistro } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import Compressor from "compressorjs";
import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Image, Row, Spinner } from "react-bootstrap";
import { registrarSegundoUsuarioSai } from "@helpers/httpRequests/httpRequestsSAI/httpRequestsSAI";
import ReactInputMask from "react-input-mask";


const RegistroSegundoUsuario = () => {
	const { contextValue, setContextValue } = useContext(UserContext);

	const { usuarioLogeado } = contextValue;

	const { idCodigoRegion, idPerfil, idPersona, folioContrato, idLicencia, idCredencial, rol, nombreCompleto, apellidos, usuarioTitular, nombreUsuario } = usuarioLogeado;

	const [formValues, setFormValues] = useState({
		nombreSegundoUsuario: "",
		apellidosSegundoUsuario: "",
		correoSegundoUsuario: "",
		passwordSegundoUsuario: "",
		curpSegundoUsuario: "",
		profesionSegundoUsuario: "",
		avatarSegundoUsuario: "",
		previewFotoPerfil: false,
		folioContrato,
		idLicencia,
		idCredencial,
		numeroTelefono: "",
	});

	const [validacionForm, setValidacionForm] = useState({
		nombreSegundoUsuario: false,
		apellidosSegundoUsuario: false,
		correoSegundoUsuario: false,
		curpSegundoUsuario: false,
		passwordSegundoUsuario: false,
		profesionSegundoUsuario: false,
		numeroTelefono: false,
	});

	const [tokenRegistro, setTokenRegistro] = useState("");

	const [registroExitoso, setRegistroExitoso] = useState(false);

	const [loading, setLoading] = useState(false);
 
	//FUNCION PARA EDITAR LOS VALORES DE LOS INPUTS.
	const handleOnChange = ({ target }) => {
		const { name, value } = target;

		setFormValues({
			...formValues,
			[name]: value,
		});

		if (value === "") {
			setValidacionForm({
				...validacionForm,
				[name]: true,
			});
		} else {
			setValidacionForm({
				...validacionForm,
				[name]: false,
			});
		}
	};

	const cleanPreview = () => {
		setFormValues({
			...formValues,
			avatarSegundoUsuario: "",
			previewFotoPerfil: false,
		});
	};

	//FUNCION PARA RECUPERAR LA INFORMACIÓN DE LA IMAGEN DE PERFIL
	const getImageInBase64 = ({ target }) => {
		try {
			const { files } = target;
			let file = files[0];
			// console.log(file);

			var formData = new FormData();
			const fileReader = new FileReader();

			if (!file) {
				return;
			} else {
				//!codigo para comparacion de peso de imagen antes de ser comprimida
				fileReader.readAsDataURL(file);
				// //!Compresorjs documentacion:https://www.npmjs.com/package/compressorjs

				new Compressor(file, {
					quality: 0.2,
					success(result) {
						fileReader.readAsDataURL(result);
						fileReader.onload = async function () {
							formData.append("title", result.name);
							formData.append("file", result);

							let compresoResult = fileReader.result; //result es el que contiene la data una vez cargada la imagen

							// console.log(compresoResult);

							setFormValues({
								...formValues,
								avatarSegundoUsuario: compresoResult,
								previewFotoPerfil: true,
							});
						};
					},
					error(e) {
						console.log(e);
						Notificacion("warning", "Ups!, algo salió mal, vuelve a cargar tu foto");
					},
				});

				// let json=await uploadImage(formData);
			}
		} catch (e) {
			Notificacion("warning", "Ups!, algo salió mal, vuelve a cargar tu foto");
		}
	};

	//FUNCION PARA GUARDAR LA INFORMACION DEL REGISTRO
	const handleSubmit = async (e) => {
		e.preventDefault();

		setLoading(true);

		try {
			const informacion = {
				idPerfil,
				idPersona,
				rol,
				nombreSegundoUsuario: formValues.nombreSegundoUsuario,
				apellidosSegundoUsuario: formValues.apellidosSegundoUsuario,
				correoSegundoUsuario: formValues.correoSegundoUsuario,
				curpSegundoUsuario: formValues.curpSegundoUsuario,
				folioContrato: formValues.folioContrato,
				idLicencia: formValues.idLicencia,
				avatarPerfil: formValues.avatarSegundoUsuario,
				idCredencial: formValues.idCredencial,
				nombreTitular: nombreCompleto,
				apellidosTitular: apellidos,
				numeroTelefono: formValues.numeroTelefono,
			};

			//REGISTRO DEL SEGUNDO USUARIO
			const { message, code } = await addSegundoPerfilEstudiante(informacion);

			await registrarSegundoUsuarioSai({
				idRegion: idCodigoRegion,
				folioContrato,
				tokenRegistro,
				nombre: formValues.nombreSegundoUsuario,
				apellidos: formValues.apellidosSegundoUsuario,
				nombreUsuario,
				contrasenia: "",
				curp: formValues.curpSegundoUsuario,
				correo: formValues.correoSegundoUsuario,
			});

			// console.log(code);
			NotificacionExtendida("success", message);

			setRegistroExitoso(!registroExitoso);

			//OBTENER LOS DATOS NUEVOS DEL USUARIO
			const { data: dataRespuesta } = await getDatosUsuario({
				tipoUsuario: informacion.rol,
				idCredencial: informacion.idCredencial,
				usuarioTitular,
			});

			//ENVIAR EL CORREO ELECTRONICO PARA CONFIRMAR EL REGISTRO DEL NUEVO PEFIL
			await enviarConfirmacionRegistro(informacion);

			const [datosUsuarioActualizados] = dataRespuesta;

			setContextValue({
				...contextValue,
				...usuarioLogeado,
				usuarioLogeado: datosUsuarioActualizados,
			});

			setLoading(false);
		} catch (error) {
			console.log(error);

			if (error.data) {
				const { data } = error;

				if (data.error.code === "ER_DUP_ENTRY") {
					if (data.error.sqlMessage.includes("persona.correo")) {
						return ModalSweetAlert({
							title: "El correo ingresado ya existe, por favor ingresa uno distinto.",
							icon: "error",
						});
					}

					if (data.error.sqlMessage.includes("persona.curp")) {
						return ModalSweetAlert({
							title: "El CURP ingresado ya existe, por favor verifica tu información.",
							icon: "error",
						});
					}
				}
			}

			NotificacionExtendida("error", "Error al registrar a su segundo usuario.");
		}
	};

	useEffect(() => {
		const consultarTokenRegistro = async () => {
			try {
				const { tokenRegistro } = await getTokenRegistro(folioContrato);

				setTokenRegistro(tokenRegistro);
			} catch (error) {
				console.log("Error al consultar el token de registro: ", error);
			}
		};

		consultarTokenRegistro();
	}, []);

	return (
		<Container fluid className="p-0 mb-3">
			<DynamicBreadcrumb arrayBreadcrumbs={[{ title: "Registro de segundo usuario", onClick: () => {} }]} />

			<Row className="mb-3">
				<Col sm={12} md>
					<TitleDashboard title="Registro de segundo usuario" />
				</Col>
			</Row>

			{/*Pantalla de confirmación para el registro de segundo usuario.*/}

			{registroExitoso ? (
				<Row className="mt-5 justify-content-center pb-5">
					<Col className="col-10 col-md-8 col-lg-6">
						<div className="text-center mb-4">
							<img src={require(`@assets/images/check.png`)} className="img-fluid" width="100" alt="Imagen check"></img>
						</div>
						<h3 className="text-center mb-4">Registro exitoso</h3>
						{/* <p className="text-center">Se ha enviado un correo de confirmación a la dirección <b>{formValues.correoSegundoUsuario}</b>. Ponte en contacto con el propietario de esa dirección de correo para darle acceso a su perfil con la contraseña actual.</p> */}
						<p className="text-center">
							Tu segundo usuario ha sido registrado, deberás compartirle tus credenciales de acceso para que pueda ingresar a la plataforma. <br />
							<br /> Un correo de confirmación ha sido enviado a la dirección <b>{formValues.correoSegundoUsuario}</b> con el enlace de acceso a la plataforma.
						</p>
					</Col>
				</Row>
			) : (
				<Form onSubmit={handleSubmit} className="mt-4 p-5">
					<Row className="justify-content-center">
						<Col className="col-12 col-sm-8 col-md-7 col-lg-6">
							<Form.Group>
								<Form.Label>Nombre*</Form.Label>
								<Form.Control
									className={validacionForm.nombreSegundoUsuario ? "is-invalid" : ""}
									placeholder="Nombre(s) del segundo usuario"
									onChange={handleOnChange}
									type="text"
									name="nombreSegundoUsuario"
									value={formValues.nombreSegundoUsuario}
									required></Form.Control>

								<Form.Control.Feedback type="invalid">Este campo no puede ir vacío</Form.Control.Feedback>
							</Form.Group>

							<Form.Group>
								<Form.Label>Apellidos*</Form.Label>
								<Form.Control
									className={validacionForm.apellidosSegundoUsuario ? "is-invalid" : ""}
									placeholder="Apellido(s) del segundo usuario"
									onChange={handleOnChange}
									type="text"
									name="apellidosSegundoUsuario"
									value={formValues.apellidosSegundoUsuario}
									required></Form.Control>
								<Form.Control.Feedback type="invalid">Este campo no puede ir vacío</Form.Control.Feedback>
							</Form.Group>

							<Form.Group>
								<Form.Label>Correo electrónico*</Form.Label>
								<Form.Control
									className={validacionForm.correoSegundoUsuario ? "is-invalid" : ""}
									placeholder="Correo electrónico"
									onChange={handleOnChange}
									type="email"
									required
									name="correoSegundoUsuario"
									value={formValues.correoSegundoUsuario}></Form.Control>
								<Form.Control.Feedback type="invalid">Este campo no puede ir vacío</Form.Control.Feedback>
							</Form.Group>

							<Form.Group>
								<Form.Label>
									<h6>Número de teléfono*</h6>
								</Form.Label>
								{/* <Form.Control className='input-form' required onChange={handleOnChange} value={formValues.telefono} name="telefono" type="tel" placeholder="Número de teléfono"></Form.Control> */}

								<ReactInputMask
									className={`input-form form-control ${validacionForm.numeroTelefono ? "is-invalid" : ""}`}
									mask="(999) 999-9999"
									maskChar=" "
									required
									onChange={handleOnChange}
									value={formValues.numeroTelefono}
									name="numeroTelefono"
									type="tel"
									placeholder="Número de teléfono"
								/>

								<Form.Control.Feedback type="invalid">
									<p>Este campo es obligatorio</p>
								</Form.Control.Feedback>
							</Form.Group>
						</Col>

						<Col className="col-12 col-sm-8 col-md-7 col-lg-6">
							<Form.Group>
								<Form.Label>{idCodigoRegion !== 1 ? "Cédula de identidad*" : "CURP" }</Form.Label>
								<Form.Control
									className={validacionForm.curpSegundoUsuario ? "is-invalid" : ""}
									placeholder={idCodigoRegion !== 1 ? "Cédula de identidad*" : "CURP" }
									onChange={handleOnChange}
									type="text"
									name="curpSegundoUsuario"
									maxLength="18"
									value={formValues.curpSegundoUsuario}
									required
								/>
								<Form.Control.Feedback type="invalid">Este campo no puede ir vacío</Form.Control.Feedback>
							</Form.Group>
							<Form.Group>
								<Form.Label>Puesto de trabajo*</Form.Label>
								<Form.Control
									className={validacionForm.profesionSegundoUsuario ? "is-invalid" : ""}
									placeholder="Puesto de trabajo"
									onChange={handleOnChange}
									type="text"
									required
									name="profesionSegundoUsuario"
									value={formValues.profesionSegundoUsuario}></Form.Control>

								<Form.Control.Feedback type="invalid">Este campo no puede ir vacío</Form.Control.Feedback>
							</Form.Group>

							<Form.Group>
								<Form.Label>Foto de perfil</Form.Label>

								{formValues.previewFotoPerfil ? (
									<Row className="justify-content-center ">
										<Col className=" text-center">
											<p className=" subtitulos">Vista previa</p>
											<Image className="mt-2 mb-3 rounded-circle vista-previa" src={formValues.avatarSegundoUsuario} fluid alt="Imagen de perfil"></Image>
											<h5>
												<FontAwesomeIcon onClick={cleanPreview} icon={faTrash} />
											</h5>
										</Col>
									</Row>
								) : (
									<DropZone getImageInBase64={getImageInBase64} />
								)}
							</Form.Group>
						</Col>
					</Row>
					<Row className="justify-content-center mt-4">
						<Col className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
							<Button disabled={loading} type="submit" className="btn btn-primary" block>
							Registrar usuario {loading && (<Spinner size="sm" />)}
							</Button>
						</Col>
					</Row>
				</Form>
			)}
		</Container>
	);
};

export default RegistroSegundoUsuario;
